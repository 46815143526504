<template>
  <div class="h-full">
    <dynamic-scroller
      class="scroller h-full"
      :items="filteredNowTabsGrouped"
      :min-item-size="64"
    >
      <template #default="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :data-index="index"
          :active="active"
          :size-dependencies="[item.tabList.length]"
        >
          <div
            v-if="item.tabList.length === 1"
            :key="item.tabList[0].id"
            @click="
              $emit('selectTab', item.tabList[0].id, item.tabList[0].pickupType)
            "
          >
            <small-tab-row
              v-if="!fullScreen"
              :tab-id="item.tabList[0].id"
              :selected-tab-id="selectedTabId"
              :identifier="`${item.id}`"
            />
            <large-tab-row
              v-else-if="
                !choosingForShipment ||
                rowIsPickableForShipment(item.tabList[0])
              "
              :tab-id="item.tabList[0].id"
              :row-size="rowSize"
              :current-time="currentTime"
              :row-index="index"
              :is-selected="item.tabList[0].id === selectedTabId"
              :choosing-for-shipment="choosingForShipment"
              :picked-for-shipment="
                pickedTabsForShipment.includes(item.tabList[0].id)
              "
              @go-to-details="$emit('update:viewMode', 'splitScreen')"
              @shipment-pick-toggled="
                value => $emit('shipmentPickToggled', item.tabList[0].id, value)
              "
            />
          </div>
          <div v-else-if="!choosingForShipment" class="bg-gray-100 relative">
            <div class="flex flex-row ml-3 py-3">
              <div
                class="bg-blue h-6 w-6 rounded-full flex justify-center items-center"
              >
                <icon name="chain" class="text-n-0" small />
              </div>
              <div class="text-blue font-body ml-3">
                {{ $t('tabs.scheduled-orders') }}
              </div>
            </div>
            <div
              v-for="tab in item.tabList"
              :key="tab.id"
              @click="$emit('selectTab', tab.id, tab.pickupType)"
            >
              <small-tab-row
                v-if="!fullScreen"
                :tab-id="tab.id"
                :selected-tab-id="selectedTabId"
                offset
              />
              <large-tab-row
                v-else
                :tab-id="tab.id"
                :row-size="rowSize"
                :current-time="currentTime"
                :is-selected="tab.id === selectedTabId"
                offset
                @go-to-details="$emit('update:viewMode', 'splitScreen')"
              />
            </div>
            <div
              class="w-1 bg-blue absolute top-0 rounded-lg bundle-bar bottom-0 mb-6 mt-10"
            />
          </div>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import moment from 'moment'
import SmallTabRow from '@/components/tabs/SmallTabRow.vue'
import LargeTabRow from '@/components/tabs/LargeTabRow.vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
  name: 'TabRows',
  components: {
    SmallTabRow,
    LargeTabRow,
    Icon,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    tabs: {
      type: Array,
      default: null
    },
    pickedTabsForShipment: {
      type: Array,
      default: null
    },
    choosingForShipment: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    filterClosed: {
      type: Boolean,
      default: false
    },
    selectedTabId: {
      type: String,
      default: null
    },
    rowSize: {
      type: String,
      default: null
    },
    currentTime: {
      type: Date,
      default: null
    }
  },
  emits: ['selectTab', 'update:viewMode', 'shipmentPickToggled'],
  data() {
    return {}
  },
  computed: {
    groupedTabs() {
      return this.tabs.reduce((res, tab) => {
        let key = tab.deliveryOrder?.shipmentId || tab.shopOrderId || 'null'
        if (!res[key]) res[key] = [tab]
        else res[key].push(tab)
        return res
      }, {})
    },
    filteredNowTabsGrouped() {
      return Object.keys(this.groupedTabs)
        .flatMap(key => {
          if (key === 'null' || this.groupedTabs[key].length === 1) {
            return this.groupedTabs[key].map(tab => [tab])
          } else {
            return [
              this.groupedTabs[key].sort(
                (a, b) => a.activationTime - b.activationTime
              )
            ]
          }
        })
        .map((tabList, index) => {
          return {
            tabList,
            id: index,
            momentTimeActivationTime: moment(
              new Date(tabList[0].activationTime).toISOString()
            )
          }
        })
        .sort((first, second) => {
          return (
            first.momentTimeActivationTime - second.momentTimeActivationTime
          )
        })
    }
  },
  methods: {
    rowIsPickableForShipment(tab) {
      return (
        tab.pickupType === 'ownDelivery' &&
        tab.deliveryOrder &&
        !tab.deliveryOrder.shipmentId
      )
    }
  }
}
</script>
