<template>
  <l-modal
    :title="title"
    :button-text="$t('new-delivery.cta-continue')"
    :button-disabled="!isComplete"
    @action="checkNextStep"
    @close="$emit('close')"
  >
    <l-field :label="$t('new-delivery.client-phone-label')">
      <l-phone-input
        v-model="internalCustomer.phoneNumber"
        :placeholder="$t('new-delivery.client-phone-placeholder')"
        :wrong-value="validating && !validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>
    <l-field :label="$t('new-delivery.select-virtual-brand')">
      <l-select v-model="internalTab.virtualBrandId" :options="enabledBrands" />
    </l-field>
    <div v-if="type === 'takeAway'" class="flex justify-between">
      <l-field class="flex-1 mr-4" :label="$t('new-delivery.tab-name-label')">
        <l-input
          v-model="internalTab.name"
          :placeholder="$t('new-delivery.tab-name-placeholder')"
        />
      </l-field>
      <l-field class="flex-1" :label="$t('new-delivery.client-number-label')">
        <l-select
          v-model="internalTab.seats"
          :options="numberOfPeople"
          :placeholder="$t('new-delivery.client-number-label')"
        />
      </l-field>
    </div>
    <l-field v-else :label="$t('new-delivery.select-enterprise-label')">
      <l-select v-model="internalTab.source" :options="deliveryCompanies" />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import api from '@/api'
import {
  LModal,
  LField,
  LInput,
  LPhoneInput,
  LSelect
} from '@last/core-ui/paprika'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { computed } from 'vue'
import { watch } from 'vue'
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  customer: {
    type: Object,
    required: true
  },
  tab: {
    type: Object,
    required: true
  },
  deliveryCompanies: {
    type: Array,
    default: () => []
  },
  type: {
    type: String,
    default: null
  }
})

const emit = defineEmits([
  'close',
  'nextStep',
  'update:tab',
  'update:phoneNumber'
])

const { t } = useI18n()
const configStore = useConfigStore()
const { config } = storeToRefs(configStore)
const { virtualBrands } = storeToRefs(configStore)

const internalTab = ref<{
  source?: string
  name?: string
  pickupType?: string
  seats?: string
  virtualBrandId: string | null
}>({
  virtualBrandId: null
})

const internalCustomer = ref<{
  phoneNumber?: string
  isCreated?: boolean
  points?: number
}>({})
const loading = ref(false)
const validating = ref(false)

const isComplete = computed(() => {
  if (props.type === 'takeAway') {
    return true
  }
  return (
    internalTab.value.source &&
    internalCustomer.value.phoneNumber &&
    validPhoneNumber
  )
})

const validPhoneNumber = computed(() => {
  let parsed = parsePhoneNumberFromString(
    internalCustomer.value.phoneNumber || ''
  )
  return parsed?.isValid()
})

const parsedInternalTab = computed(() => {
  return {
    ...internalTab.value,
    seats: internalTab.value.seats ? parseInt(internalTab.value.seats) : null
  }
})

watch(
  () => internalTab.value.source,
  () => {
    emit('update:tab', parsedInternalTab.value)
  }
)

watch(
  () => internalTab.value.seats,
  () => {
    emit('update:tab', parsedInternalTab.value)
  }
)

watch(
  () => internalTab.value.virtualBrandId,
  () => {
    emit('update:tab', parsedInternalTab.value)
  }
)

watch(
  () => internalCustomer.value.phoneNumber,
  () => {
    emit('update:phoneNumber', internalCustomer.value.phoneNumber)
  }
)

onBeforeMount(() => {
  internalTab.value = { ...internalTab.value, ...props.tab }
  internalCustomer.value = { ...props.customer }
  if (!internalTab.value.virtualBrandId) {
    internalTab.value.virtualBrandId = enabledBrands.value[0]?.value
  }
})

async function checkNextStep() {
  validating.value = true
  if (!isComplete.value) return
  if (internalCustomer.value.phoneNumber && validPhoneNumber) {
    if (loading.value) return
    loading.value = true
    let lastCustomerTabs = []
    try {
      const { data } = await api.get('/customers', {
        phoneNumber: internalCustomer.value.phoneNumber
      })

      internalCustomer.value = {
        ...data.customer,
        points: data.points,
        isCreated: true
      }
      lastCustomerTabs = data.tabs
    } catch (err) {
      internalCustomer.value.isCreated = false
    } finally {
      emit('nextStep', {
        customer: internalCustomer.value,
        tabs: lastCustomerTabs,
        pickupType: 'ownDelivery'
      })
      loading.value = false
    }
  } else {
    emit('nextStep', {
      customer: internalCustomer.value
    })
  }
}

const enabledBrands = computed(() => {
  return virtualBrands.value
    .filter(virtualBrand => virtualBrand.enabled)
    .map(virtualBrand => {
      return {
        value: virtualBrand.id,
        label: virtualBrand.name
      }
    })
})

const numberOfPeople = computed(() => {
  let numberOfPeople = []
  for (let i = 0; i < 20; i++) {
    numberOfPeople[i] = {
      label: `${i < 9 ? '0' : ''}${i + 1} ${t('new-delivery.client-number')}`,
      value: i + 1 + ''
    }
  }
  return numberOfPeople
})
</script>
