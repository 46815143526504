<template>
  <div class="h-full w-full flex flex-col text-n-0">
    <div class="font-heading font-bold text-xl mb-6">
      {{ $t('end-shift.shift-summary') }}
    </div>
    <div class="flex-1 overflow-y-scroll scrolling-touch pl-4">
      <div
        v-for="(group, index) in groupedTillsReport"
        :key="index"
        class="font-body text-sm mb-4 pb-4 border-b border-n-600"
      >
        <div class="font-heading text-base font-medium py-1">
          {{ $t('end-shift.total') }} {{ group.type }}
        </div>
        <div class="flex flex-row justify-between py-1">
          <div>{{ $t('end-shift.expected') }}</div>
          <div>{{ $filters.currency(group.expectedEndAmount) }}</div>
        </div>
        <div class="flex flex-row justify-between py-1">
          <div>{{ $t('end-shift.declared') }}</div>
          <div v-if="group.endAmount || group.endAmount === 0">
            {{ $filters.currency(group.endAmount) }}
          </div>
          <div v-else>-</div>
        </div>
        <div class="flex flex-row justify-between py-1">
          <div class="">{{ $t('end-shift.difference') }}</div>
          <div
            v-if="group.endAmount || group.endAmount === 0"
            :class="{
              'text-green': group.endAmount - group.expectedEndAmount >= 0,
              'text-red': group.endAmount - group.expectedEndAmount < 0
            }"
          >
            {{ $filters.currency(group.endAmount - group.expectedEndAmount) }}
          </div>
          <div v-else>-</div>
        </div>
      </div>

      <div class="font-body text-sm mt-8">
        <div class="flex flex-row justify-between py-1">
          <div>{{ $t('end-shift.total-expected') }}</div>
          <div>
            {{ $filters.currency(totalGrouped.totalExpectedEndAmount) }}
          </div>
        </div>
        <div class="flex flex-row justify-between py-1">
          <div>{{ $t('end-shift.total-declared') }}</div>
          <div
            v-if="
              totalGrouped.totalEndAmount || totalGrouped.totalEndAmount === 0
            "
          >
            {{ $filters.currency(totalGrouped.totalEndAmount) }}
          </div>
          <div v-else>-</div>
        </div>
        <div class="flex flex-row justify-between py-1">
          <div>{{ $t('end-shift.total-difference') }}</div>
          <div
            v-if="
              totalGrouped.totalEndAmount || totalGrouped.totalEndAmount === 0
            "
            :class="{
              'text-green': totalGrouped.totalDifference >= 0,
              'text-red': totalGrouped.totalDifference < 0
            }"
          >
            {{ $filters.currency(totalGrouped.totalDifference) }}
          </div>
          <div v-else>-</div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <l-button
        size="medium"
        icon="printer"
        type="text"
        @click="$emit('printPreview')"
      >
        {{ $t('end-shift.print-shift-summary') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LastShiftGroupedTills } from '@/types'
import { LButton } from '@last/core-ui/paprika'
import { computed } from 'vue'

const { groupedTillsReport } = defineProps<{
  groupedTillsReport: LastShiftGroupedTills
}>()

defineEmits(['printPreview'])

const totalGrouped = computed(() => {
  return Object.keys(groupedTillsReport).reduce(
    (res, groupKey) => {
      let group = groupedTillsReport[groupKey]
      res.totalExpectedEndAmount += group.expectedEndAmount
      res.totalEndAmount += group.endAmount ?? 0
      res.totalDifference += (group.endAmount ?? 0) - group.expectedEndAmount
      return res
    },
    { totalExpectedEndAmount: 0, totalEndAmount: 0, totalDifference: 0 }
  )
})
</script>
