<template>
  <div></div>
</template>

<script setup lang="ts">
import sync from '@/sync/service.js'
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const configStore = useConfigStore()
const router = useRouter()

onMounted(async () => {
  await authStore.getDemoAuthData()
  await configStore.activateDemoMode()
  sync.resync()
  router.push({ name: 'employees' })
})
</script>
