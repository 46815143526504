<template>
  <div class="flex flex-row h-screen">
    <div
      class="hidden w-1/2 md:flex flex-col justify-center items-center bg-n-700 text-n-0"
    >
      <div class="w-3/4 md:w-2/3 flex flex-col max-w-sm">
        <div
          class="w-full text-left text-[2.813rem] leading-[3.438rem] font-heading font-bold mb-6"
        >
          {{ $t('login.welcome-title') }}
        </div>
        <div class="w-full text-left text-xl leading-5 pb-[209px]">
          {{ $t('login.welcome-baseline') }}
        </div>
        <div class="w-full text-left text-xl leading-5">
          <img class="w-[208px]" src="../assets/logo-last-color-dark.svg" />
        </div>
      </div>
    </div>
    <div
      class="w-full md:w-1/2 flex justify-center items-start mt-20 md:mt-0 sm:pt-0 items-center flex-col"
    >
      <div
        class="p-4 sm:p-0 w-full sm:w-3/4 md:w-2/3 max-w-sm flex flex-col flex-shrink-0"
      >
        <LoginForm
          v-if="componentState === ComponentStates.LOGIN"
          :show-p-i-n-option="showPINOption"
          @activate-p-i-n="componentState = ComponentStates.PIN"
          @forgot-password="componentState = ComponentStates.FORGOT"
        />

        <LoginPIN
          v-if="componentState === ComponentStates.PIN"
          @disable-p-i-n="componentState = ComponentStates.LOGIN"
        />

        <LoginForgot
          v-if="componentState === ComponentStates.FORGOT"
          @back="componentState = ComponentStates.LOGIN"
          @code-verified="onCodeVerified"
        />

        <LoginNewPassword
          v-if="componentState === ComponentStates.NEW_PASSWORD"
          :change-password-token="changePasswordToken"
          @error="componentState = ComponentStates.LOGIN"
          @success="componentState = ComponentStates.LOGIN"
        />
      </div>
      <div class="md:hidden flex items-end flex-1 mb-12">
        <img class="w-[208px]" src="../assets/logo-last-color-dark.svg" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import LoginForm from '@/components/login/LoginForm.vue'
import LoginPIN from '@/components/login/LoginPIN.vue'
import LoginForgot from '@/components/login/LoginForgot.vue'
import LoginNewPassword from '@/components/login/LoginNewPassword.vue'

const ComponentStates = {
  LOGIN: 'login',
  PIN: 'pin',
  FORGOT: 'forgot',
  NEW_PASSWORD: 'newPassword'
} as const

const componentState = ref<
  (typeof ComponentStates)[keyof typeof ComponentStates]
>(ComponentStates.LOGIN)

const authStore = useAuthStore()
const { locationId, isAuthenticated } = storeToRefs(authStore)
const showPINOption = ref<boolean>(false)
const changePasswordToken = ref<string>()

const router = useRouter()

watch(isAuthenticated, authenticated => {
  if (authenticated) {
    if (locationId.value) {
      if (componentState.value === ComponentStates.PIN) {
        selectLocationAndContinue(locationId.value)
      } else {
        router.push({ name: 'employees' })
      }
    } else {
      router.push({ name: 'locations' })
    }
  }
})

onMounted(() => {
  if (isAuthenticated.value) {
    router.push({ name: 'pos' })
  }
  const platform = Capacitor.getPlatform()
  showPINOption.value = platform !== 'web'
})

function selectLocationAndContinue(locationId: string) {
  let platform = Capacitor.getPlatform()
  if (platform !== 'web') {
    router.push({
      name: 'extraDeviceWarning',
      query: { locationId }
    })
  }
}

function onCodeVerified(token: string): void {
  changePasswordToken.value = token
  componentState.value = ComponentStates.NEW_PASSWORD
}
</script>
