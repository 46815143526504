export default class StringParser {
  static removeEmojis(input: string): string {
    const emojiRegex =
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}]/gu

    return input.replace(emojiRegex, '')
  }

  static removeEmojisFromObject(obj: Record<string, any>): Record<string, any> {
    const result: Record<string, any> = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'string') {
          result[key] = this.removeEmojis(obj[key])
        } else if (Array.isArray(obj[key])) {
          result[key] = this.removeEmojisFromArray(obj[key])
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          result[key] = this.removeEmojisFromObject(obj[key])
        } else {
          result[key] = obj[key]
        }
      }
    }
    return result
  }

  static removeEmojisFromArray(arr: any[]): any[] {
    return arr.map(item => {
      if (typeof item === 'string') {
        return this.removeEmojis(item)
      } else if (Array.isArray(item)) {
        return this.removeEmojisFromArray(item)
      } else if (typeof item === 'object' && item !== null) {
        return this.removeEmojisFromObject(item)
      } else {
        return item
      }
    })
  }
}
