<template>
  <div>
    <transition name="fade">
      <div
        v-if="open"
        class="z-20 font-body text-3 transition bg-n-900/80 backdrop-blur-sm absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden dark pt-safe"
        @click="$emit('close')"
      >
        <div
          :class="{ open: open, closed: !open }"
          class="menu transition bg-n-800 text-n-0 w-full h-full flex flex-col"
          @click.stop
        >
          <div
            class="flex-none relative bg-n-900 flex flex-row px-6 h-14 items-center"
          >
            <div class="flex-1 text-xl font-bold">
              {{ config?.locationName }}
            </div>
            <div class="flex-none text-n-0" @click="$emit('close')">
              <icon name="close" />
            </div>
          </div>
          <div class="flex-1 [&>*]:border-b [&>*]:border-b-n-700">
            <div class="mx-6 py-6 flex flex-col last:border-b-0">
              <div class="text-base font-medium">
                {{ currentEmployee?.name }}
              </div>
              <router-link :to="{ name: 'employees' }">
                <l-button type="text" class="!px-0">
                  {{ $t('sidemenu.change-employee') }}
                </l-button>
              </router-link>
            </div>
            <div
              v-if="isDataphoneWithPrinter"
              class="py-4 flex items-center mx-4 px-4"
            >
              <l-checkbox
                v-model="useSelfPrinter"
                :label="$t('sidemenu.use-self-printer')"
              />
            </div>
            <div
              v-if="!isMobile"
              class="py-4 flex items-center mx-6 px-0 last:border-b-0"
              @click="closeApplication()"
            >
              <icon size="small" name="power" />
              <span class="ml-2">{{ $t('sidemenu.close-application') }}</span>
            </div>
            <router-link
              :to="{ name: 'hardwareSelector' }"
              class="mx-6 px-0 py-4 flex items-center cursor-pointer last:border-b-0"
              @click="$emit('close')"
            >
              <icon name="calendar" class="mr-2" />
              {{ $t('sidemenu.hardware-management') }}
            </router-link>
          </div>
          <div class="flex-none sm:hidden pb-4">
            <div
              class="flex flex-row text-n-0 text-xs justify-center items-center cursor-pointer"
              @click="openAbout"
            >
              <div>{{ $t('sidemenu.version') }}: {{ appVersion }}</div>
              <icon class="px-1" name="info" size="medium" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import app from '@/app'
import { Icon, LCheckbox } from '@last/core-ui/paprika'
import { Capacitor } from '@capacitor/core'
import { computed } from 'vue'
import { LButton } from '@last/core-ui/paprika'
import { useRouter } from 'vue-router'

type Props = {
  open: boolean
}

defineProps<Props>()
defineEmits(['close'])

const router = useRouter()
const authStore = useAuthStore()

const { currentEmployee } = storeToRefs(authStore)

const configStore = useConfigStore()
const { device, useSelfPrinter, config, appVersion } = storeToRefs(configStore)

const isMobile = computed(() => {
  const platform = Capacitor.getPlatform()
  return platform !== 'web'
})

function openAbout() {
  router.push({ name: 'about' })
}

const isDataphoneWithPrinter = computed<boolean>(() => {
  return device.value?.model === 'Saturn1000F2'
})

async function closeApplication() {
  if (CashMachine.isEnabled()) {
    await CashMachine.close()
  }
  app.quit()
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter > .menu,
.fade-leave-to > .menu {
  transform: translateX(20rem);
}

.transition {
  transition: all 0.3s;
}

.closed {
  transform: translateX(20rem);
}

.open {
  transform: translateX(0rem);
}
</style>
