<template>
  <div v-if="internalReservation">
    <div v-if="selectingTable" class="absolute top-0 left-0 w-screen h-full">
      <table-selector
        class="fixed top-0 left-0 right-0"
        :title="$t('reservations.title')"
        :blocked-tables="blockedTables"
        :multiple-select="true"
        :selected-tables="internalReservation.tables"
        @close="selectingTable = false"
        @tables-selected="selectTables"
      />
    </div>
    <reservation-details
      :reservation="internalReservation"
      :open="reservationDetailsOpen"
      @close="$emit('close')"
      @select-table="
        newInternalReservation => {
          selectingTable = true
          internalReservation = newInternalReservation
        }
      "
    />
  </div>
</template>

<script setup lang="ts">
import TableSelector from '@/components/tables/TableSelector.vue'
import ReservationDetails from '@/components/ReservationDetails.vue'
import { Reservation } from '@/types'
import { computed, ref } from 'vue'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { nextTick } from 'vue'
import { extendMoment } from 'moment-range'
import moment from 'moment'
import { useConfigStore } from '@/store/config'
import { useReservationsStore } from '@/store/reservations'
import { storeToRefs } from 'pinia'
import { useTabsStore } from '@/store/tabs'

const momentWithRange = extendMoment(moment)

const props = defineProps<{
  reservation: Reservation | null
}>()

defineEmits(['close'])

const route = useRoute()
const configStore = useConfigStore()
const reservationStore = useReservationsStore()
const tabsStore = useTabsStore()
const { tabs } = storeToRefs(tabsStore)

const { sortedReservations } = storeToRefs(reservationStore)

const internalReservation = ref<Reservation | null>(null)
const selectingTable = ref(false)

const reservationDetailsOpen = computed(() => {
  return !!internalReservation.value && !selectingTable.value
})

onMounted(() => {
  if (route.query.showSelector) {
    nextTick(() => {
      selectingTable.value = true
    })
  }
  internalReservation.value = { ...props.reservation } as Reservation
})

const blockedTables = computed(() => {
  if (!internalReservation.value) return []

  const date = internalReservation.value.dateTime
  const range = momentWithRange.range(
    moment(date).subtract(configStore.config.reservations.duration, 'minutes'),
    moment(date).add(configStore.config.reservations.duration - 1, 'minutes')
  )

  const reservedTables = Object.values(sortedReservations.value)
    .filter(reservation => {
      let inRange = range.contains(moment(reservation.dateTime))
      let current = reservation.id === internalReservation.value!.id
      return inRange && !current
    })
    .flatMap(reservation => reservation.tables)

  const currentTabsTables = Object.values(tabs.value)
    .filter((tab: any) => tab.open && range.contains(moment(tab.creationTime)))
    .map((tab: any) => tab.tableId)

  return [...reservedTables, ...currentTabsTables]
})

function selectTables(tables: string[]) {
  internalReservation.value!.tables = tables
  // Temporal code to be backward compatible
  internalReservation.value!.tableId = tables[0]
  selectingTable.value = false
}
</script>
