<template>
  <div>
    <div class="flex mb-4 gap-2">
      <payment-method-selector
        class="flex-1"
        :payment-methods="paymentMethods"
        @change="changePaymentMethod"
      />
      <div
        v-if="showDiscounts"
        class="border border-n-600 px-4 flex-none text-n-0 rounded-lg cursor-pointer bg-n-700 flex items-center justify-center"
        @click="$emit('showDiscounts')"
      >
        <icon name="discount" />
      </div>
    </div>
    <probable-amounts-selector
      v-if="showProbableAmounts"
      class="mb-4"
      :initial-amount="initialPending"
      :actual-amount="calculatorValues.toPay"
      :tip-mode="calculatorTipMode"
      @change="onChangeProbableAmount"
    />
    <calculator
      ref="calculator"
      class="mb-2"
      :initial-to-pay="selectedFixedAmount"
      :pending="initialPending"
      :display-mode="displayMode"
      :keypad-mode="keypadMode"
      :tip-mode="calculatorTipMode"
      :keep-divisions="true"
      @change="calculatorChange"
      @division="onCalculatorDivision"
    />
  </div>
</template>

<script setup lang="ts">
import { PaymentMethod } from '@/types'
import PaymentMethodSelector from './PaymentMethodSelector.vue'
import ProbableAmountsSelector from '@/components/ChargeCalculator/ProbableAmountsSelector.vue'
import {
  CalculatorDisplayMode,
  CalculatorDisplayModeType,
  CalculatorKeypadMode,
  CalculatorKeypadModeType,
  CalculatorPayload
} from '@/components/Calculator/CalculatorUtils'
import { Icon } from '@last/core-ui/paprika'
import Calculator from '@/components/Calculator/Calculator.vue'
import { computed, ref, watch } from 'vue'

type Props = {
  paymentMethods: PaymentMethod[]
  initialPending?: number
  showProbableAmounts?: boolean
  displayMode?: CalculatorDisplayModeType
  keypadMode?: CalculatorKeypadModeType
  autofill?: boolean
  showDiscounts?: boolean
  keepDivisions?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  initialPending: 0,
  showProbableAmounts: true,
  keypadMode: CalculatorKeypadMode.SIMPLE,
  displayMode: CalculatorDisplayMode.CHANGE,
  autofill: true,
  showDiscounts: false,
  keepDivisions: false
})

const emit = defineEmits<{
  (e: 'changePaymentMethod', value: string): void
  (e: 'calculatorChange', value: CalculatorPayload): void
  (e: 'showDiscounts'): void
  (e: 'division', value: number): void
}>()

const calculatorValues = ref<CalculatorPayload>({
  toPay: 0,
  pending: 0,
  change: 0,
  tip: 0
})

const selectedFixedAmount = ref<number>(0)
const paymentMethod = ref<PaymentMethod>(props.paymentMethods[0])

const calculatorTipMode = computed(() => {
  return paymentMethod.value.type !== 'cash'
})

const lastDivisionValue = ref<number>(0)
watch(
  () => props.initialPending,
  value => {
    if (props.autofill) {
      selectedFixedAmount.value = value
      calculatorValues.value.toPay = value
    }
    if (props.keypadMode === 'full') {
      if (lastDivisionValue.value > 0) {
        selectedFixedAmount.value = Math.min(lastDivisionValue.value, value)
      } else {
        selectedFixedAmount.value = 0
      }
    }
    calculatorValues.value.pending = value
  },
  { immediate: true }
)

function onCalculatorDivision(value: number) {
  lastDivisionValue.value = value
  emit('division', value)
}

function calculatorChange(value: CalculatorPayload) {
  selectedFixedAmount.value = 0
  calculatorValues.value = value
  emit('calculatorChange', value)
}

function changePaymentMethod(method: string): void {
  paymentMethod.value =
    props.paymentMethods.find(m => m.type === method) || props.paymentMethods[0]
  emit('changePaymentMethod', method)
}

function onChangeProbableAmount(amount: number): void {
  selectedFixedAmount.value = amount
}
</script>
