<template>
  <div>
    <div class="p-6 bg-n-700 rounded-big h-full flex-1">
      <div
        class="flex justify-between pb-4 font-heading text-2xl font-bold text-n-0"
      >
        <span class="flex-1 text-n-10">{{ $t('tabs.order-info') }}</span>
        <icon
          v-if="tab.pickupType"
          name="pen"
          class="opacity-50 text-v-300 cursor-pointer"
          @click="editOrderInfo = true"
        />
      </div>
      <div v-if="tab.schedulingTime">
        <h2 class="pt-2 text-sm text-gray-400">
          {{ $t('tabs.scheduled-time') }}
        </h2>
        <div class="text-n-10">
          {{ $filters.day(tab.schedulingTime) }} -
          {{ $filters.time(tab.schedulingTime) }}
        </div>
      </div>
      <div v-if="tab.deliveryOrder">
        <div v-if="tab.deliveryOrder.pickupTime">
          <div>
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('tabs.pickup-time') }}
            </h2>
            <div class="text-n-10">
              {{ deliveryPickUpTime }}
            </div>
          </div>
        </div>
        <div
          v-if="tab.deliveryOrder || tab.deliveryOrder.shipmentCancelled"
          class="flex flex-row justify-between items-center"
        >
          <div v-if="tab.deliveryOrder.shipment">
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('tabs.shipment-provider') }}
            </h2>
            <div class="text-n-10">
              {{ tab.deliveryOrder.shipment.provider }}
            </div>
            <div v-if="tab.deliveryOrder.shipmentCancelled" class="text-error">
              {{ $t('tabs.shipment-cancelled') }}
            </div>
          </div>
        </div>
        <div v-if="tab.deliveryOrder.courierName">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.courier-name') }}
          </h2>
          <div class="text-n-10">
            {{ tab.deliveryOrder.courierName }}
          </div>
        </div>
        <div v-if="tab.deliveryOrder.courierPhoneNumber">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.courier-phone-number') }}
          </h2>
          <div class="text-n-10">
            {{ tab.deliveryOrder.courierPhoneNumber }}
          </div>
        </div>
        <div
          v-if="tab.deliveryOrder.address"
          class="flex flex-row items-center gap-2"
        >
          <div>
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('tabs.address') }}
            </h2>
            <div class="text-n-10">
              {{ tab.deliveryOrder.address }}
            </div>
          </div>
          <div>
            <l-button
              v-if="shouldShowMapIcon"
              icon="dots"
              size="small"
              @click="dropdownOpen = true"
            />
            <l-dropdown
              v-model:open="dropdownOpen"
              :options="dropdownOptions"
              :mark-selected="false"
              @click="() => (dropdownOpen = false)"
            />
          </div>
        </div>
        <div v-if="tab.deliveryOrder.addressDetails">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.address-details') }}
          </h2>
          <div class="text-n-10">
            {{ tab.deliveryOrder.addressDetails }}
          </div>
        </div>
      </div>
      <div v-if="tab.customerInfo">
        <div v-if="tab.customerInfo.name">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-name') }}
          </h2>
          <div class="text-n-10 flex justify-between items-center relative">
            {{ tab.customerInfo.name }}
            <l-tool-tip
              v-if="tab.customerInfo.internalNote"
              :message="tab.customerInfo.internalNote"
              position="left"
              size="small"
            >
              <icon class="cursor-pointer" name="support"
            /></l-tool-tip>
          </div>
        </div>
        <div v-if="tab.customerInfo.phoneNumber">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-phone-number') }}
          </h2>
          <div class="text-n-10">
            {{ tab.customerInfo.phoneNumber }}
          </div>
        </div>
        <div v-if="tab.customerInfo.phoneNumberCode">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.phone-number-code') }}
          </h2>
          <div class="text-n-10">
            {{ tab.customerInfo.phoneNumberCode }}
          </div>
        </div>
        <div v-if="tab.customerInfo.email">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-email') }}
          </h2>
          <div class="text-n-10">
            {{ tab.customerInfo.email }}
          </div>
        </div>
      </div>
      <div v-if="reservationLinked">
        <div class="flex justify-between items-center">
          <div>
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('reservations.source') }}
            </h2>
            <div class="text-n-10">
              {{ reservationLinked.source || 'Restaurant' }}
            </div>
          </div>
          <icon
            name="link-break"
            small
            class="text-red cursor-pointer"
            @click="unlinkTabFromReservation()"
          />
        </div>
        <div v-if="reservationLinked.name">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-name') }}
          </h2>
          <div class="text-n-10">
            {{ reservationLinked.name }}
          </div>
        </div>
        <div v-if="reservationLinked.phoneNumber">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-phone-number') }}
          </h2>
          <div class="text-n-10">
            {{ reservationLinked.phoneNumber }}
          </div>
        </div>
        <div v-if="reservationLinked.email">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-email') }}
          </h2>
          <div class="text-n-10">
            {{ reservationLinked.email }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="tab.source === 'Glovo'"
      class="p-3 mt-4 text-n-0 uppercase rounded-big bg-yellow"
    >
      <div class="text-center">{{ $t('tabs.delivery-support') }}</div>
      <div class="flex items-center justify-center text-xl font-bold">
        <icon name="phone" class="mr-2" />+34 93 003 80 60
      </div>
    </div>
    <teleport to="#appRoot">
      <ClientDetails
        v-if="editOrderInfo === true"
        hide-back-button
        is-edit
        :customer="{
          ...tab.customerInfo,
          id: tab.customerId,
          addresses: addressesFromSelectedTab
        }"
        :delivery="{
          ...tab.deliveryOrder,
          details: tab.deliveryOrder
            ? tab.deliveryOrder.addressDetails
            : undefined
        }"
        :new-tab="tab"
        @close="editOrderInfo = false"
      />
      <shipment-info
        v-if="showMap || showCancelShipment"
        :tab="tab"
        :cancel="showCancelShipment"
        @close="closeShipmentInfo"
      />
    </teleport>
    <courier-selector
      v-if="showCourierPopUp"
      :open-tabs="openTabs"
      @close="showCourierPopUp = false"
      @assign-courier="courier => setCourier(courier)"
    />
  </div>
</template>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import ClientDetails from '@/components/tabs/ClientDetails.vue'
import LToolTip from '@last/core-ui/components/LToolTip.vue'
import ShipmentInfo from '@/components/tabs/ShipmentInfo.vue'
import CourierSelector from '@/components/tabs/CourierSelector.vue'
import { useReservationsStore } from '@/store/reservations'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { Icon, LDropdown, LButton } from '@last/core-ui/paprika'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Tab } from '@/types'

const props = defineProps<{
  tab: Tab
}>()

const { t } = useI18n()

const reservationsStore = useReservationsStore()
const tabsStore = useTabsStore()

const { editReservation } = reservationsStore
const { getNearReservations } = storeToRefs(reservationsStore)
const { tabs } = storeToRefs(tabsStore)
const { updateDeliveryOrderStatus } = tabsStore

const editOrderInfo = ref(false)
const showMap = ref(false)
const showCancelShipment = ref<boolean>(false)
const showCourierPopUp = ref(false)
const dropdownOpen = ref(false)
const dropdownOptions = [
  {
    label: t('tabs.show-map'),
    value: 'showMap',
    onClick: () => {
      showMap.value = true
      dropdownOpen.value = false
    }
  },
  {
    label: t('tabs.cancel-shipment'),
    value: 'showCancelShipment',
    onClick: () => {
      showCancelShipment.value = true
      dropdownOpen.value = false
    }
  }
]

const reservationLinked = computed(() =>
  Object.values(getNearReservations.value).find(
    reservation => reservation.tabId === props.tab.id
  )
)

const shouldShowMapIcon = computed(
  () =>
    props.tab.pickupType === 'ownDelivery' && !!props.tab.deliveryOrder?.address
)

const addressesFromSelectedTab = computed(() => [
  {
    id: uuid(),
    customerId: props.tab.customerId,
    address: props.tab.deliveryOrder && props.tab.deliveryOrder.address,
    details: props.tab.deliveryOrder && props.tab.deliveryOrder.addressDetails,
    postalCode: props.tab.deliveryOrder && props.tab.deliveryOrder.postalCode
  }
])

const openTabs = computed(() =>
  Object.values(tabs.value).filter(tab => tab.open)
)

const deliveryPickUpTime = computed(() => {
  if (moment().diff(moment(props.tab.deliveryOrder?.pickupTime), 'days') > 0) {
    return moment(props.tab.deliveryOrder?.pickupTime).format(
      'DD/MM/YYYY HH:mm'
    )
  } else {
    return moment(props.tab.deliveryOrder?.pickupTime).format('HH:mm')
  }
})

function unlinkTabFromReservation() {
  const reservation = {
    ...reservationLinked.value,
    tabId: null
  }
  editReservation(reservation)
}

function closeShipmentInfo() {
  showMap.value = false
  showCancelShipment.value = false
}

function setCourier(selectedCourier: string) {
  showCourierPopUp.value = false
  updateDeliveryOrderStatus({
    tabId: props.tab.id,
    newStatus: 'ON_DELIVERY',
    courier: selectedCourier
  })
}
</script>
