<template>
  <div
    class="flex items-center bg-gray-200 rounded-full px-4 py-2"
    :class="{
      'bg-blue bg-secondary text-white': modelValue,
      'text-blue text-secondary': !modelValue,
      'opacity-50': disabled && !modelValue,
      'cursor-pointer': !disabled && !modelValue
    }"
    @click="click"
  >
    <icon
      v-if="icon"
      :name="icon"
      class="pr-2"
      :class="{
        'text-white': modelValue,
        'text-blue text-secondary': !modelValue
      }"
    />
    <div class="text-sm">
      {{ label }}
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'LPill',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon
  },
  emits: ['click', 'update:modelValue'],
  methods: {
    click() {
      this.$emit('update:modelValue', !this.modelValue)
      this.$emit('click')
    }
  }
}
</script>

<style>
.circle-padding {
  padding: 3px;
}
</style>
