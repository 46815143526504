<template>
  <div class="w-full">
    <div class="flex w-full pb-4 items-center space-x-2">
      <div
        id="prev-month"
        class="w-7 h-7 flex justify-center items-center cursor-pointer rounded-full hover:bg-n-50 dark:hover:bg-n-600"
        :class="{ 'pointer-events-none opacity-50': cantGoBack }"
        @click="previousMonth()"
      >
        <icon name="arrow-left" class="text-n-600 dark:text-n-0" small />
      </div>
      <div v-if="showSelectors" class="flex flex-1 space-x-2">
        <l-select v-model="month" :options="months" class="flex-1" />
        <l-select v-model="year" :options="years" class="flex-1" />
      </div>
      <div v-else class="flex-1 font-body text-n-800 dark:text-n-0 text-center">
        {{ month }} {{ year }}
      </div>
      <div
        id="next-month"
        class="w-7 h-7 flex justify-center items-center rounded-full cursor-pointer hover:bg-n-50 dark:hover:bg-n-600"
        @click="nextMonth()"
      >
        <icon name="arrow-right" class="text-n-600 dark:text-n-0" small />
      </div>
    </div>
    <div class="flex w-full text-n-400 text-xs pb-3">
      <div v-for="day in weekdays" :key="day" class="flex-1 text-center">
        {{ day }}
      </div>
    </div>
    <div class="w-full pb-2">
      <div v-for="(row, index) in days" :key="index" class="flex-1 flex">
        <div
          v-for="day in row"
          :id="'day-' + day.day"
          :key="day.date"
          class="flex-1 text-n-800 dark:text-n-0 text-sm flex justify-center py-0.5"
          :class="{ 'opacity-30': !day.enabled, 'cursor-pointer': day.enabled }"
          @click="select(day)"
        >
          <div
            class="w-8 h-8 rounded-full leading-8 text-center"
            :class="{
              'hover:bg-n-50 dark:hover:bg-n-600': day.enabled && !day.selected,
              'bg-v-300 text-white': day.selected
            }"
          >
            {{ day.day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, defineModel } from 'vue'
import { Icon, LSelect } from '@last/core-ui/paprika'
import baseMoment from 'moment'
import * as MomentRange from 'moment-range'

const moment = MomentRange.extendMoment(baseMoment)

type Props = {
  unavailableDates?: string[]
  showSelectors?: boolean
  maxDate?: Date | null
  onlyFuture?: boolean
  onlyWorkingDays?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  unavailableDates: () => [],
  showSelectors: false,
  maxDate: null,
  onlyFuture: false,
  onlyWorkingDays: false
})

const model = defineModel<string | null>({
  default: null
})

const emit = defineEmits(['monthChanged'])

const currentDate = ref(moment())

onMounted(() => {
  if (model.value) {
    currentDate.value = moment(model.value)
  }
  emit('monthChanged', currentDate.value.format('YYYY-MM'))
})

const years = computed(() => {
  let years = []
  let pastYears = [
    ...Array(3)
      .fill(0)
      .map((_, i) => moment().year() - i - 1)
  ]
  let futureYears = [
    moment().year(),
    ...Array(3)
      .fill(0)
      .map((_, i) => moment().year() + i + 1)
  ]
  if (props.onlyFuture) {
    years = futureYears
  } else {
    years = [...pastYears, ...futureYears]
  }
  return years.sort().map(y => {
    return { label: y.toString(), value: y.toString() }
  })
})

const cantGoBack = computed(() => {
  return (
    props.onlyFuture &&
    moment().month() === currentDate.value.month() &&
    moment().year() === currentDate.value.year()
  )
})

const weekdays = computed(() => {
  let week = moment().startOf('isoWeek').locale('es')
  return Array.apply(null, Array(7)).map((_, i) => {
    return week
      .isoWeekday(i + 1)
      .format('ddd')
      .toUpperCase()
      .slice(0, 3)
  })
})
const days = computed(() => {
  let firstWeek = moment(currentDate.value).startOf('month').startOf('isoWeek')
  if (firstWeek.isoWeekday() !== 1) {
    firstWeek.subtract(1, 'isoWeek')
  }
  return chunks(
    [...moment.rangeFromInterval('days', 6 * 7 - 1, firstWeek).by('days')].map(
      day => {
        let date = day.format('YYYY-MM-DD')
        let maxDateFilter = props.maxDate ? day < moment(props.maxDate) : true
        return {
          date,
          day: day.date(),
          enabled:
            day.month() === currentDate.value.month() &&
            !props.unavailableDates.includes(date) &&
            (!props.onlyFuture || day >= moment().startOf('day')) &&
            maxDateFilter &&
            (!props.onlyWorkingDays || day.isoWeekday() < 6),
          selected: date === moment(model.value).format('YYYY-MM-DD')
        }
      }
    ),
    7
  )
})

const months = computed(() => {
  let months = moment.monthsShort().map(m => {
    return { label: m, value: m }
  })
  let now = moment()
  if (currentDate.value.year() === now.year() && props.onlyFuture) {
    months.splice(0, now.month())
  }
  return months
})

const month = computed({
  get() {
    if (props.showSelectors) {
      return currentDate.value.format('MMM')
    } else {
      return currentDate.value.format('MMMM')
    }
  },
  set(value) {
    currentDate.value.set('month', moment(value, 'MMM').month())
    currentDate.value = moment(currentDate.value.toDate())
  }
})

const year = computed({
  get() {
    return currentDate.value.format('YYYY')
  },
  set(value) {
    currentDate.value.set('year', value)
    currentDate.value = moment(currentDate.value.toDate())
  }
})

watch(
  () => model.value,
  value => {
    if (value) {
      currentDate.value = moment(value)
    }
  }
)

function nextMonth() {
  currentDate.value = moment(currentDate.value.add(1, 'month'))
}
function previousMonth() {
  currentDate.value = moment(currentDate.value.subtract(1, 'month'))
}
function chunks(list, size) {
  var res = []
  for (var i = 0; i < list.length; i += size) {
    res.push(list.slice(i, i + size))
  }
  return res
}

function select(date) {
  if (date.enabled) {
    let newDate = moment(date.date)
    model.value = newDate.format('YYYY-MM-DD')
  }
}
</script>

<style scoped>
.bg-red {
  background-color: red;
}
</style>
