import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'

// eslint-disable-next-line no-undef
const api = <T = any>(url: string, options: RequestInit) => {
  const auth = useAuthStore()
  const token = auth.accessToken

  const headers = options?.headers
    ? new Headers(options.headers)
    : new Headers()
  if (token) {
    headers.set('Authorization', token)
  }

  const locationId = auth.locationId
  if (locationId) {
    headers.set('Location-ID', locationId)
  }

  const organizationId = auth.organizationId
  if (organizationId) {
    headers.set('Organization-ID', organizationId)
  }

  const employeeId = auth.currentEmployeeId
  if (employeeId) {
    headers.set('Employee-ID', employeeId)
  }

  const configStore = useConfigStore()
  const isDemo = configStore.demoMode
  if (isDemo && (options.method !== 'get' || url === '/resync')) {
    return Promise.reject() //FIXME
  }

  let body = options.body
  if (body) {
    body = JSON.stringify(body)
    headers.set('Content-Type', 'application/json')
  }

  return fetch(new URL(url, import.meta.env.VITE_APP_BASE_URL), {
    ...options,
    headers,
    body
  }).then(async response => {
    if (!response.ok) {
      throw response
    }
    if (response.status === 401) {
      await auth.logout()
    }

    const isJson = !!response.headers
      .get('content-type')
      ?.includes('application/json')
    const res = isJson ? await response.json() : await response.text()

    if (response.status >= 200 && response.status < 400) {
      return { data: res as T }
    }

    throw res
  })
}

export const get = <T = any>(url: string, params?: Record<string, any>) => {
  const { headers, ...restParams } = params || {}
  const urlWithParams = new URL(url, import.meta.env.VITE_APP_BASE_URL)
  const searchParams = new URLSearchParams(restParams)
  searchParams.forEach((value, key) => {
    urlWithParams.searchParams.set(key, value)
  })
  return api<T>(urlWithParams.toString(), { method: 'GET', headers })
}
export const post = <T>(url: string, body: any) =>
  api<T>(url, {
    method: 'POST',
    body
  })
export const put = <T>(url: string, body: any) =>
  api<T>(url, {
    method: 'PUT',
    body
  })
export const del = <T>(url: string) => api<T>(url, { method: 'DELETE' })

export default {
  get,
  post,
  put,
  del
}
