<template>
  <scale-to-fit>
    <div
      id="plan-container"
      style="width: 580px; height: 400px; position: relative"
      ref="planCanvas"
    >
      <div
        v-for="table in tables"
        @click="selectTable(table)"
        :key="table.id"
        :style="getStyle(table)"
        :parent="true"
        :class="[
          table.type,
          getMovingClass(table),
          { disabled: blockedTables.includes(table.id) },
          { 'is-selected': selectedTables.includes(table.id) }
        ]"
        class="table relative"
      >
        <div class="flex flex-col justify-between h-full w-full">
          <div class="flex items-center justify-center h-full">
            <div
              class="text-center uppercase font-text font-bold text-xs line-height"
            >
              {{ table.name }}
            </div>
          </div>
          <div
            class="flex items-center justify-center pb-2"
            v-if="table.seats > 1 && table.type !== 'wall'"
          >
            <icon name="diners" class="opacity-50 mr-1 diners-size" />
            {{ table.seats }}
          </div>
        </div>
      </div>
    </div>
  </scale-to-fit>
</template>

<script>
import ScaleToFit from './ScaleToFit.vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import { useTablesStore } from '@/store/tables'
import { storeToRefs } from 'pinia'

export default {
  name: 'TableSelectorFloorplan',
  props: {
    floorplanId: {
      type: String,
      default: null
    },
    blockedTables: {
      type: Array,
      default: () => []
    },
    selectedTables: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const tablesStore = useTablesStore()
    const { getTables } = storeToRefs(tablesStore)

    return {
      getTables
    }
  },
  methods: {
    getStyle(table) {
      return {
        position: 'absolute',
        width: table.width + 'px',
        height: table.height + 'px',
        left: table.x + 'px',
        top: table.y + 'px',
        'animation-duration': 0.3 + 's',
        'animation-delay': -(Math.random() + 0.3) + 's'
      }
    },
    getMovingClass(table) {
      if (table.type === 'wall' || this.blockedTables.includes(table.id))
        return ''
      return `moving-${this.getAngle(Math.max(table.width, table.height))}`
    },
    selectTable(table) {
      if (table.type === 'wall' || this.blockedTables.includes(table.id)) return
      this.$emit('tablesSelected', table.id)
    },
    getAngle(size) {
      const maxAngle = 6
      const maxSize = 200
      const stepSize = maxSize / maxAngle

      const angle = maxAngle - parseInt(size / stepSize)

      return angle <= 2 ? 2 : angle >= maxAngle ? maxAngle : angle
    }
  },
  computed: {
    tables() {
      return this.getTables(this.floorplanId)
    }
  },
  components: {
    ScaleToFit,
    Icon
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.table {
  color: #545383;
  background: #f7fafc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-size: 10px;
  &.is-selected {
    @apply bg-yellow text-n-0;
  }
}

.rectangle {
  border-radius: 15px;
}

.circle {
  border-radius: 500px;
}

.wall {
  background: #100f1b;
  color: #545383;
}

.line-height {
  line-height: 10px;
}

.diners-size {
  width: 10px;
  height: 10px;
}

.circle {
  width: 5px;
  height: 5px;
  padding: 2px;
  margin-right: -4px;
  margin-top: -5px;
  top: 50%;
}

.selected {
  background: yellow;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@for $i from 1 to 6 {
  .moving-#{$i} {
    animation-name: wiggle-#{$i};
    animation-iteration-count: infinite;
  }
}

@for $i from 1 through 6 {
  @keyframes wiggle-#{$i} {
    0% {
      transform: rotate(#{$i}deg);
      animation-timing-function: ease-in;
    }
    50% {
      transform: rotate(-#{$i}deg);
      animation-timing-function: ease-out;
    }
    100% {
      transform: rotate(#{$i}deg);
      animation-timing-function: ease-out;
    }
  }
}
</style>
