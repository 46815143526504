<template>
  <div ref="wrapperRef" class="relative w-full h-full grow flex justify-center">
    <div
      ref="contentRef"
      class="origin-[50%_0] absolute inline-block"
      :style="{ transform: 'scale(' + scale + ')' }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { computed } from 'vue'

const contentRef = ref<HTMLDivElement | null>(null)
const wrapperRef = ref<HTMLDivElement | null>(null)

const wrapperEl = useElementSize(wrapperRef)

const scale = computed(() => {
  if (!contentRef.value) return 1
  const xScale = wrapperEl.width.value / contentRef.value.clientWidth
  const yScale = wrapperEl.height.value / contentRef.value.clientHeight
  return Math.min(xScale, yScale)
})
</script>
