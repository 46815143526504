<template>
  <product-component
    :product="product"
    :disabled="disabled"
    :disable-swipe="disableSwipe"
    :show-quantity-selector="!disabledQuantitySelector"
    allow-remove
    show-sent-to-kitchen
    show-billing-status
    @selected="emit('selected')"
  ></product-component>
</template>

<script setup lang="ts">
import ProductComponent from '@/components/core/Product.vue'
import { useTabsStore } from '@/store/tabs'
import useProducts from '@/composables/useProducts'
import { computed } from 'vue'
import type { Product } from '@/types'
import { toRef } from 'vue'

const props = defineProps({
  product: {
    type: Object as () => Product,
    default: null
  },
  disableSwipe: {
    type: Boolean,
    default: false
  },
  disabledQuantitySelector: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['selected'])

const tabStore = useTabsStore()
const { productIsBilled } = useProducts(toRef(props, 'product'))

const { getSentToKitchenTime } = tabStore

const disabled = computed(() => {
  return !!sentTime.value || productIsBilled.value
})

const sentTime = computed(() => {
  return getSentToKitchenTime(props.product.id)
})
</script>
