<template>
  <div class="flex flex-col pt-3 gap-3 border-t border-n-600">
    <div class="flex flex-col px-3 gap-1 text-n-0 font-body text-sm leading-4">
      <div v-if="getGlobalDiscount?.amount" class="flex justify-between">
        <div class="flex gap-1">
          <div>{{ $t('tabs.discount') }}</div>
          <div v-if="getGlobalDiscount.type === 'percentage'">
            {{ getGlobalDiscount?.amount }}%
          </div>
        </div>
        <div>-{{ $filters.currency(getPendingBill.discountTotal) }}</div>
      </div>
      <div v-if="getMinimumBasketSurcharge" class="flex justify-between">
        <div>
          {{ $t('tabs.minimum-basket-surcharge') }}
        </div>
        <div>
          {{ $filters.currency(getMinimumBasketSurcharge) }}
        </div>
      </div>
      <div
        v-if="getDeliveryFee.isFree || getDeliveryFee.value > 0"
        class="flex justify-between"
      >
        <div>{{ $t('ordering.delivery-fee') }}</div>
        <div v-if="getDeliveryFee.isFree">
          {{ $t('ordering.free-delivery') }}
        </div>
        <div v-else>{{ $filters.currency(getDeliveryFee.value) }}</div>
      </div>
      <div v-if="extraCharges" class="flex justify-between items-center">
        <div class="text-sm">{{ $t('tabs.extra-charges') }}</div>
        <div>
          {{ $filters.currency(extraCharges) }}
        </div>
      </div>
      <div
        class="flex items-center justify-between font-heading font-bold text-xl leading-5"
      >
        <div>{{ $t('tabs.total') }}</div>
        <div class="flex items-center gap-2">
          <tab-payment-status v-if="getTotal > 0" :tab-id="tabId" />
          <div>{{ $filters.currency(getTotal) }}</div>
        </div>
      </div>
    </div>
    <template v-if="showPending">
      <div v-if="size === 'large'" class="flex border-t border-b border-n-600">
        <div
          class="flex flex-col py-2 border-r border-n-600 items-center justify-center flex-1 text-g-500 text"
        >
          <div>{{ $t('checkout.paid') }}</div>
          <div class="font-heading font-medium text-base leading-4">
            {{ $filters.currency(getTotalPaid) }}
          </div>
        </div>
        <div
          class="flex flex-col py-2 items-center justify-center flex-1 text-r-300"
        >
          <div>{{ $t('checkout.pending') }}</div>
          <div class="font-heading font-medium text-base leading-4">
            {{ $filters.currency(getTotalPending) }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex text-r-300 justify-between p-3 border-t border-b border-n-600 box-border font-body text-sm leading-4"
      >
        <div>{{ $t('checkout.pending') }}</div>
        <div>{{ $filters.currency(getTotalPending) }}</div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import TabPaymentStatus from './TabPaymentStatus.vue'
import { useTabs } from '@/composables/useTabs'
import { computed } from 'vue'

type Props = {
  tabId: string
  size: 'small' | 'large'
}
const props = withDefaults(defineProps<Props>(), {
  size: 'large'
})

const tabId = computed(() => props.tabId)

const {
  getTotal,
  getTotalPaid,
  getTotalPending,
  getDeliveryFee,
  getGlobalDiscount,
  getMinimumBasketSurcharge,
  getPendingBill,
  extraCharges
} = useTabs(tabId)

const showPending = computed(() => getTotalPending.value > 0)
</script>
