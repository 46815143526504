import api from '@/api'

class AdyenPrinter {
  constructor(config) {
    this.config = config
  }

  async printImage(imageDataUrl) {
    let img = new Image()
    let config = this.config

    img.onload = async function () {
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      let parts = []
      let imgWidth = img.width
      let imgHeight = img.height
      canvas.width = imgWidth

      let currentHeight = 0
      let adderStep = 1000
      let currentAdder = adderStep
      while (currentHeight + currentAdder < imgHeight) {
        canvas.height = currentAdder
        ctx.drawImage(
          img,
          0,
          currentHeight,
          imgWidth,
          currentAdder,
          0,
          0,
          imgWidth,
          currentAdder
        )
        let dataUrl = canvas.toDataURL('image/webp', 0.01)
        if (dataUrl.length > 90000) {
          parts.push(dataUrl)
          currentHeight += currentAdder
          currentAdder = adderStep
        } else {
          currentAdder += adderStep
        }
      }
      canvas.height = Math.min(currentAdder, imgHeight)
      ctx.drawImage(
        img,
        0,
        currentHeight,
        imgWidth,
        currentAdder,
        0,
        0,
        imgWidth,
        currentAdder
      )
      parts.push(canvas.toDataURL('image/webp', 0.01))

      for (let part of parts) {
        let partData = part.split('base64,')[1]
        await api.post('adyen/print', {
          imageBase64: partData,
          poiid: config.metadata.poiid
        })
      }
    }
    img.src = imageDataUrl
  }

  openCashDrawer() {
    // NO_OP
  }
}

export default AdyenPrinter
