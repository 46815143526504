import Bills from '@last/core/src/billsGenerator.js'
import { useTabsStore } from './tabs'
import { defineStore } from 'pinia'
import { useConfigStore } from './config'
import { useTabs } from '@/composables/useTabs'

export const useBillingStore = defineStore('billing', {
  getters: {
    getCurrentBill:
      () =>
      ({
        tabId,
        currentDiscount,
        selectedProductIds = [],
        includeAlreadyBilled = false
      }) => {
        const { tab, getAllProducts } = useTabs(tabId)
        let selectedProducts = []
        if (selectedProductIds.length > 0) {
          let tabProducts = getAllProducts.value
          selectedProducts = Object.values(
            selectedProductIds
              .map(selection => {
                let product = tabProducts.find(
                  tabProduct => tabProduct.id === selection.id
                )
                if (product.discountType === '2x1') {
                  product.discountType = null
                  product.discountAmount = null
                }
                return product
              })
              .reduce((products, product) => {
                products[product.id] = products[product.id] || {
                  ...product,
                  quantity: 0
                }
                products[product.id].quantity += 1
                return products
              }, {})
          )
        } else {
          selectedProducts = getAllProducts.value.map(product => {
            return {
              ...product,
              quantity: includeAlreadyBilled
                ? product.quantity
                : product.notBilledQuantity ?? 0
            }
          })
        }
        let config = useConfigStore()
        return Bills.generateProductsBill({
          products: selectedProducts,
          company: config.config.company,
          tab: tab.value,
          discount: currentDiscount,
          taxRate: config.config.taxRate,
          ticketInfo: config.config.ticketInfo
        })
      }
  }
})
