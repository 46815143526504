import { normalize, schema } from 'normalizr'

const productSchema = new schema.Entity('products')
const kitchenOrderSchema = new schema.Entity('kitchenOrders')
const employeeSchema = new schema.Entity('employees')
const paymentSchema = new schema.Entity('payments')
const billSchema = new schema.Entity('bills', {
  payments: [paymentSchema]
})
const tabSchema = new schema.Entity('tabs', {
  shared: [productSchema],
  seats: [[productSchema]],
  kitchenOrders: [kitchenOrderSchema],
  bills: [billSchema]
})

export const normalizeTab = tab => normalize(tab, tabSchema).entities
export const normalizeTabs = tabs => normalize(tabs, [tabSchema]).entities
export const normalizeEmployees = employees =>
  normalize(employees, [employeeSchema]).entities
