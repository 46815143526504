<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-bar show-back @back="$router.go(-1)">
      <template #center>
        <div class="text-xl flex items-center">
          {{ $t('couriers.title') }}
        </div>
      </template>
    </top-bar>
    <div
      class="overflow-y-scroll scrolling-touch bg-gray-200 w-full h-full flex justify-center"
    >
      <div class="py-12">
        <div
          v-if="couriersReportFiltered"
          class="bg-n-0 rounded-big shadow fixed-w overflow-hidden flex flex-col overflow-hidden p-8 mb-20"
        >
          <div class="font-text text-xl text-blue uppercase font-bold">
            {{ $t('couriers.title') }}
          </div>
          <div class="mt-5">
            <div
              class="flex flex-row justify-between text-gray-400 font-body text-xs"
            >
              <div>
                {{ $t('couriers.payment-method') }}
              </div>
              <div class="mr-5">{{ $t('couriers.total') }}</div>
            </div>
            <div class="separator bg-gray-200 mt-2 -mx-8" />
          </div>
          <div>
            <l-collapse
              v-for="courierId in Object.keys(couriersReportFiltered)"
              :key="courierId"
              class="mt-8 first:mt-0 first:bg-red"
              open
            >
              <template #trigger="props">
                <div
                  class="text-blue mt-5 flex flex-row items-center justify-between w-full -ml-5"
                >
                  <div class="flex flex-row items-center">
                    <icon
                      :name="'arrow-right'"
                      class="arrow mr-2"
                      :class="{ rotated: props.open }"
                    />
                    <div class="font-bold">{{ couriers[courierId].name }}</div>
                  </div>
                  <div class="text-gray-400 font-bold text-sm">
                    {{ $filters.currency(courierTotal(courierId)) }}
                  </div>
                </div>
              </template>
              <div class="rounded bg-gray-100 py-3 mt-3 px-3">
                <div
                  v-for="method in Object.keys(
                    couriersReportFiltered[courierId].payments
                  )"
                  :key="method"
                  class="mt-3 first:mt-0"
                >
                  <div class="flex flex-row justify-between">
                    <div class="text-blue text-sm">
                      {{ getPaymentMethodLabel(method) }}
                    </div>
                    <div class="text-green text-sm mr-2">
                      {{
                        $filters.currency(
                          couriersReportFiltered[courierId].payments[method]
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <courier-report-table
                class="mt-8"
                :report="couriersReportFiltered[courierId].tabs"
              />
              <div
                class="flex flex-row justify-end items-center text-blue uppercase text-sm"
              >
                <div
                  class="cursor-pointer"
                  @click="startCourierReset(courierId)"
                >
                  {{ $t('couriers.reset-courier') }}
                </div>
                <div
                  class="px-5 py-2 border rounded-lg ml-5 cursor-pointer"
                  @click="printCourierReport(courierId)"
                >
                  {{ $t('couriers.print-report') }}
                </div>
              </div>
            </l-collapse>
          </div>
        </div>
      </div>
    </div>
    <l-modal
      v-if="showResetCourierModal"
      size="small"
      :title="$t('couriers.reset-courier')"
      class="pt-40"
      @close="showResetCourierModal = false"
    >
      <template #body>
        <div class="text-blue text-lg text-center">
          {{
            $t('couriers.reset-message', { courierName: selectedCourier.name })
          }}
        </div>
        <l-modal-ctas>
          <l-modal-button
            type="secondary"
            class="w-1/2 mr-2"
            :label="$t('couriers.cancel')"
            @click="showResetCourierModal = false"
          />
          <l-modal-button
            type="main"
            class="w-1/2 ml-2"
            :label="$t('couriers.reset')"
            @click="resetCourier()"
          />
        </l-modal-ctas>
      </template>
    </l-modal>
  </div>
</template>

<script>
import TopBar from '@/components/core/TopBar.vue'
import api from '@/api'
import LCollapse from '@last/core-ui/v3/components/LCollapse.vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import CourierReportTable from '@/components/couriersReport/CourierReportTable.vue'
import TicketPrinter from '@/ticketPrinter.js'
import LModal from '@last/core-ui/v3/components/LModal.vue'
import LModalCtas from '@last/core-ui/v3/components/LModalCtas.vue'
import LModalButton from '@last/core-ui/v3/components/LModalButton.vue'
import { useCouriersStore } from '@/store/couriers'
import { storeToRefs } from 'pinia'

export default {
  name: 'CouriersReport',
  components: {
    TopBar,
    LCollapse,
    Icon,
    CourierReportTable,
    LModal,
    LModalCtas,
    LModalButton
  },
  setup() {
    const couriersStore = useCouriersStore()
    const { couriers } = storeToRefs(couriersStore)

    return {
      couriers
    }
  },
  data() {
    return {
      couriersReport: null,
      showResetCourierModal: false,
      selectedCourier: null
    }
  },
  computed: {
    couriersReportFiltered() {
      if (!this.couriersReport) {
        return null
      }
      return Object.keys(this.couriersReport).reduce((res, courierId) => {
        if (this.couriers[courierId]) {
          res[courierId] = this.couriersReport[courierId]
        }
        return res
      }, {})
    }
  },
  async mounted() {
    await this.updateCouriersReport()
  },
  methods: {
    async updateCouriersReport() {
      let response = await api.get('/reports/couriers_v2')
      this.couriersReport = response.data
    },
    startCourierReset(courierId) {
      this.selectedCourier = this.couriers[courierId]
      this.showResetCourierModal = true
    },
    printCourierReport(courierId) {
      TicketPrinter.printCourierReport({
        ...this.couriersReport[courierId],
        total: this.courierTotal(courierId),
        courierName: this.couriers[courierId].name
      })
    },
    async resetCourier() {
      let courierId = this.selectedCourier.id
      await api.post(`/reports/courier/${courierId}/reset`)
      await this.updateCouriersReport()
      this.showResetCourierModal = false
    },
    courierTotal(courierId) {
      if (!this.couriersReport[courierId]) {
        return 0
      }
      return Object.values(this.couriersReport[courierId].payments).reduce(
        (total, amount) => (total += amount),
        0
      )
    },
    getPaymentMethodLabel(method) {
      switch (method) {
        case 'cash':
          return this.$t('couriers.cash')
        case 'card':
          return this.$t('couriers.card')
        default:
          return method
      }
    }
  }
}
</script>

<style scoped>
.fixed-w {
  width: 660px;
}

.separator {
  height: 1px;
}

.arrow {
  transition: all 0.3s;
}

.rotated {
  transform: rotate(90deg);
}
</style>
