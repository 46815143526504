<template>
  <l-modal
    v-if="!previewOrderId"
    size="small"
    :title="$t('resend-kitchen-orders.title')"
    :button-enabled="hasFailedOrders && !previewOrderId"
    :button-text="$t('resend-kitchen-orders.resend-failed')"
    @close="$emit('close')"
    @action="resendFailed"
  >
    <div
      v-for="order in orders"
      :key="order.id"
      class="flex items-center gap-3 bg-n-700 rounded-xl py-6 px-4 mb-4"
      @click="previewOrderId = order.id"
    >
      <div class="text-success">{{ $filters.time(order.creationTime) }}</div>
      <div class="text-n-0 font-heading font-medium">
        {{ order.description }}
      </div>
      <l-status
        v-if="order.printable && !order.printed"
        class="ml-auto"
        status="error"
      >
        {{ $t('resend-kitchen-orders.error') }}
      </l-status>
    </div>
  </l-modal>
  <l-modal
    v-if="previewOrderId"
    :button-text="$t('resend-kitchen-orders.resend')"
    @close="previewOrderId = null"
    @action="resendOrder(previewOrderId)"
  >
    <div class="flex justify-center">
      <div v-if="previewOrderId && canvasImage" class="bg-n-0 rounded-lg p-2">
        <img :src="canvasImage" />
      </div>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import TicketPrinter from '@/ticketPrinter.js'
import KitchenOrderFormatter from '@/kitchenOrderFormatter.js'
import TicketGenerator from '@/tickets/generator.js'
import { LModal, LStatus } from '@last/core-ui/paprika'
import { useTabsStore } from '@/store/tabs'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { ref } from 'vue'
import { computed } from 'vue'
import { watch } from 'vue'
import { useTabs } from '@/composables/useTabs'

const props = defineProps<{
  tabId: string
}>()

const emit = defineEmits(['close'])

const tabsStore = useTabsStore()
const catalogStore = useCatalogStore()
const configStore = useConfigStore()

const { kitchenOrders } = storeToRefs(tabsStore)
const { getCatalogIdByTabVirtualBrandId, catalogs } = storeToRefs(catalogStore)
const { config } = storeToRefs(configStore)
const { getKitchenOrder } = useKitchenOrders(props.tabId)
const { tab } = useTabs(props.tabId)

const previewOrderId = ref<string | null>(null)
const canvasImage = ref<string | null>(null)

const hasFailedOrders = computed(() => {
  return (
    orders.value.filter(order => !order.printed && order.printable).length > 0
  )
})
const orders = computed(() => {
  let orders = tab.value.kitchenOrders.map(id => kitchenOrders.value[id])
  return Object.values(orders)
    .map(order => {
      return {
        id: order.id,
        creationTime: order.creationTime,
        description: order.printerName,
        copies: order.copies,
        printed: !!order.printedTime,
        printable: order.copies > 0
      }
    })
    .sort((a, b) => b.creationTime - a.creationTime)
})

const previewPrinter = computed(() => {
  return kitchenOrders.value[previewOrderId.value]
    ? config.value.printers[kitchenOrders.value[previewOrderId.value].printerId]
    : null
})

const previewOrder = computed(() => {
  if (!previewOrderId.value) return null
  let catalogId = getCatalogIdByTabVirtualBrandId.value(
    tab.value.virtualBrandId,
    tab.value.pickupType,
    tab.value.source
  )
  let sortedCourses = catalogs.value[catalogId]?.courses || []
  return KitchenOrderFormatter.format(
    kitchenOrders.value[previewOrderId.value],
    sortedCourses
  )
})

watch(previewOrder, async () => {
  if (previewOrder.value) {
    let canvas = await TicketGenerator.kitchenOrder(
      previewOrder.value,
      previewPrinter.value
    )
    canvasImage.value = canvas.toDataURL('image/png')
  }
})

function resendOrder(orderId: string) {
  TicketPrinter.printKitchenOrder(getKitchenOrder(orderId))
  previewOrderId.value = null
}

function resendFailed() {
  orders.value
    .filter(order => !order.printed && order.printable)
    .forEach(order =>
      TicketPrinter.printKitchenOrder(getKitchenOrder(order.id))
    )
  emit('close')
}
</script>
