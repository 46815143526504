<template>
  <OnClickOutside @trigger="focused = false" @click="focused = true">
    <div class="flex flex-col">
      <calculator-display
        class="mb-4"
        :mode="displayMode"
        :pending="pendingValue"
        :change="changeValue"
        :to-pay="toPayValue"
        :tip-mode="tipMode"
        :label="label"
        @set-to-pay="() => changeToPay(pending)"
      />
      <calculator-keypad
        :mode="keypadMode"
        :focused="focused"
        @action="handleAction"
        @number="handleNumber"
        @division="lastDivisionValue = $event"
      />
    </div>
  </OnClickOutside>
</template>

<script setup lang="ts">
import CalculatorKeypad from '@/components/Calculator/CalculatorKeypad.vue'
import {
  CalculatorDisplayMode,
  CalculatorDisplayModeType,
  CalculatorKeypadActions,
  CalculatorKeypadActionsType,
  CalculatorKeypadMode,
  CalculatorKeypadModeType,
  CalculatorPayload
} from '@/components/Calculator/CalculatorUtils'
import CalculatorDisplay from '@/components/Calculator/CalculatorDisplay.vue'
import { computed, reactive, ref, watch } from 'vue'
import { useMoney } from '@/composables/useMoney'
import { OnClickOutside } from '@vueuse/components'

type Props = {
  keypadMode?: CalculatorKeypadModeType
  displayMode?: CalculatorDisplayModeType
  pending?: number
  tipMode?: boolean
  initialToPay?: number
  startFocused?: boolean
  label?: string
  keepDivisions?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  keypadMode: CalculatorKeypadMode.SIMPLE,
  displayMode: CalculatorDisplayMode.PENDING,
  initialToPay: 0,
  pending: 0,
  tipMode: false,
  startFocused: true,
  label: '',
  keepDivisions: true
})

const { currency, formatNumber } = useMoney()

const emit = defineEmits<{
  (e: 'change', value: CalculatorPayload): void
  (e: 'division', value: number): void
}>()

const toPay = ref<number>(0)
const operation = reactive<{ symbol: string; value?: number }>({
  symbol: ''
})

const focused = ref<boolean>(props.startFocused)

watch(
  () => props.pending,
  () => changeToPay(0, props.keepDivisions)
)

const isDividing = ref<boolean>(false)
const lastDivisionValue = ref<number>(0)

const maxValue = 9999999
function handleAction(action: CalculatorKeypadActionsType): void {
  if (toPay.value === 0) return
  if (toPay.value > maxValue) {
    toPay.value = maxValue
    return
  }
  if (action in actionMapper) {
    actionMapper[action]()
  }
  emit('division', isDividing.value ? toPay.value : 0)
}

function handleNumber(num: number): void {
  if (operation.symbol) {
    const newValue = +`${operation.value || ''}${num}`
    if (newValue > maxValue) {
      operation.value = maxValue
      return
    }
    operation.value = newValue
  } else {
    changeToPay(+`${toPay.value}${num}`)
  }
}

function recalculateModel(): void {
  emit('change', {
    pending: currentPending.value,
    change: !props.tipMode ? currentChange.value : 0,
    toPay: toPay.value,
    tip: props.tipMode ? currentChange.value : 0
  })
}

const pendingValue = computed(() => {
  return currency(currentPending.value)
})

const changeValue = computed(() => {
  return currency(currentChange.value)
})

const toPayValue = computed(() => {
  if (operation.symbol) {
    let value = operation.value
    if (value && ['+', '-'].includes(operation.symbol)) {
      return `${formatNumber(toPay.value)} ${operation.symbol} ${
        formatNumber(value) || ''
      }`
    }
    return `${formatNumber(toPay.value)} ${operation.symbol} ${value || ''}`
  }
  if (props.displayMode === CalculatorDisplayMode.QUANTITY) {
    return `${toPay.value}`
  }

  return currency(toPay.value)
})

const currentPending = computed(() => {
  let newPending = props.pending - toPay.value
  if (newPending < 0) {
    newPending = 0
  }
  return newPending
})

const currentChange = computed(() => {
  if (props.pending - toPay.value < 0) {
    return (props.pending - toPay.value) * -1
  }

  return 0
})

function resetOperation(): void {
  operation.symbol = ''
  operation.value = undefined
}

function changeToPay(newValue: number, fromPendingChange = false): void {
  if (newValue >= maxValue) {
    newValue = maxValue
  }
  if (!fromPendingChange || lastDivisionValue.value === 0) {
    toPay.value = newValue
  }
  resetOperation()
  recalculateModel()
}

const actionMapper = {
  [CalculatorKeypadActions.CLEAR]: () => {
    isDividing.value = false
    changeToPay(0)
  },
  [CalculatorKeypadActions.BACKSPACE]: () => {
    if (operation.symbol !== '/') isDividing.value = false
    if (operation.symbol) {
      if (operation.value) {
        operation.value = +`${operation.value}`.slice(0, -1) || undefined
      } else {
        operation.symbol = ''
      }
    } else {
      changeToPay(+`${toPay.value}`.slice(0, -1) || 0)
    }
  },
  [CalculatorKeypadActions.DOUBLE_ZERO]: () => {
    if (operation.symbol) {
      operation.value = +`${operation.value}00`
    } else {
      changeToPay(+`${toPay.value}00`)
    }
  },
  [CalculatorKeypadActions.DIVIDE2]: () => {
    if (operation.symbol) return
    isDividing.value = true
    changeToPay(Math.ceil(+`${toPay.value}` / 2))
  },
  [CalculatorKeypadActions.DIVIDE3]: () => {
    if (operation.symbol) return
    isDividing.value = true
    changeToPay(Math.ceil(+`${toPay.value}` / 3))
  },
  [CalculatorKeypadActions.DIVIDE]: () => {
    isDividing.value = true
    operation.symbol = '/'
  },
  [CalculatorKeypadActions.MULTIPLY]: () => {
    operation.symbol = '*'
  },
  [CalculatorKeypadActions.ADD]: () => {
    operation.symbol = '+'
  },
  [CalculatorKeypadActions.SUBTRACT]: () => {
    operation.symbol = '-'
  },
  [CalculatorKeypadActions.EQUALS]: () => {
    if (operation.symbol && operation.value) {
      isDividing.value = operation.symbol === '/'
      changeToPay(
        Math.ceil(eval(`${toPay.value} ${operation.symbol} ${operation.value}`))
      )
    }
  }
} as const

watch(
  () => props.initialToPay,
  value => {
    if (value) {
      changeToPay(value)
    }
  },
  { immediate: true }
)
</script>
