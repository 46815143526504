<template>
  <section class="mt-2">
    <div class="flex flex-col">
      <div class="flex">
        <div class="flex-1 text-xs text-gray-400">
          {{ $t('couriers.identifier') }}
        </div>
        <div class="w-1/3 text-xs text-gray-400">
          {{ $t('couriers.address') }}
        </div>
        <div class="flex-1 text-xs text-gray-400">
          {{ $t('couriers.pickup-delivery') }}
        </div>

        <div class="flex-1 text-xs text-gray-400 ml-8">
          {{ $t('couriers.payment-method') }}
        </div>
        <div class="flex-1 text-xs text-right text-gray-400">
          {{ $t('couriers.total') }}
        </div>
      </div>
      <div class="mt-2 mb-4 separator border-b border-gray-200"></div>
      <div v-for="row in report" :key="row.id">
        <div class="flex items-center">
          <div class="flex-1 text-sm text-blue">
            {{ row.code }}
          </div>
          <div class="w-1/3 text-sm text-gray-400">
            {{ row.address }}
          </div>
          <div>
            <div class="flex-1 text-sm text-gray-400">
              {{ $filters.date(row.creationTime) }}
            </div>
            <div class="flex-1 text-sm text-gray-400">
              {{ $filters.date(row.closeTime) }}
            </div>
          </div>
          <div class="flex-1 text-sm text-gray-400 ml-8">
            {{ getPaymentMethodLabel(row.paymentMethod) }}
          </div>
          <div class="flex-1 text-sm text-right text-blue">
            {{ $filters.currency(row.total) }}
          </div>
        </div>
        <div class="mt-4 mb-4 separator-items border-b border-gray-200"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    report: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getPaymentMethodLabel(method) {
      switch (method) {
        case 'cash':
          return this.$t('couriers.cash')
        case 'card':
          return this.$t('couriers.card')
        default:
          return method
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.separator {
  width: calc(100% + 64px);
  margin-left: -32px;
}

.separator-items {
  width: calc(100% + 32px);
  margin-left: -16px;
}
</style>
