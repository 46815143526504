<template>
  <div class="relative">
    <l-button
      ref="statusButton"
      :icon="isFinalStatus ? '' : 'arrow-right'"
      :type="isFinalStatus ? 'secondary' : 'primary'"
      icon-align="right"
      size="small"
      @click="moveToNextStatus"
      @click.right.prevent="() => (dropdownOpen = true)"
    >
      {{ statusNames[tab.deliveryOrder.status] }}
    </l-button>
    <l-dropdown
      v-model:open="dropdownOpen"
      v-model="selectedStatus"
      :options="options"
    />
  </div>
  <teleport to="#appRoot">
    <courier-selector
      v-if="showCourierSelector"
      @close="showCourierSelector = false"
      @assign-courier="setToOnDelivery"
    />
  </teleport>
</template>

<script setup lang="ts">
import { LButton, LDropdown } from '@last/core-ui/paprika'
import { toRef, defineProps, computed, ref } from 'vue'
import CourierSelector from '@/components/tabs/CourierSelector.vue'
import { useTabs } from '@/composables/useTabs'
import { useI18n } from 'vue-i18n'
import { onLongPress } from '@vueuse/core'
import type { Courier } from '@/types'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'

const statusButton = ref<HTMLElement>()

const { t } = useI18n()

interface Props {
  tabId: string
}

const props = defineProps<Props>()
const configStore = useConfigStore()
const { isShipmentProvidersEnabled } = storeToRefs(configStore)

const tabId = toRef(props, 'tabId')
const showCourierSelector = ref(false)
const { tab, updateDeliveryOrderStatus } = useTabs(tabId)

const statusOrder = computed(() => {
  let statuses = [
    'CREATED',
    'KITCHEN',
    'READY_TO_PICKUP',
    'ON_DELIVERY',
    'DELIVERED'
  ]
  if (tab.value.pickupType !== 'ownDelivery') {
    statuses = statuses.filter(status => status !== 'ON_DELIVERY')
  }
  return statuses
})

const statusNames = {
  CREATED: t('tabs.created'),
  KITCHEN: t('tabs.kitchen'),
  READY_TO_PICKUP: t('tabs.pickup'),
  ON_DELIVERY: t('tabs.on-delivery'),
  DELIVERED: t('tabs.delivered'),
  CANCELLED: t('tabs.cancelled'),
  CLOSED: t('tabs.closed')
}

const isFinalStatus = computed(() => {
  return ['DELIVERED', 'CANCELLED'].includes(tab.value.deliveryOrder.status)
})

function moveToNextStatus() {
  if (dropdownOpen.value) return
  const currentIndex = statusOrder.value.indexOf(tab.value.deliveryOrder.status)
  const nextStatus = statusOrder.value[currentIndex + 1]
  if (nextStatus) {
    moveToStatus(nextStatus)
  }
}

function moveToStatus(newStatus: string) {
  if (newStatus === 'ON_DELIVERY' && !isShipmentProvidersEnabled.value) {
    showCourierSelector.value = true

    return
  } else {
    updateDeliveryOrderStatus({
      newStatus
    })
  }
  dropdownOpen.value = false
}

const selectedStatus = computed({
  get: () => [tab.value.deliveryOrder.status],
  set: (selected: string[]) => {
    moveToStatus(selected[0])
  }
})

function setToOnDelivery(courier: Courier) {
  updateDeliveryOrderStatus({
    newStatus: 'ON_DELIVERY',
    courier: courier
  })
  showCourierSelector.value = false
}

const dropdownOpen = ref(false)

function openDropdown() {
  dropdownOpen.value = true
}

onLongPress(statusButton, openDropdown, {
  modifiers: {
    prevent: true
  }
})

const options = Object.keys(statusNames).map(status => {
  return {
    label: statusNames[status],
    value: status
  }
})
</script>
