<template>
  <div>
    <div class="flex flex-row items-center">
      <l-search-bar
        v-model="query"
        class="flex-1"
        size="medium"
        :debounce-time="500"
        @update:model-value="updateCompanies()"
      />
      <l-button icon="plus" class="ml-4" @click="createCompany()" />
    </div>
    <transition-group name="companies-list" mode="out-in">
      <div
        v-for="company in companies"
        :key="company.id"
        class="flex flex-row justify-between bg-n-700 rounded-[10px] py-6 px-4 my-4 cursor-pointer"
        @click="selectCompany(company)"
      >
        <div>
          <div class="font-heading text-base">{{ company.name }}</div>
          <div class="font-body text-sm font-normal text-n-200">
            {{ `NIF: ${company.taxId}` }}
          </div>
        </div>
        <div class="text-action pl-6" @click.stop="editCompany(company)">
          <icon name="pen" />
        </div>
      </div>
    </transition-group>
    <div v-if="companies.length === 0" class="mt-8">
      <empty-case type="empty" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineEmits } from 'vue'
import api from '@/api.js'
import { notification } from '@last/core-ui/v3/plugins/notification'
import { useI18n } from 'vue-i18n'
import { Icon, LSearchBar, LButton, EmptyCase } from '@last/core-ui/paprika'
import { type Company } from './types'

const { t } = useI18n()

const emit = defineEmits(['modify', 'select'])

const companies = ref<Company[]>([])

const query = ref('')

onMounted(async () => {
  await updateCompanies()
})

async function updateCompanies() {
  try {
    let response = await api.get('/companies', {
      query: query.value
    })
    companies.value = response.data
  } catch (e) {
    notification.create({
      type: 'error',
      title: t('companies-list.error-fetching-companies'),
      icon: 'error'
    })
  }
}

function createCompany() {
  emit('modify', null)
}

function editCompany(company: Company) {
  emit('modify', company)
}

function selectCompany(company: Company) {
  emit('select', company)
}
</script>

<style scoped>
.companies-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.companies-list-enter-active {
  transition: all 0.3s ease;
}
.companies-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
.companies-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}
.companies-list-move {
  transition: all 0.2s ease;
}
</style>
