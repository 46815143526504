<template>
  <on-click-outside @trigger="focused = false" @click="focused = true">
    <l-field :label="$t('new-tab.seats-selector-default')">
      <l-input
        v-model="model"
        type="number"
        :placeholder="$t('new-tab.seats-selector-default')"
      />
    </l-field>
    <calculator-keypad
      mode="normal"
      :focused="focused"
      @action="handleAction"
      @number="handleNumber"
    />
  </on-click-outside>
</template>

<script setup lang="ts">
import { defineModel, ref } from 'vue'
import CalculatorKeypad from '../Calculator/CalculatorKeypad.vue'
import {
  CalculatorKeypadActions,
  CalculatorKeypadActionsType
} from '../Calculator/CalculatorUtils'
import { LInput, LField } from '@last/core-ui/paprika'
import { OnClickOutside } from '@vueuse/components'

const model = defineModel<number | null>({
  default: null
})

const focused = ref<boolean>(true)
function handleAction(action: CalculatorKeypadActionsType): void {
  switch (action) {
    case CalculatorKeypadActions.CLEAR:
      model.value = null
      break
    case CalculatorKeypadActions.BACKSPACE:
      model.value = +`${model.value}`.slice(0, -1) || null
      break
  }
}

function handleNumber(num: number): void {
  let newValue = +`${model.value || ''}${num}`
  newValue = Math.min(newValue, 100)
  if (newValue < 1) return
  model.value = newValue
}
</script>
