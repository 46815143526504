<template>
  <div v-if="savedCatalogCombo" class="h-full flex flex-col bg-n-800">
    <div
      class="pt-10 pb-4 px-4 flex items-center gap-3 text-n-0 uppercase bg-n-900"
    >
      <div>
        <icon name="arrow-left" size="medium" @click="emit('close')" />
      </div>
      <div class="font-heading font-bold text-xl">
        {{ savedCatalogCombo.name }}
      </div>
    </div>

    <div class="overflow-y-scroll scrolling-touch px-4 min-h-0 flex-1">
      <div
        v-for="(combo, comboIndex) in savedCombos"
        :key="comboIndex"
        class=""
      >
        <div
          class="flex justify-between px-4 py-3 border-b border-n-700 pb-4 mt-6"
        >
          <div class="font-body text-n-200">
            {{ 'COMBO ' + (comboIndex + 1) }}
          </div>
          <icon
            v-if="showRemoveButton(comboIndex)"
            class="text-n-0"
            name="trash"
            @click="emit('onRemove', comboIndex)"
          />
        </div>
        <div
          v-for="(category, index) in savedCatalogCombo.categories"
          :key="category.id"
        >
          <template
            v-if="mapCategorySelectedProducts[comboIndex][index].length > 0"
          >
            <div class="font-body text-n-200 px-4 py-2">
              {{ category.name }}
            </div>
            <combo-product
              v-for="product in mapCategorySelectedProducts[comboIndex][index]"
              :key="product.id"
              :product="product"
              :quantity-is-maxed="mapCategoryMaxed[comboIndex][index]"
              @delete="emit('deleteProduct', comboIndex, product.id)"
              @update-quantity="
                newQuantity =>
                  emit(
                    'updateProductQuantity',
                    product.id,
                    newQuantity,
                    comboIndex,
                    index
                  )
              "
              @selected="emit('editProduct', comboIndex, product)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@last/core-ui/paprika'
import ComboProduct from '@/components/ordering/combos/ComboProduct.vue'

const { mapCategorySelectedProducts, savedCombos } = defineProps({
  mapCategorySelectedProducts: {
    type: Array,
    default: () => []
  },
  mapCategoryMaxed: {
    type: Array,
    default: () => []
  },
  savedCatalogCombo: {
    type: Object,
    default: () => {}
  },
  savedCombos: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits([
  'updateProductQuantity',
  'editProduct',
  'deleteProduct',
  'onRemove',
  'close'
])

function showRemoveButton(comboIndex: number) {
  return (
    !mapCategorySelectedProducts[comboIndex] ||
    Object.keys(mapCategorySelectedProducts[comboIndex]).length == 0
  )
}
</script>
