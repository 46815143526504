<template>
  <div :class="{ 'bg-n-700': sectionSelected }">
    <div
      class="flex items-center justify-between p-4 text-sm text-n-200 font-body border-b border-n-600"
      @click="$emit('selectedSection')"
    >
      <div class="flex items-center">
        <l-radio
          :model-value="sectionSelected"
          class="mr-4 hidden sm:flex"
          @update:model-value="$emit('selectedSection')"
        />
        {{ sectionName }}
      </div>
      <icon
        v-if="showRemove"
        name="trash"
        class="justify-end text-n-0 cursor-pointer"
        @click.stop="$emit('onRemove')"
      />
    </div>
    <VueDraggable
      :model-value="elements"
      :empty-insert-threshold="40"
      direction="vertical"
      group="products"
      :force-fallback="true"
      :delay="250"
      :delay-on-touch-only="false"
      :touch-start-threshold="5"
      :animation="200"
      :component-data="{ name: 'list' }"
      @start="$emit('draggableStart')"
      @end="$emit('draggableEnd')"
      @update="event => $emit('onUpdate', event)"
      @add="event => $emit('onAdd', event)"
      @choose="sendVibration"
    >
      <div v-for="element in elements" :id="element.id" :key="element.id">
        <slot v-bind="{ id: element.id, item: element }"></slot>
      </div>
    </VueDraggable>
    <div ref="endSection"></div>
  </div>
</template>

<script setup lang="ts">
import { VueDraggable } from 'vue-draggable-plus'
import { Icon, LRadio } from '@last/core-ui/paprika'
import { Haptics } from '@capacitor/haptics'
import { watch } from 'vue'
import { computed } from 'vue'
import { ref } from 'vue'

const props = defineProps({
  showRemove: {
    type: Boolean,
    default: false
  },
  showSelectRadio: {
    type: Boolean,
    default: true
  },
  sectionName: {
    type: String,
    required: true
  },
  sectionSelected: {
    type: Boolean,
    default: false
  },
  elements: {
    type: Array,
    default: () => []
  },
  hideSectionName: {
    type: Boolean,
    default: false
  }
})

defineEmits([
  'selectedSection',
  'onRemove',
  'onUpdate',
  'onAdd',
  'draggableStart',
  'draggableEnd'
])

const endSection = ref<HTMLDivElement>()

const elementsLength = computed(() => {
  return props.elements.length
})

watch(elementsLength, () => {
  let scrollTarget = endSection.value
  if (scrollTarget) {
    scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
})

function sendVibration() {
  try {
    Haptics.vibrate()
  } catch {
    //no vibration
  }
}
</script>
