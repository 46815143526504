import sync from '@/sync/service.js'
import api from '@/api'
import { EventBus } from '@/eventBus.js'
import { v4 as uuid } from 'uuid'
import PromotionApplier from '@last/core/src/PromotionApplier'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import i18n from '@/i18n'
import { defineStore } from 'pinia'
import { useCatalogStore } from './catalog'
import { useConfigStore } from './config'
import { useTabsStore } from './tabs'
import { useTabs } from '@/composables/useTabs'
import { useNotifications } from '@/composables/useNotifications'

EventBus.$on('tabProductsUpdated', tabId => {
  let promotions = usePromotionsStore()
  promotions.recalculatePromotionsInProducts(tabId)
})

export const usePromotionsStore = defineStore('promotions', {
  persist: true,
  state: () => ({
    promotions: [],
    tabPromotions: {},
    customerPromotions: {}
  }),
  getters: {
    getTabGlobalPromotion: state => tabId => {
      return (
        state.tabPromotions[tabId]?.find(promotion => promotion.global) ||
        undefined
      )
    },
    getPromotions: state => {
      let config = useConfigStore()
      if (!config.config.organizationConfig.promotions) return []
      return state.promotions.filter(
        promotion =>
          promotion.enabled &&
          (!promotion.maxRedemptions || promotion.remainingRedemptions > 0)
      )
    },
    getCustomerPromotions: state => customerId => {
      return (state.customerPromotions[customerId] || []).filter(
        promotion =>
          promotion.enabled &&
          (!promotion.maxRedemptions || promotion.remainingRedemptions > 0)
      )
    },
    getPromotionsWithPoints() {
      return this.getPromotions.filter(promotion => promotion.pointsExpense)
    },
    getPromotionsWithoutPoints() {
      return this.getPromotions.filter(promotion => !promotion.pointsExpense)
    },
    usedPointsInTab: state => tabId => {
      const { getAllProducts, getBills } = useTabs(tabId)
      const products = getAllProducts.value
      const bills = getBills.value
      const tabPromotions = state.tabPromotions[tabId]
      const productPoints = products.reduce(
        (sum, product) => sum + product.pointsExpense * product.quantity,
        0
      )
      const billPoints = bills.reduce(
        (sum, bill) =>
          sum + ((bill.discount && bill.discount.pointsExpense) || 0),
        0
      )
      const promotionPoints = (tabPromotions || []).reduce(
        (sum, promotion) => sum + (promotion.pointsExpense || 0),
        0
      )

      return productPoints + billPoints + promotionPoints
    }
  },
  actions: {
    async refreshPromotions(promotions) {
      if (!promotions) {
        promotions = (await api.get('/promotions')).data
      }
      this.promotions = promotions
    },
    async refreshTabPromotions(tabPromotions) {
      this.tabPromotions = tabPromotions
    },
    async updateCustomerPromotions({ customerId, customerPromotions }) {
      this.customerPromotions[customerId] = customerPromotions
    },
    async deleteTabPromotion({ tabPromotion, tabId }) {
      sync.record('promotionDeletedFromTab', {
        tabPromotionId: tabPromotion.id,
        tabId
      })
      this.recalculatePromotionsInProducts(tabId)
    },
    async updateTabPromotion({ promotion, tabId }) {
      const isDiscountManager = await this.$confirm('DISCOUNT_MANAGER')
      if (!isDiscountManager) {
        const { notifyError } = useNotifications()
        notifyError({
          title: i18n.global.t('employees.not-allowed')
        })
        return Promise.reject()
      }
      const tabPromotion = (this.tabPromotions[tabId] || []).find(
        tabPromo => tabPromo.promotionId === promotion.id
      )
      if (tabPromotion) {
        sync.record('promotionDeletedFromTab', {
          tabPromotionId: tabPromotion.id,
          tabId
        })
      } else {
        const newTabPromotion = {
          id: uuid(),
          tabId,
          promotionId: promotion.id,
          name: promotion.name,
          description: promotion.description,
          pointsExpense: promotion.pointsExpense,
          discountType: promotion.discountType,
          discountAmount: promotion.discountAmount,
          allowRepeat: promotion.allowRepeat,
          global: promotion.global,
          freeDelivery: promotion.freeDelivery
        }
        sync.record('promotionAddedToTab', { tabPromotion: newTabPromotion })
      }
      this.recalculatePromotionsInProducts(tabId)
    },
    recalculatePromotionsInProducts(tabId) {
      const { getCustomerId, getAllProducts } = useTabs(tabId)
      const customerId = getCustomerId.value
      const promotions = [
        ...this.promotions,
        ...(this.customerPromotions[customerId] || [])
      ].reduce((acc, promotion) => {
        acc[promotion.id] = promotion
        return acc
      }, {})

      const availablePromotions = this.tabPromotions[tabId]
        ? this.tabPromotions[tabId].map(tabPromotion => ({
            ...tabPromotion,
            products: promotions[tabPromotion.promotionId]
              ? promotions[tabPromotion.promotionId].products
              : [],
            categories: promotions[tabPromotion.promotionId]
              ? promotions[tabPromotion.promotionId].categories
              : []
          }))
        : []
      let catalog = useCatalogStore()
      const productsToBeUpdated = new PromotionApplier(
        availablePromotions,
        catalog.products
      ).updateDiscountInProducts(
        getAllProducts.value.filter(product => product.notBilledQuantity > 0)
      )

      productsToBeUpdated.forEach(product => {
        let productPromotion = this.promotions.find(
          promotion => promotion.id === product.promotionId
        )
        let productPromotionName = productPromotion
          ? productPromotion.name
          : null
        useTabsStore().updateProductDiscount({
          productId: product.id,
          discount: {
            discountType: product.discountType,
            discountAmount: product.discountAmount,
            discountConcept: product.discountConcept || productPromotionName,
            promotionId: product.promotionId
          },
          productPricing:
            ProductPriceCalculator.calculateProductPricing(product)
        })
      })
    }
  }
})
