<template>
  <div>
    <top-bar :show-back="true" @back="goBack">
      <template #center>
        <div
          v-if="tab && tab.tableName"
          class="flex font-heading font-bold text-xl"
        >
          {{ tab.tableName }}
        </div>
      </template>
    </top-bar>
    <div class="flex justify-center">
      <div class="px-6 md:px0 md:w-2/3 w-full md:max-w-[33.5rem] mt-8">
        <div class="text-xl text-n-10 flex flex-row mb-8">
          <div class="flex-1 font-bold">{{ $t('tabs.total') }}</div>
          <div class="flex-none">{{ currency(currentBillPending) }}</div>
        </div>
        <charge-calculator
          class="mb-4"
          :payment-methods="paymentMethods"
          :initial-pending="currentBillPending"
          keypad-mode="normal"
          @change-payment-method="selectPaymentMethod"
          @calculator-change="calculatorChange"
        />
        <div>
          <l-button
            class="w-full"
            :disabled="isCharging || !canCharge"
            @click="charge"
          >
            {{ $t('checkout.charge') }} {{ currency(calculatorValues.toPay) }}
          </l-button>
          <l-button class="w-full" type="text" @click="goToCheckout">
            {{ $t('checkout.advanced-charge') }}
          </l-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { useTabs } from '@/composables/useTabs'
import { useMoney } from '@/composables/useMoney'
import { useCheckout } from '@/composables/useCheckout'
import { LButton } from '@last/core-ui/paprika'
import type { CalculatorPayload } from '@/components/Calculator/CalculatorUtils'
import TopBar from '@/components/core/TopBar.vue'
import ChargeCalculator from '@/components/ChargeCalculator/ChargeCalculator.vue'

const route = useRoute()
const router = useRouter()

const tabId = ref<string>(
  Array.isArray(route.params?.tabId)
    ? route.params.tabId[0]
    : route.params.tabId
)

const { tab } = useTabs(tabId)
const { currency } = useMoney()

const calculatorValues = ref<CalculatorPayload>({
  toPay: 0,
  pending: 0,
  change: 0,
  tip: 0
})

const {
  currentBill,
  paymentMethods,
  isCharging,
  canCharge,
  charge,
  selectPaymentMethod
} = useCheckout(tabId.value, 'fastCheckout', calculatorValues)

const currentBillPending = computed<number>(
  () => currentBill.value?.pending || 0
)

function calculatorChange(value: CalculatorPayload) {
  calculatorValues.value = value
}

function goToCheckout(): void {
  router.push({
    name: 'checkout',
    params: { tabId: tabId.value },
    query: { referrer: route.name as string }
  })
}

function goBack() {
  router.go(-1)
}
</script>
