import { useConfigStore } from '@/store/config.js'
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useTablesStore } from '@/store/tables'
import { useTillStore } from '@/store/till'
import { useRoute, useRouter } from 'vue-router'

export const useHome = () => {
  const configStore = useConfigStore()
  const tablesStore = useTablesStore()
  const tillStore = useTillStore()

  const { config } = storeToRefs(configStore)
  const { floorplanIds } = storeToRefs(tablesStore)
  const { shiftsEnabled, selectedCashTillIsStarted } = storeToRefs(tillStore)
  const router = useRouter()
  const route = useRoute()

  const selectedTab = ref<string | null>(null)
  const showFloorplanButton = ref<boolean>(true)
  const tabBrowserSelectedTabId = ref<string>()

  if (
    shiftsEnabled &&
    !selectedCashTillIsStarted &&
    router.currentRoute.value.name !== 'employees'
  ) {
    router.push({ name: 'employees' })
  }
  if (floorplanIds.value.length > 0) {
    selectedTab.value = 'floorplan'
  } else {
    selectedTab.value = 'delivery'
    showFloorplanButton.value = false
  }

  const showingFloorplan = computed(() => {
    return (
      (config.value.lastProductPosEnabled ||
        config.value.lastProductBookingsEnabled) &&
      !!selectedTab.value &&
      !['all', 'delivery', 'takeAway'].includes(selectedTab.value)
    )
  })

  function updateSelectedTab(tab: string) {
    selectedTab.value = tab
  }

  watch(
    () => route.query,
    query => {
      if (query.preSelectedTabId) {
        const preSel: string = (
          Array.isArray(query.preSelectedTabId)
            ? query.preSelectedTabId[0]
            : query.preSelectedTabId
        ) as string

        tabBrowserSelectedTabId.value = preSel
        selectedTab.value = 'all'
      }
    },
    { immediate: true }
  )

  return {
    showingFloorplan,
    selectedTab,
    showFloorplanButton,
    tabBrowserSelectedTabId,
    updateSelectedTab
  }
}
