<template>
  <div
    class="flex h-16 justify-between cursor-pointer border-r-6 py-2"
    :class="{
      'opacity-30': status === 'closed',
      'bg-n-700 border-v-300 box-border': selected,
      'border-transparent': !selected
    }"
  >
    <div class="flex pr-2" :class="{ 'ml-8': offset }">
      <tab-title :tab-id="tabId" class="px-4" />
    </div>
    <div v-if="isCancelled || hasAlert" class="pr-4">
      <l-status
        v-if="isCancelled"
        status="error"
        size="small"
        class="capitalize"
      >
        {{ $t('tabs.cancelled') }}
      </l-status>
      <l-status
        v-if="hasAlert"
        status="pending"
        size="small"
        class="capitalize"
      >
        {{ $t('tabs.alert') }}
      </l-status>
    </div>
    <div v-else class="pr-4 text-n-0 text-xs">
      <div v-if="!tab.activationTime" class="flex flex-col items-end">
        <div>
          {{ $filters.day(tab.schedulingTime) }}
        </div>
        <div>
          {{ $filters.time(tab.schedulingTime) }}
        </div>
      </div>
      <div v-else>{{ $filters.time(tab.activationTime) }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTabs } from '@/composables/useTabs'
import { LStatus } from '@last/core-ui/paprika'
import TabTitle from './TabTitle.vue'
import { computed } from 'vue'

interface Props {
  tabId: string
  selectedTabId?: string
  offset?: boolean
}

const props = defineProps<Props>()

const tabId = computed(() => props.tabId)
const { tab, isCancelled, hasAlert, getStatus: status } = useTabs(tabId)

const selected = computed(() => props.tabId === props.selectedTabId)
</script>
