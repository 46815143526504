<template>
  <div v-if="!!tab" class="w-screen h-screen overflow-hidden flex flex-col">
    <top-bar show-home :show-change="false">
      <template #center>
        <div
          v-if="tab"
          class="flex items-center gap-2 font-heading font-bold text-xl"
        >
          {{ tab.tableName }}
          <icon
            v-if="tab.open"
            name="arrow-down"
            class="text-v-300"
            @click="openTabOptions = true"
          />
          <l-dropdown
            v-model:open="openTabOptions"
            :mark-selected="false"
            :options="tabOptions"
          />
        </div>
      </template>
    </top-bar>
    <div class="flex w-full h-full overflow-hidden">
      <menu-browser
        class="flex-1"
        :catalog-id="catalogId"
        :course-id="selectedCourse"
        :customer-points="availablePoints"
        :customer-used-promotions="customerUsedPromotions"
        @selected-product="configureProduct"
      />
      <open-tab
        v-model:selected-seat="selectedSeat"
        v-model:selected-course="selectedCourse"
        class="flex"
        :tab-id="tabId"
        :catalog-id="catalogId"
        :selected-product-id="selectedProductId"
        @selected-product="editProduct"
        @selected-combo-product="editComboProduct"
      />
    </div>
    <product-editor
      v-if="editingProduct"
      :product="editingProduct"
      :catalog-id="catalogId"
      :tab-id="tabId"
      :show-course="!selectedCourse"
      show-quantity
      @save-product="saveProduct"
      @close="editingProduct = null"
    />
    <combo-editor
      v-if="editingCombo"
      :editing-combo="editingCombo.edit"
      :catalog-combo="editingCombo.catalog"
      :combo-product="editingCombo.comboProduct"
      :catalog-id="catalogId"
      :tab-id="tabId"
      :seat="selectedSeat"
      @close="editingCombo = null"
    />
    <link-customer-modal-component
      v-if="linkCustomerModal"
      :tab-id="tabId"
      @close="linkCustomerModal = false"
    />
    <assign-table v-model:is-active="showAssignTablePopup" :tab-ids="[tabId]" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import MenuBrowser from './MenuBrowser.vue'
import OpenTab from './OpenTab.vue'
import ProductEditor from './modifiers/ProductEditor.vue'
import ComboEditor from './combos/ComboEditor.vue'
import TopBar from '@/components/core/TopBar.vue'
import { Icon, useDialog, LDropdown } from '@last/core-ui/paprika'
import LinkCustomerModalComponent from './LinkCustomer.vue'
import AssignTable from '../tabs/AssignTable.vue'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import api from '@/api'
import { useTabsStore } from '@/store/tabs'
import { usePromotionsStore } from '@/store/promotions.js'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { useI18n } from 'vue-i18n'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

const router = useRouter()
const route = useRoute()
const dialog = useDialog()
const { t } = useI18n()

const tabId = computed(() => route.params.tabId as string)

const tabsStore = useTabsStore()
const promotionsStore = usePromotionsStore()
const catalogStore = useCatalogStore()
const configStore = useConfigStore()

const { addProduct, updateProductModifiers, updateCourse } = tabsStore
const { isTabOpen } = storeToRefs(tabsStore)
const { updateCustomerPromotions } = promotionsStore
const { usedPointsInTab } = storeToRefs(promotionsStore)
const { config } = storeToRefs(configStore)
const { tab, catalogId, getSeatProducts, getSharedProducts, closeTab } =
  useTabs(tabId.value)
const { sendKitchenOrder, pendingOrders } = useKitchenOrders(tabId.value)

const showAssignTablePopup = ref(false)
const selectedSeat = ref<number | null>(null)
const selectedCourse = ref(null)
const editingProduct = ref(null)
const editingCombo = ref(null)
const editingSeat = ref(null)
const customerPoints = ref(0)
const customerUsedPromotions = ref([])
const linkCustomerModal = ref(false)
const openTabOptions = ref(false)

const tabOptions = computed(() => {
  const option: Option[] = []
  option.push(
    {
      icon: 'user',
      label: t('ordering.link-customer'),
      value: 'link-customer',
      onClick: () => {
        linkCustomerModal.value = true
        openTabOptions.value = false
      }
    },
    {
      icon: 'plus',
      label: t('tabs.assign-to-table'),
      value: 'assign-to-table',
      onClick: () => {
        showAssignTablePopup.value = true
        openTabOptions.value = false
      }
    },
    {
      icon: 'calendar',
      label: t('ordering.link-reservation'),
      value: 'link-reservation',
      onClick: () => {
        router.push({ name: 'reservations', query: { tabId: tabId.value } })
        openTabOptions.value = false
      }
    },
    {
      icon: 'close',
      label: t('close-tab.close-tab'),
      value: 'close',
      onClick: () => {
        closeTab()
        openTabOptions.value = false
      }
    }
  )
  return option
})

const selectedProductId = computed(() =>
  editingProduct.value ? editingProduct.value.id : null
)
const productCount = computed(
  () => getSeatProducts.value.flat().length + getSharedProducts.value.length
)
const tabCustomerId = computed(() => tab.value?.customerId)

const availablePoints = computed(
  () => customerPoints.value - usedPointsInTab.value(tabId.value)
)

watch(productCount, (oldCount, newCount) => {
  if (oldCount != newCount) {
    editingProduct.value = null
  }
})

watch(tabCustomerId, (oldId, newId) => {
  if (oldId != newId) {
    updatePromotionsInfo()
  }
})

onMounted(() => {
  if (!isTabOpen.value(tabId.value)) {
    router.push({ name: 'pos' })
  }
  updatePromotionsInfo()
})

onBeforeRouteLeave((to, from, next) => {
  if (
    pendingOrders.value &&
    tab.value.activationTime &&
    config.value.enableKitchenOrders &&
    tab.value.open &&
    to.name === 'pos'
  ) {
    dialog({
      title: t('unsent-dialog.title'),
      content: t('unsent-dialog.message'),
      secondaryLabel: t('unsent-dialog.cancel'),
      onConfirm: async () => {
        sendKitchenOrder('all')
        next()
      },
      onSecondary: () => {
        next()
      }
    })
  } else {
    next()
  }
})

const updatePromotionsInfo = async () => {
  let points = 0
  if (tab.value?.customerId) {
    const response = await api.get(
      `/customer/${tab.value.customerId}/promotions`
    )
    points = response.data.points
    updateCustomerPromotions({
      customerId: tab.value.customerId,
      customerPromotions: response.data.customerPromotions
    })
    customerUsedPromotions.value = response.data.customerUsedPromotions
  }
  customerPoints.value = points
}

const saveProduct = (product: any) => {
  const productPricing = ProductPriceCalculator.calculateProductPricing(product)
  if (product.id) {
    updateProductModifiers({
      productId: product.id,
      modifiers: product.modifiers,
      comments: product.comments,
      productPricing
    })
    updateCourse({
      tabId: tabId.value,
      productId: product.id,
      course: product.course
    })
  } else {
    addProduct({
      tabId: tabId.value,
      seat: selectedSeat.value,
      product: {
        ...product,
        ...productPricing
      }
    })
  }
  editingProduct.value = null
}

const configureProduct = (product: any, quantity = 1) => {
  const parentProduct = catalogStore.getProductById(product.id)
  if (product.categories) {
    editingCombo.value = {
      catalog: { ...product, ...parentProduct },
      edit: null
    }
  } else {
    const newProduct = {
      ...product,
      ...parentProduct,
      price: product.price,
      course: selectedCourse.value || product.course,
      id: null,
      parentProduct: product.id,
      quantity
    }
    const mandatory =
      newProduct.modifierGroups &&
      newProduct.modifierGroups.some(group => group.min > 0)
    if (mandatory) {
      editProduct(newProduct)
    } else {
      saveProduct({ ...newProduct, modifiers: [] })
    }
  }
}

const editComboProduct = ({
  product,
  comboProduct
}: {
  product: any
  comboProduct: any
}) => {
  const parentProduct = catalogStore.getProductById(product.parentProduct)
  if (parentProduct && parentProduct.categories) {
    editingCombo.value = {
      catalog: parentProduct,
      edit: product,
      comboProduct
    }
  }
}

const editProduct = (product: any) => {
  if (!product) {
    editingProduct.value = null
    return
  }
  editingSeat.value = selectedSeat.value
  const parentProduct = catalogStore.getProductById(product.parentProduct)
  if (parentProduct && parentProduct.categories) {
    editingCombo.value = {
      catalog: parentProduct,
      edit: product
    }
  } else {
    editingProduct.value = {
      ...parentProduct,
      quantity: product.quantity,
      modifiers: product.modifiers,
      comments: product.comments,
      id: product.id,
      parentProduct: (parentProduct && parentProduct.id) || null,
      course: product.course,
      price: product.price,
      name: product.name,
      discountType: product.discountType,
      discountAmount: product.discountAmount,
      pointsExpense: product.pointsExpense
    }
  }
}
</script>
