import Home from '@/components/Home.vue'
import OrderManagement from '@/components/ordering/OrderManagement.vue'
import Checkout from '@/components/checkout/Checkout.vue'
import Login from '@/components/Login.vue'
import ExtraDeviceWarning from '@/components/ExtraDeviceWarning.vue'
import SupportLogin from '@/components/SupportLogin.vue'
import EmployeeSelector from '@/components/EmployeeSelector.vue'
import PaymentRequest from '@/components/PaymentRequest.vue'
import StartShift from '@/components/shift/StartShift.vue'
import EndShift from '@/components/shift/EndShift.vue'
import PayInPayOut from '@/components/shift/PayInPayOut.vue'
import LocationSelector from '@/components/LocationSelector.vue'
import RealTimeReport from '@/components/reports/RealTimeReport.vue'
import CouriersReport from '@/components/couriersReport/CouriersReport.vue'
import HardwareSelector from '@/components/HardwareSelector.vue'
import SearchView from '@/components/Search/SearchView.vue'
import Reservations from '@/components/Reservations.vue'
import DeliveryCheckout from '@/components/checkout/DeliveryCheckout.vue'
import MenuAvailability from '@/components/MenuAvailability.vue'
import FreeTrialExpired from '@/components/FreeTrialExpired.vue'
import GracePeriodExpired from '@/components/GracePeriodExpired.vue'
import PlanSelector from '@/components/PlanSelector.vue'
import DemoPos from '@/components/DemoPos.vue'
import OverTheLimit from '@/components/OverTheLimit.vue'
import FastCheckoutView from '@/components/checkout/FastCheckout.vue'
import { RouteRecordRaw } from 'vue-router'
import AboutPopup from '@/components/AboutPopup.vue'
import CloseDelivery from '@/components/delivery/CloseDelivery.vue'
import WrongTime from '@/components/WrongTime.vue'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'employees' }
  },
  {
    name: 'pos',
    path: '/pos',
    component: Home
  },
  {
    name: 'demoPos',
    path: '/demo-pos',
    component: DemoPos
  },
  {
    name: 'orderManagement',
    path: '/pos/order-management/:tabId',
    component: OrderManagement
  },
  {
    name: 'checkout',
    path: '/pos/checkout/:tabId',
    component: Checkout
  },
  {
    name: 'fastCheckout',
    path: '/pos/fast-checkout/:tabId',
    component: FastCheckoutView,
    props: true
  },
  {
    name: 'deliveryCheckout',
    path: '/pos/checkout/delivery/:tabId',
    component: DeliveryCheckout,
    props: true
  },
  {
    name: 'login',
    path: '/pos/login',
    component: Login
  },
  {
    name: 'supportLogin',
    path: '/support-login',
    component: SupportLogin
  },
  {
    name: 'employees',
    path: '/employees',
    component: EmployeeSelector
  },
  {
    name: 'paymentRequest',
    path: '/payment-request',
    component: PaymentRequest
  },
  {
    name: 'startShift',
    path: '/start-shift',
    component: StartShift
  },
  {
    name: 'locations',
    path: '/locations',
    component: LocationSelector
  },
  {
    name: 'realTimeReport',
    path: '/real-time-report',
    component: RealTimeReport
  },
  {
    name: 'couriersReport',
    path: '/couriers-report',
    component: CouriersReport
  },
  {
    name: 'search',
    path: '/search',
    component: SearchView
  },
  {
    name: 'endShift',
    path: '/end-shift',
    component: EndShift
  },
  {
    name: 'payInPayOut',
    path: '/pay-in-pay-out',
    component: PayInPayOut
  },
  {
    name: 'reservations',
    path: '/reservations',
    component: Reservations
  },
  {
    name: 'products',
    path: '/products',
    component: MenuAvailability
  },
  {
    name: 'extraDeviceWarning',
    path: '/extra-device',
    component: ExtraDeviceWarning
  },
  {
    name: 'overTheLimit',
    path: '/over-the-limit',
    component: OverTheLimit
  },
  {
    name: 'closeDelivery',
    path: '/close-delivery',
    component: CloseDelivery
  },
  {
    name: 'planSelector',
    path: '/change-plan',
    component: PlanSelector
  },
  {
    name: 'hardwareSelector',
    path: '/hardware-selector',
    component: HardwareSelector
  },
  {
    name: 'freeTrialExpired',
    path: '/free-trial-expired',
    component: FreeTrialExpired
  },
  {
    name: 'gracePeriodExpired',
    path: '/grace-period-expired',
    component: GracePeriodExpired
  },
  {
    name: 'about',
    path: '/about',
    component: AboutPopup
  },
  {
    name: 'wrongTime',
    path: '/wrong-time',
    component: WrongTime
  }
]
