<template>
  <div class="flex flex-col gap-4">
    <div
      ref="categoriesGrid"
      class="categories grid gap-2 overflow-hidden"
      :class="{ 'search-bar-expanded': searchBarExpanded }"
      :style="{ 'max-height': dynamicMaxHeight }"
    >
      <transition-group name="categories-list">
        <div
          ref="searchContainer"
          key="searchBar"
          :style="searchContainerStyle"
          class="flex gap-2 transition-all duration-300 ease"
        >
          <search-bar
            ref="searchCategory"
            v-model="search"
            :expanded="searchBarExpanded"
            class="flex-1"
            @update:expanded="value => toggleSearchBar(value)"
          ></search-bar>
          <div
            v-if="!searchBarExpanded"
            :class="{
              'bg-v-300': selectedCategoryId === 'promotions',
              'bg-n-700': selectedCategoryId !== 'promotions'
            }"
            class="flex flex-1 items-center justify-center text-n-0 text-center rounded-lg font-body text-sm cursor-pointer h-12 sm:h-14"
            @click="selectCategory('promotions')"
          >
            <icon name="discount"></icon>
          </div>
        </div>
        <template v-if="!searchBarExpanded">
          <category
            v-for="category in categoryList"
            :key="category.id"
            :category="category"
            :selected="selectedCategoryId === category.id"
            class="w-full"
            @selected="selectCategory(category.id)"
          />
        </template>
      </transition-group>
    </div>
    <div
      v-if="showToggle && !searchBarExpanded"
      class="flex items-center h-9 relative"
    >
      <div class="flex-1 border border-n-700 justify-center"></div>
      <div
        class="absolute left-1/2 transform -translate-x-1/2 bg-n-700 p-2 rounded-full cursor-pointer"
        @click="toggleExpand"
      >
        <icon
          name="arrow-down"
          class="w-6 h-6 text-n-0 transition"
          :class="{ 'rotate-180': expanded }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  defineProps,
  defineEmits,
  nextTick
} from 'vue'
import { useRoute } from 'vue-router'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import SearchBar from '@/components/core/SearchBar.vue'
import Category from '@/components/ordering/Category.vue'
import { useCatalogStore } from '@/store/catalog'
import { storeToRefs } from 'pinia'
import { useElementSize } from '@vueuse/core'
import lastUtils from '@last/core/src/lastUtils'
import app from '@/app'

const props = defineProps<{
  catalogId: string | null
}>()

const emit = defineEmits(['categorySelected', 'update:search'])

const categoriesGrid = ref<HTMLElement | null>(null)
const searchCategory = ref<HTMLElement | null>(null)
const searchContainer = ref<HTMLElement | null>(null)
const searchContainerStyle = ref({})

const catalogStore = useCatalogStore()
const { catalogs, categories } = storeToRefs(catalogStore)

const selectedCategoryId = ref<string | null>(null)
const disableCategorySelection = ref<boolean>(false)
let expanded = ref<boolean>(false)
let showToggle = ref<boolean>(false)
let dynamicMaxHeight = ref<string>('7.5rem')

const route = useRoute()

const categoryIds = computed(() => {
  if (!props.catalogId) return
  return catalogs.value[props.catalogId]?.categories || []
})

const categoryList = computed(() => {
  return categoryIds.value.map((id: string) => ({
    id,
    name: ellipsis(getCategories.value[id]?.name || '', 20)
  }))
})

const getCategories = computed(() => {
  const catalog = catalogs.value[props.catalogId]
  if (!catalog) return {}
  return catalog.categories.reduce((res, categoryId) => {
    res[categoryId] = categories.value[categoryId]
    return res
  }, {})
})

const search = ref('')
const searchBarExpanded = ref(false)

watch(
  categoryIds,
  () => {
    preselectCategory()
  },
  { immediate: true }
)

watch(
  categoryList,
  () => {
    checkOverflow()
    calculateMaxHeight()
  },
  { deep: true }
)

watch(search, () => {
  emit('update:search', search.value)
})

onMounted(() => {
  preselectCategory()
  checkOverflow()
  window.addEventListener('resize', checkOverflow)
  calculateMaxHeight()
  window.addEventListener('resize', calculateMaxHeight)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkOverflow)
  window.removeEventListener('resize', calculateMaxHeight)
})

function preselectCategory() {
  if (
    !selectedCategoryId.value ||
    !getCategories.value[selectedCategoryId.value]
  ) {
    const param = route.query.selectedCategoryId as string
    selectedCategoryId.value =
      param && categoryIds.value.includes(param) ? param : categoryIds.value[0]
    emit('categorySelected', selectedCategoryId.value)
  }
}

function selectCategory(id: string): void {
  if (disableCategorySelection.value) return
  selectedCategoryId.value = id
  emit('categorySelected', id)
}

function ellipsis(name: string, maxLength: number): string {
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name
}

function toggleExpand() {
  expanded.value = !expanded.value
  nextTick(() => {
    calculateMaxHeight()
  })
}

function checkOverflow() {
  const grid = categoriesGrid.value
  showToggle.value = grid?.scrollHeight > grid?.clientHeight
}

function calculateMaxHeight() {
  if (!expanded.value) {
    dynamicMaxHeight.value = app.isMobile ? '6.5rem' : '7.5rem'
  } else {
    const grid = categoriesGrid.value
    dynamicMaxHeight.value = `${grid?.scrollHeight}px`
  }
}

async function toggleSearchBar(value: boolean) {
  if (value === searchBarExpanded.value) return
  if (searchBarExpanded.value) {
    searchContainerStyle.value = {}
    searchBarExpanded.value = value
  } else {
    const { width: initialWidth } = useElementSize(searchCategory)
    if (searchCategory.value) {
      searchContainerStyle.value = { width: `${initialWidth.value}px` }
    }
    searchBarExpanded.value = value
    await lastUtils.sleep(250)
    if (searchCategory.value) {
      searchContainerStyle.value = { width: `100%`, 'grid-column': '1 / -1' }
    }
  }
}
</script>

<style scoped>
.categories {
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  transition: all 0.2s;
}

@media screen and (max-width: 640px) {
  .categories {
    grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  }
}

.categories-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.categories-list-enter-active {
  transition: all 0.2s ease;
}

.categories-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.categories-list-leave-active {
  transition: all 0.2s ease;
}

.categories-list-move {
  transition: all 0.2s ease;
}
</style>
