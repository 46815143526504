<template>
  <section class="bg-n-800 w-full h-screen flex flex-col">
    <top-bar :show-back="true" @back="$router.go(-1)">
      <template #center>
        <div id="tab-name" class="flex">
          {{ tab.tableName }}
        </div>
      </template>
    </top-bar>
    <div class="flex p-6 gap-3 w-full h-full overflow-hidden">
      <div class="flex flex-col max-w-xs w-full">
        <tab-info class="h-full flex-1 flex flex-col" :tab="tab" />
      </div>
      <div class="flex-1 flex flex-col min-h-0">
        <div class="bg-n-700 rounded-big flex-1 flex flex-col min-h-0">
          <div class="pt-4 px-4">
            <tab-title :tab-id="tabId" class="mr-3" size="big" />
          </div>
          <div class="p-2 flex-1 flex flex-col min-h-0">
            <div
              class="font-bold font-text text-n-10 p-4 flex justify-between text-2xl"
            >
              {{ $t('tabs.order-details') }}
            </div>
            <div class="h-full overflow-y-scroll">
              <delivery-checkout-products
                :tab-id="tabId"
                @products-modified="productsModified = true"
              />
            </div>
            <balance :tab-id="tabId" size="small" />
          </div>
        </div>
        <div class="flex w-full overflow-hidden mt-4 gap-2">
          <div class="flex-1 flex gap-3">
            <payment-method-selector
              :payment-methods="deliveryPaymentMethods"
              :selected-payment-method="selectedPaymentMethod"
              class="flex-1"
              @change="setPaymentMethod"
            />
            <div class="w-72 grid grid-cols-2 gap-x-2 gap-y-4">
              <bill-discount
                v-model:discount="discount"
                size="small"
                class="mt-2 px-2"
                :disabled="!currentBill"
                :original-total="currentBill && currentBill.originalTotal"
                :tab-id="tabId"
              />
              <l-button size="small" @click="goToCheckout">
                {{ $t('checkout.advanced-charge') }}
              </l-button>
              <l-button
                class="col-span-2"
                :disabled="!isAbleToSendBills"
                @click="onDoneClicked"
              >
                {{ $t('checkout.done') }}
              </l-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { defineProps, ref, computed, onMounted, toRef } from 'vue'
import TicketPrinter from '@/ticketPrinter.js'
import TopBar from '@/components/core/TopBar.vue'
import TabInfo from '@/components/tabs/TabInfo.vue'
import TabTitle from '@/components/tabs/TabTitle.vue'
import Balance from '@/components/core/Balance.vue'
import BillDiscount from './BillDiscount.vue'
import DeliveryCheckoutProducts from '@/components/checkout/DeliveryCheckoutProducts.vue'
import PaymentMethodSelector from '@/components/ChargeCalculator/PaymentMethodSelector.vue'
import { useConfigStore } from '@/store/config'
import { useTabs } from '@/composables/useTabs'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { usePromotionsStore } from '@/store/promotions'
import { useBillingStore } from '@/store/billing'
import { useTabsStore } from '@/store/tabs'
import { storeToRefs } from 'pinia'
import { LButton } from '@last/core-ui/paprika'
import { useRouter } from 'vue-router'
import type { Discount, Payment } from '@/types'

const router = useRouter()

const props = defineProps({
  tabId: {
    type: String,
    required: true
  }
})

const configStore = useConfigStore()
const tabsStore = useTabsStore()
const promotionsStore = usePromotionsStore()
const billingStore = useBillingStore()

const tabId = toRef(props, 'tabId')

const { config } = storeToRefs(configStore)
const { getNegativeTabPayments } = storeToRefs(tabsStore)
const { tab, getBills, getUnsentProducts } = useTabs(tabId)
const { sendKitchenOrder } = useKitchenOrders(props.tabId)
const { getTabGlobalPromotion } = storeToRefs(promotionsStore)
const { getCurrentBill } = storeToRefs(billingStore)

const currentDiscount = ref<Discount>()
const previousDiscount = ref<Discount>()
const selectedPaymentMethod = ref<string>()
const productsModified = ref(false)
const negativePayments = ref<Payment[]>([])

const tabGlobalPromotion = computed(() => {
  return getTabGlobalPromotion.value(props.tabId)
})

const currentBill = computed(() => {
  return getCurrentBill.value({
    tabId: props.tabId,
    currentDiscount: currentDiscount.value,
    includeAlreadyBilled: true
  })
})

const isAbleToSendBills = computed(() => {
  let existingPaymentMethod = deliveryPaymentMethods.value.find(
    method => selectedPaymentMethod.value === method.type
  )
  return (
    tab.value &&
    ((tab.value.pickupType === 'ownDelivery' && existingPaymentMethod) ||
      tab.value.pickupType === 'delivery')
  )
})

const paymentMethods = computed(() => {
  return config.value.paymentMethodsConfig.map(method => ({
    ...method,
    type: method.name.toLowerCase()
  }))
})

const tabHasBills = computed(() => {
  return getBills.value.length > 0
})

const discountIsDifferent = computed(() => {
  return (
    previousDiscount.value?.type !== currentDiscount.value?.type ||
    previousDiscount.value?.amount !== currentDiscount.value?.amount
  )
})

const deliveryPaymentMethods = computed(() => {
  if (!tab.value) {
    return []
  }
  let sourceAsMethod = {
    name: tab.value.source,
    type: tab.value.source?.toLowerCase()
  }
  let methods = paymentMethods.value.filter(
    method => method.availableForDelivery
  )
  if (tab.value.source !== 'OwnDelivery' && tab.value.source !== 'Shop') {
    methods = [sourceAsMethod, ...methods]
  }
  return methods
})

const pendingKitchenOrders = computed(() => {
  return getUnsentProducts.value.length > 0
})

onMounted(() => {
  let method =
    tab.value.deliveryOrder.preferredPaymentMethod ||
    tab.value.source.toLowerCase()
  if (method) {
    setPaymentMethod(method)
  }
  if (tabGlobalPromotion.value) {
    currentDiscount.value = {
      type: tabGlobalPromotion.value.discountType,
      amount: tabGlobalPromotion.value.discountAmount,
      promotionId: tabGlobalPromotion.value.promotionId,
      concept: tabGlobalPromotion.value.concept,
      freeDelivery: tabGlobalPromotion.value.freeDelivery
    }
    previousDiscount.value = currentDiscount.value
  }
  negativePayments.value = getNegativeTabPayments.value(props.tabId)
})

function createTabPromotionForDelivery(promotion) {
  deleteCurrentTabPromotion()
  promotionsStore.updateTabPromotion({
    tabId: props.tabId,
    promotion: {
      discountType: promotion.type,
      discountAmount: promotion.amount,
      id: promotion.promotionId,
      global: promotion.global,
      freeDelivery: promotion.freeDelivery
    }
  })
}

function deleteCurrentTabPromotion() {
  if (tabGlobalPromotion.value) {
    promotionsStore.deleteTabPromotion({
      tabId: props.tabId,
      tabPromotion: tabGlobalPromotion.value
    })
  }
}

function onDoneClicked() {
  if (!isAbleToSendBills.value) return
  generateBillsAndLeave()
  router.push({
    name: 'pos'
  })
}

function generateBillsAndLeave() {
  if (
    (!tabHasBills.value ||
      pendingKitchenOrders.value ||
      discountIsDifferent.value ||
      productsModified.value) &&
    tab.value.activationTime
  ) {
    tabsStore.removeTabBills(props.tabId)
    printBill()
    if (config.value.enableKitchenOrders) {
      printKitchenOrder()
    }
  }
}

function printBill() {
  tabsStore.generatePendingBill({
    tabId: props.tabId,
    bill: currentBill.value,
    payments: negativePayments.value
  })
  let bills = getBills.value
  bills.forEach(bill => TicketPrinter.printBill(bill))
}

function printKitchenOrder() {
  sendKitchenOrder('all')
}

function setPaymentMethod(method: string) {
  selectedPaymentMethod.value = method
  tabsStore.setPreferredPaymentMethod({
    tabId: props.tabId,
    preferredPaymentMethod: method
  })
}

function goToCheckout() {
  generateBillsAndLeave()
  router.push({
    name: 'checkout',
    params: { tabId: props.tabId }
  })
}

const discount = computed({
  get() {
    return currentDiscount.value
  },
  set(discount) {
    if (!discount || !discount.type) {
      deleteCurrentTabPromotion()
      currentDiscount.value = undefined
    } else {
      createTabPromotionForDelivery(discount)
      currentDiscount.value = discount
    }
  }
})
</script>
