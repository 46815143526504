import { createApp, h, reactive, ref } from 'vue'
import i18n from '@/i18n.js'
import moment from 'moment'
import type { App as Application } from 'vue'
import uuid4 from 'uuid/v4'

import LNotificationFixedList from '../components/NotificationFixedList.vue'
import LNotificationList from '../components/NotificationList.vue'

interface Notification {
  id: string
  icon: string
  type: 'success' | 'error' | 'info'
  title: string
  subtitle: string
  subtitleIcon: string
  timeout: string
  important: boolean
  progress: number
  progressCanceled: boolean
  progressCancelTitle: string
  createdAt: Date
  onAction: Function
  onCancel: Function
}

const notifications = ref<Notification[]>([])
const fixedNotifications = ref<Notification[]>([])
const fixedComponentOpen = ref<Boolean>(false)

const initializeComponents = () => {
  mountComponent(LNotificationList, {
    notifications,
    'onUpdate:notifications': (newValue: Notification[]) => {
      notifications.value = newValue
    },
    onCancel: (notification: Notification) => {
      if (notification.onCancel) {
        notification.onCancel()
      }
      fixedNotifications.value = fixedNotifications.value.filter(
        fixedNotification => fixedNotification.id !== notification.id
      )
    }
  })
  mountComponent(LNotificationFixedList, {
    notifications: fixedNotifications,
    open: fixedComponentOpen,
    'onUpdate:notifications': (newValue: Notification[]) => {
      fixedNotifications.value = newValue
    },
    onCancel: (notification: Notification) => {
      if (notification.onCancel) {
        notification.onCancel()
      }
    },
    onClose: () => {
      closeFixedComponent()
    }
  })
}

const addNotification = (newNotification: Notification): Notification => {
  newNotification.createdAt = moment().toDate()
  newNotification.id = uuid4()
  if (fixedComponentOpen.value) {
    fixedNotifications.value.unshift(newNotification)
    return newNotification
  }

  fixedNotifications.value.unshift(newNotification)

  if (!fixedComponentOpen.value) {
    notifications.value.unshift(newNotification)
  }
  return newNotification
}

const openFixedComponent = () => {
  notifications.value = []
  fixedComponentOpen.value = true
}

const closeFixedComponent = () => {
  fixedComponentOpen.value = false
}

const updateProgress = (id: string, progress: number) => {
  const notificationIndex = notifications.value.findIndex(
    notification => notification.id === id
  )
  if (notifications.value[notificationIndex]) {
    notifications.value[notificationIndex].progress = progress
  }
}

export const notification = {
  create: addNotification,
  openFixedComponent,
  updateProgress,
  fixedNotifications: fixedNotifications.value
}

function mountComponent(componentToMount: any, propsData: any, slot = null) {
  const container = document.createElement('div')
  document.body.appendChild(container)
  const props = reactive(propsData)
  const app = createApp({
    name: componentToMount.name,
    setup() {
      return () => h(componentToMount, props, () => slot)
    }
  })
  app.use(i18n)
  const instance = app.mount(container)
  return { component: app, instance }
}

const notificationPlugin = {
  install(app: Application) {
    app.component('LNotificationList', LNotificationList)
    app.component('LNotificationFixedList', LNotificationFixedList)
    initializeComponents()
    app.config.globalProperties.$lnotification = notification
  }
}

export default notificationPlugin
