import { Buffer } from 'buffer'

type Size = { width: number; height?: number }
type Image = {
  imageId: string
  size?: Size
}

const DEFAULT_SIZE: Size = { width: 150, height: 75 }

function getImageUrl(imageId: string, size?: Size, isGrayscale = false) {
  if (size) {
    return `https://res.cloudinary.com/lastpos/image/upload/c_thumb,g_center,w_${size.width}${size.height ? `,h_${size.height}` : ''},f_auto,q_auto${isGrayscale ? ',e_grayscale' : ''}/${imageId}`
  } else {
    return `https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/${imageId}`
  }
}

function getBase64Image(url: string) {
  return fetch(url).then(async response => {
    if (!response.ok) {
      throw new Error(`HTTP error, status = ${response.status}`)
    }
    const buffer = await response.arrayBuffer()
    return 'data:image/png;base64,' + Buffer.from(buffer).toString('base64')
  })
}

function getCacheLogo(brandId: string) {
  const imageKey = localStorage.getItem(`brand-logo-${brandId}`)
  return localStorage.getItem(`logo-image-${imageKey}`)
}

async function cacheLogo(id: string, imageId: string) {
  if (!imageId) return
  const logoKey = `brand-logo-${id}`
  const imageKey = localStorage.getItem(logoKey)
  if (imageKey && imageKey === imageId) return
  return getBase64Image(getImageUrl(imageId, { width: 300 }, true)).then(
    image => {
      const imageKey = `logo-image-${imageId}`
      localStorage.setItem(logoKey, imageId)
      localStorage.setItem(imageKey, image)
    }
  )
}

async function preloadImages(images: Image[]) {
  await Promise.allSettled(
    images.map(image => preloadImage(image.imageId, image.size))
  )
  removeOldCache()
}

async function preloadImage(imageId: string, size: Size = DEFAULT_SIZE) {
  return new Promise((resolve, reject) => {
    const url = getImageUrl(imageId, size)
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = url
  })
}

function removeOldCache() {
  // Deprecated logo
  localStorage.removeItem('ticket-logo')
  // Deprecated image cache
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('image-cache-')) {
      const imageKey = localStorage.getItem(key)
      if (imageKey) {
        localStorage.removeItem(key)
        localStorage.removeItem(imageKey)
      }
    }
  })
}

export default {
  DEFAULT_SIZE,
  cacheLogo,
  getCacheLogo,
  preloadImages,
  getImageUrl
}
