import mqtt from 'mqtt'
import pinia from '@/store'
import sync from '@/sync/service.js'
import { logger } from '@/monitoring'
import printerDiscovery from '@/integrations/printer/printerDiscovery.js'
import { useStatusStore } from '@/store/status'
import { useConfigStore } from '@/store/config'
import { useAuthStore } from '@/store/auth'
import { useCatalogStore } from '@/store/catalog'
import { usePromotionsStore } from '@/store/promotions.js'
import { useCouriersStore } from '@/store/couriers'

var client = mqtt.connect(import.meta.env.VITE_APP_MQTT_URL)

function subscribe(locationId) {
  let topic = import.meta.env.VITE_APP_MQTT_TOPIC + '-' + locationId
  let status = useStatusStore(pinia)
  client.subscribe(topic, () => {
    status.updateMqttStatus(true)
  })
  sync.resync()
}

client.on('connect', function () {
  let config = useConfigStore(pinia)
  let auth = useAuthStore(pinia)
  let locationId = pinia && !config.demoMode && auth.locationId
  if (locationId) {
    subscribe(locationId)
  }
})

client.on('error', function (error) {
  logger.info('Mqtt error', error)
})

client.on('end', function () {
  let status = useStatusStore(pinia)
  status.updateMqttStatus(false)
})

client.on('offline', function () {
  let status = useStatusStore(pinia)
  status.updateMqttStatus(false)
})

client.on('disconnect', function () {
  let status = useStatusStore(pinia)
  status.updateMqttStatus(false)
})

client.on('message', function (topic, data) {
  let message = JSON.parse(data)
  let auth = useAuthStore(pinia)
  let catalog = useCatalogStore(pinia)
  let promotions = usePromotionsStore(pinia)
  let config = useConfigStore(pinia)
  let status = useStatusStore(pinia)
  let couriers = useCouriersStore(pinia)
  switch (message.type) {
    case 'catalogs':
      catalog.refreshCatalogs(message.catalogs)
      break
    case 'normalizedCatalog':
      catalog.refreshCatalog(message.catalog)
      break
    case 'promotions':
      promotions.refreshPromotions(message.promotions)
      break
    case 'couriers':
      couriers.refreshCouriers(message.couriers)
      break
    case 'employees':
      auth.refreshEmployees(message.employees)
      break
    case 'event':
      sync.playRemote(message.event)
      break
    case 'configUpdate':
      config.refreshConfig(message.config)
      break
    case 'errorMessages':
      status.refreshErrorMessages(message.errorMessages)
      break
    case 'resync':
      sync.resync()
      break
    case 'discoverPrinters':
      printerDiscovery.discoverPrinters(message.existingPrinters)
      break
  }
})

export default {
  subscribe: subscribe,
  isConnected: client.connected
}
