<template>
  <div class="flex flex-col h-screen overflow-hidden">
    <top-bar>
      <template #left>
        <tab-selector
          :selected-tab="selectedTab"
          :show-floorplan-button="showFloorplanButton"
          @update:selected-tab="updateSelectedTab"
        />
      </template>
      <template #right>
        <icon name="burger" @click.stop="showMenu = true" />
      </template>
    </top-bar>
    <table-tabs v-if="showingFloorplan" />
    <tab-browser
      v-else
      :tabs-type="selectedTab"
      :pre-selected-tab-id="tabBrowserSelectedTabId"
    />
    <side-menu :open="showMenu" @close="showMenu = false" />
  </div>
</template>

<script setup lang="ts">
import TableTabs from '@/components/tables/TableTabs.vue'
import TabBrowser from '@/components/tabs/TabBrowser.vue'
import TopBar from '@/mobile/components/TopBar.vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import TabSelector from '@/components/TabSelector.vue'
import { ref } from 'vue'
import { useHome } from '@/composables/useHome'
import SideMenu from '@/mobile/components/SideMenu.vue'

const {
  selectedTab,
  showFloorplanButton,
  tabBrowserSelectedTabId,
  showingFloorplan,
  updateSelectedTab
} = useHome()

const showMenu = ref(false)
</script>
