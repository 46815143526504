<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 bg-n-900/80 backdrop-blur-sm overflow-hidden flex justify-center items-center"
    @click="$emit('close')"
  >
    <div class="p-20 max-h-full overflow-y-scroll">
      <div class="text-n-0 uppercase text-2xl text-center pb-6">
        {{ $t('tabs.merge-tab') }}
      </div>
      <div
        class="bg-n-0 rounded-big shadow p-6 relative max-w-[25rem]"
        @click.stop
      >
        <icon
          name="close"
          class="text-gray-400 absolute top-0 right-0 m-4"
          @click="$emit('close')"
        />
        <div class="py-8">
          <option-selector
            v-model:selected="selectedTabId"
            :options="tabOptions"
          />
        </div>
      </div>
      <div class="flex pt-4">
        <div
          class="p-3 px-4 text-n-0 bg-blue border border-blue-700 uppercase rounded-small font-bold flex-1 text-center"
          @click="$emit('close')"
        >
          {{ $t('tabs.cancel') }}
        </div>
        <div
          class="p-3 px-4 text-n-0 bg-red border border-red-b uppercase rounded-small font-bold flex-1 ml-4 text-center"
          @click="merge()"
        >
          {{ $t('tabs.merge') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '@last/core-ui/v3/components/Icon.vue'
import OptionSelector from './OptionSelector.vue'
import { useTabsStore } from '@/store/tabs'
import { Tab } from '@/types'
import { computed, ref } from 'vue'

type Props = {
  tabId?: string
  tabs?: Tab[]
}

const props = withDefaults(defineProps<Props>(), {
  tabId: '',
  tabs: () => []
})

const emit = defineEmits(['close'])

const tabsStore = useTabsStore()

const mergeTabs = tabsStore.mergeTabs
const selectedTabId = ref<string>('')

const tabOptions = computed(() => {
  return props.tabs.map(tab => ({
    value: tab.id,
    label: tab.tableName || tab.code
  }))
})

function merge() {
  mergeTabs({ tabAId: props.tabId, tabBId: selectedTabId.value })
  emit('close')
}
</script>
