<template>
  <div class="flex h-full w-full justify-center bg-n-800">
    <div
      v-if="!groupedTillsReportIsEmpty"
      class="w-1/2 flex-shrink-0 bg-n-700 p-10"
    >
      <tills-view-summary
        :grouped-tills-report="groupedTillsReport"
        @print-preview="$emit('printPreview')"
      />
    </div>
    <div
      class="w-1/2 flex-shrink-0 flex flex-col gap-8 h-full relative bg-n-800 p-10 text-n-0"
    >
      <div v-if="tills.cash && tills.cash.length > 0">
        <div class="font-heading text-xl font-bold mb-6">
          {{ $t('end-shift.tills') }}
        </div>
        <div class="flex flex-col gap-4">
          <div
            v-for="till in tills.cash"
            :key="till.name"
            class="border border-n-600 bg-n-700 rounded-xl flex items-center justify-between h-16 p-4"
            @click="startClosingCashTill(till)"
          >
            <div class="font-heading font-medium">{{ till.name }}</div>
            <icon
              v-if="!startedShifts.includes(till.id)"
              name="done"
              class="text-g-500"
            />
            <icon v-else name="arrow-right" class="text-action" />
          </div>
        </div>
      </div>

      <div v-if="employeesWithTill && employeesWithTill.length > 0">
        <div class="font-heading text-xl font-bold mb-6">
          {{ $t('end-shift.employees') }}
        </div>
        <div class="flex flex-col gap-4">
          <div
            v-for="employee in employeesWithTill"
            :key="employee.name"
            class="border border-n-600 bg-n-700 rounded-xl flex items-center justify-between h-16 p-4"
            @click="startClosingEmployeeTill(employee)"
          >
            <div class="font-heading font-medium">{{ employee.name }}</div>
            <icon
              v-if="!startedShifts.includes(employee.tillId!)"
              name="done"
              class="text-g-500"
            />
            <icon v-else name="arrow-right" class="text-action" />
          </div>
        </div>
      </div>

      <l-button class="w-full mt-auto" :disabled="printDisabled" @click="leave">
        {{ $t('end-shift.close-restaurant-shift') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon, LButton } from '@last/core-ui/paprika'
import TillsViewSummary from './TillsViewSummary.vue'
import sync from '@/sync/service.js'
import api from '@/api'
import { useConfigStore } from '@/store/config'
import { useAuthStore } from '@/store/auth'
import { useTillStore } from '@/store/till'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { Till, Employee, LastShiftGroupedTills } from '@/types'
import { watch } from 'vue'

const emit = defineEmits<{
  (event: 'startClosingTill', till: Till): void
  (event: 'leave'): void
  (event: 'printPreview'): void
}>()

const configStore = useConfigStore()
const authStore = useAuthStore()
const tillStore = useTillStore()

const { config, tills } = storeToRefs(configStore)
const { getEmployees } = storeToRefs(authStore)
const { startedShifts } = storeToRefs(tillStore)

const groupedTillsReport = ref<LastShiftGroupedTills>({})

const groupedTillsReportIsEmpty = computed(() => {
  return Object.keys(groupedTillsReport.value).length === 0
})

const printDisabled = computed(() => {
  return startedShifts.value.length > 0
})

const employeesWithTill = computed(() => {
  return getEmployees.value.filter(
    employee => employee.tillEnabled && employee.tillId
  )
})

watch(startedShifts, (value: any) => {
  if (value.length === 0) {
    sync.observeEnd(async () => {
      await loadShiftGroupedTills()
    })
  }
  tryToAutoSelectTill()
})

onMounted(async () => {
  if (!config.value.blindTill || startedShifts.value.length === 0) {
    await loadShiftGroupedTills()
  }
  tryToAutoSelectTill()
})

function startClosingCashTill(till: Till) {
  if (!startedShifts.value.includes(till.id)) {
    return
  }
  emit('startClosingTill', till)
}

function startClosingEmployeeTill(employee: Employee) {
  let till = { id: employee.tillId, name: employee.name, type: 'cash' }
  if (!startedShifts.value.includes(till.id!)) {
    return
  }
  emit('startClosingTill', till)
}

async function loadShiftGroupedTills() {
  let response = await api.get('last-shift-grouped-tills')
  groupedTillsReport.value = response.data as LastShiftGroupedTills
}

function leave() {
  if (!printDisabled.value) {
    emit('leave')
  }
}

function tryToAutoSelectTill() {
  if (startedShifts.value.length === 1) {
    let startedShiftId = startedShifts.value[0]
    let till = tills.value.cash.find(till => till.id === startedShiftId)
    if (till) startClosingCashTill(till)
  }
}
</script>
