<template>
  <full-screen :title="title" @close="cancel">
    <transition name="forward">
      <div
        v-if="!showEmployeeTill"
        class="w-full h-full flex flex-col items-center py-4 sm:py-8 px-4"
      >
        <div
          class="flex-1 min-h-0 flex flex-col items-center max-w-[33.5rem] w-full"
        >
          <cash-amount
            class="w-full flex-1 min-h-0"
            :done-label="$t('start-shift.start')"
            :show-cancel="true"
            :show-open-till="true"
            :last-shift-end-amount="lastShiftCashTillAmount"
            :use-cash-machine="useCashMachine"
            @done="
              amount => startShiftAndContinue(amount, selectedCashTill!.id)
            "
          />
        </div>
      </div>
      <div
        v-else-if="showEmployeeTill && !employeeShiftStarted"
        class="w-full h-full flex flex-col items-center py-4 sm:py-8 px-4"
      >
        <div
          class="flex-1 min-h-0 flex flex-col items-center max-w-[33.5rem] w-full"
        >
          <cash-amount
            class="w-full flex-1 min-h-0"
            :done-label="$t('start-shift.start')"
            :show-cancel="true"
            :last-shift-end-amount="lastShiftEmployeeTillAmount"
            @done="
              amount => startShiftAndContinue(amount, currentEmployee!.tillId!)
            "
          />
        </div>
      </div>
    </transition>
  </full-screen>
</template>

<script setup lang="ts">
import CashAmount from './CashAmount.vue'
import api from '@/api'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { useTillStore } from '@/store/till'
import { useAuthStore } from '@/store/auth'
import FullScreen from '../core/FullScreen.vue'
import { storeToRefs } from 'pinia'
import { watch, onBeforeMount } from 'vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { LastShift } from '@/types'
import { useI18n } from 'vue-i18n'

const tillStore = useTillStore()
const authStore = useAuthStore()
const router = useRouter()
const { t } = useI18n()

const { startShift } = tillStore
const { selectedCashTill, selectedCashTillIsStarted, tillIsStarted } =
  storeToRefs(tillStore)
const { currentEmployee } = storeToRefs(authStore)

const showEmployeeTill = ref(false)
const lastShiftCashTill = ref<LastShift>()
const lastShiftEmployeeTill = ref<LastShift>()

const useCashMachine = computed(() => {
  const till = selectedCashTill.value
  return till ? CashMachine.methods.includes(till.type) : false
})

const title = computed(() => {
  return !showEmployeeTill.value
    ? t('start-shift.title') + ' ' + selectedCashTill.value!.name + ' (1/2)'
    : t('start-shift.title') + ' ' + currentEmployee.value!.name + ' (2/2)'
})

const employeeShiftStarted = computed(() => {
  return (
    !currentEmployee.value?.tillEnabled ||
    tillIsStarted.value(currentEmployee.value.tillId!)
  )
})

const allShiftsStarted = computed(() => {
  return (
    employeeShiftStarted.value &&
    !!(!selectedCashTill.value || selectedCashTillIsStarted.value)
  )
})

const lastShiftCashTillAmount = computed(() => {
  if (!lastShiftCashTill.value || !lastShiftCashTill.value.amounts) {
    return null
  } else {
    let cashAmount = lastShiftCashTill.value.amounts.find(
      amount => amount.method === 'cash'
    )
    if (cashAmount) {
      return cashAmount.end
    }
  }
  return null
})

const lastShiftEmployeeTillAmount = computed(() => {
  if (!lastShiftEmployeeTill.value || !lastShiftEmployeeTill.value.amounts) {
    return null
  } else {
    let cashAmount = lastShiftEmployeeTill.value.amounts.find(
      amount => amount.method === 'cash'
    )
    if (cashAmount) {
      return cashAmount.end
    }
  }
  return null
})

onMounted(async () => {
  if (allShiftsStarted.value) {
    router.push({ name: 'pos' })
  }
  if (selectedCashTill.value && selectedCashTill.value.id) {
    const response = await api.get('/last-shift', {
      tillId: selectedCashTill.value.id
    })
    lastShiftCashTill.value = response.data as LastShift
  }
  if (currentEmployee.value?.tillEnabled && currentEmployee.value?.tillId) {
    const response = await api.get('/last-shift', {
      tillId: currentEmployee.value.tillId
    })
    lastShiftEmployeeTill.value = response.data as LastShift
  }
})

onBeforeMount(() => {
  if (!selectedCashTill.value || selectedCashTillIsStarted.value) {
    showEmployeeTill.value = true
  }
})

function startShiftAndContinue(amount: number, tillId: string) {
  let method = CashMachine.method || 'cash'
  startShift({ amount, tillId, method })
  showEmployeeTill.value = true
}

function cancel() {
  router.push({ name: 'employees' })
}

watch(allShiftsStarted, started => {
  if (started) {
    router.push({ name: 'pos' })
  }
})
</script>

<style scoped>
.forward-enter-active,
.forward-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.3s ease;
}

.forward-leave-to {
  transform: translateX(-100%);
}

.forward-enter-from {
  transform: translateX(100%);
}
</style>
