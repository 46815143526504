<template>
  <tippy
    ref="tippyRef"
    class="w-full"
    :interactive="true"
    :arrow="false"
    trigger="click"
    placement="auto"
    :duration="100"
    :append-to="() => appendTarget"
  >
    <div class="w-full relative">
      <div
        class="flex rounded-xl justify-between border border-transparent items-center cursor-pointer min-w-[200px]"
        :class="[
          ...sizeClasses,
          ...paddingClasses,
          ...backgroundColorsClasses,
          ...focusClasses,
          ...textColorsClasses
        ]"
        @click="handleOpen"
      >
        <div class="flex-1 whitespace-nowrap">
          <div v-if="modelValue">
            {{
              showTime ? $filters.date(modelValue) : $filters.day(modelValue)
            }}
          </div>
          <div v-else class="ellipsis text-n-200">
            {{ placeholder }}
          </div>
        </div>
        <icon
          name="arrow-down"
          class="transition-all duration-300"
          :class="{
            'rotate-180': showCalendar
          }"
        />
      </div>
    </div>
    <template #content>
      <div
        class="w-72 border border-n-50 dark:border-n-900 rounded-xl mt-2 px-5 py-6 flex flex-col gap-[24px] bg-n-0 dark:bg-n-800 z-10"
      >
        <calendar v-model="day" :only-future="onlyFuture" />
        <time-picker v-if="showTime" v-model="time" />
        <l-button class="w-full" size="small" @click="save">
          {{ $t('ctas.apply') }}</l-button
        >
      </div>
    </template>
  </tippy>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, defineModel } from 'vue'
import moment from 'moment'
import { Calendar, TimePicker, Icon, LButton } from '@last/core-ui/paprika'
import {
  useSizeClasses,
  usePaddingClasses,
  useBackgroundColorsClasses,
  useFocusClasses,
  useTextColorsClasses
} from '../classes'
import type { Size } from '../types'
import { Tippy } from 'vue-tippy'

type Props = {
  showTime?: boolean
  onlyFuture?: boolean
  placeholder: string
  size?: Size
  defaultDate?: string
}

const showCalendar = ref(false)
const appendTarget = ref(document.body)
const tippyRef = ref<typeof Tippy>()

const props = withDefaults(defineProps<Props>(), {
  showTime: true,
  onlyFuture: false,
  size: 'small',
  defaultDate: undefined
})

const model = defineModel<string>()
let localValue = ref(model.value)

onMounted(() => {
  localValue.value = model.value ?? props.defaultDate
})

function save() {
  model.value = moment(localValue.value, 'YYYY-MM-DD HH:mm').toISOString()
  showCalendar.value = false
  tippyRef.value?.hide()
}

const day = computed({
  get: () => {
    if (!localValue.value) return null
    return moment(localValue.value).format('YYYY-MM-DD')
  },
  set: value => {
    localValue.value = `${value} ${time.value ?? '00:00'}`
  }
})

const time = computed({
  get: () => {
    if (!localValue.value) return null
    const hour = moment(localValue.value).hour()
    // Round to the nearest 15 minutes
    const minute =
      (15 * Math.round(moment(localValue.value).minute() / 15)) % 60
    return `${hour}:${minute.toString().padStart(2, '0')}`
  },
  set: value => {
    localValue.value = `${day.value ?? moment().format('YYYY-MM-DD')} ${value}`
  }
})

function handleOpen() {
  showCalendar.value = !showCalendar.value
}

watch(model, () => {
  localValue.value = model.value
})

const sizeClasses = computed(() => useSizeClasses(props.size))
const paddingClasses = computed(() => usePaddingClasses(props.size))
const backgroundColorsClasses = computed(() => useBackgroundColorsClasses())
const focusClasses = computed(() => useFocusClasses())
const textColorsClasses = computed(() => useTextColorsClasses())
</script>
