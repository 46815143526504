import moment from 'moment'
import lastUtils from '@last/core/src/lastUtils'
import { useConfigStore } from '@/store/config'
import { getActivePinia, Pinia } from 'pinia'

export const createFilters = (store: Pinia) => {
  function currency(value: number): string {
    const config = useConfigStore(store)
    const currencyCode = config.config?.currencyCode || 'EUR'
    return lastUtils.currencyFilter(value, currencyCode)
  }

  function date(value: string | number | Date) {
    if (value) {
      return moment(value).format('DD/MM/YYYY HH:mm')
    }
  }

  function elapsedTime(value: string | number | Date): string | undefined {
    if (value) {
      const now = moment()
      const start = moment(value)
      const duration = moment.duration(Math.max(now.diff(start), 0))
      return (
        Math.floor(duration.asHours()) +
        moment.utc(duration.asMilliseconds()).format(':mm')
      )
    }
  }

  function time(value: string | number | Date): string | undefined {
    if (value) {
      return moment(new Date(value).toISOString()).format('HH:mm')
    }
  }

  function day(value: string | number | Date): string | undefined {
    if (value) {
      return moment(value).format('DD/MM/YYYY')
    }
  }

  return {
    currency,
    date,
    elapsedTime,
    time,
    day
  }
}

export const useFilters = () => {
  const pinia = getActivePinia()

  if (!pinia) {
    throw new Error('Pinia instance not found')
  }

  return createFilters(pinia)
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $filters: ReturnType<typeof createFilters>
  }
}
