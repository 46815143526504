<template>
  <div>
    <div
      :id="option.value"
      class="px-4 py-3 rounded-lg text-n-600 dark:text-white text-xs flex hover:bg-n-50 dark:hover:bg-n-700 cursor-pointer"
      @click.stop="selectOption(option)"
    >
      <l-checkbox
        v-if="props.option.checkbox"
        small
        class="mr-2"
        :model-value="model.selected"
        :half="selectedChildren.length > 0 && !allChildSelected"
        :label="label"
        @update:model-value="() => selectOption(props.option)"
      />
      <template v-else-if="isColor">
        <div
          :style="{ background: label }"
          class="color mx-6 my-2 w-full cursor-pointer rounded h-[1.7rem]"
        />
      </template>
      <template v-else>
        <icon v-if="props.option.icon" :name="props.option.icon" class="mr-2" />
        <div
          :class="{
            'text-v-300': markSelected && model.selected,
            'font-bold': option.title
          }"
        >
          {{ label }}
        </div>
      </template>
    </div>
    <div v-if="props.option.children">
      <div
        v-for="child in props.option.children"
        :key="child.value"
        class="pl-4 rounded-lg"
      >
        <dropdown-option
          v-model="childrenByValue![child.value]"
          :option="child"
          @update:model-value="() => selectChild(childrenByValue![child.value])"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel } from 'vue'
import { LCheckbox, Icon } from '@last/core-ui/paprika'
import type { Option, Model } from './types'

type Props = {
  option: Option
  selected?: boolean
  hasCheckbox?: boolean
  multiselect?: boolean
  markSelected?: boolean
}

const model = defineModel<Model>({
  required: true
})

const selectedChildren = computed(() => {
  if (!model.value?.children) return []
  return model.value?.children
    ?.filter(child => child.selected)
    .map(child => child.value)
})

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  half: false,
  selected: false,
  markSelected: true
})

const selectOption = (option: Option) => {
  const newModel = structuredClone(model.value)
  if (option.checkbox) {
    newModel.selected = !newModel?.selected
  } else {
    newModel.selected = true
  }
  if (newModel.children) {
    newModel.children = newModel.children.map(child => ({
      ...child,
      selected: props.multiselect && !allChildSelected.value
    }))
  }
  model.value = newModel
  option.onClick?.()
}

const selectChild = (selectedChild: Model) => {
  const newModel = structuredClone(model.value)
  if (!newModel.children) return
  newModel.children = newModel.children.map(child => {
    if (child.value === selectedChild.value) return selectedChild
    if (!props.multiselect) return { ...child, selected: false }
    return child
  })
  newModel.selected =
    newModel.children.every(child => child.selected) && props.multiselect

  model.value = newModel
}

const childrenByValue = computed(() =>
  model.value.children?.reduce(
    (acc, child) => {
      acc[child.value] = child
      return acc
    },
    {} as Record<string, Model>
  )
)

const allChildSelected = computed(() => {
  return Boolean(
    model.value.children &&
      model.value.children.every(child =>
        selectedChildren.value.includes(child.value)
      )
  )
})

const label = computed(() => {
  return props.option.label
})

const isColor = computed(() => {
  let colorRegex = '#([0-9]|[A-F]){6}'
  return label.value.match(colorRegex)
})
</script>
