<template>
  <info-screen
    :title="$t('grace-period-expired.title')"
    :message="$t('grace-period-expired.message')"
    :image="'dizzy-robot.svg'"
  />
</template>

<script setup lang="ts">
import InfoScreen from '@/components/core/InfoScreen.vue'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { watch } from 'vue'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const { refreshBillingStatus } = authStore
const { gracePeriodExpired } = storeToRefs(authStore)
const router = useRouter()

onMounted(() => {
  refreshBillingStatus()
  if (!gracePeriodExpired) {
    router.push({
      name: 'employees'
    })
  }
})

watch(gracePeriodExpired, () => {
  if (!gracePeriodExpired) {
    router.push({
      name: 'employees'
    })
  }
})
</script>
