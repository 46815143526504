<template>
  <swipe-out ref="swipeOutRef" :is-disabled="disableSwipe" class="w-full">
    <template v-if="allowDiscount" #left>
      <div
        class="w-14 h-full flex items-center justify-center px-3 bg-g-500"
        @click="showDiscount = true"
      >
        <icon name="discount" size="small" class="text-n-0" />
      </div>
    </template>
    <template #default>
      <div
        :class="{
          'opacity-30': disabled,
          'bg-n-600': isSelected
        }"
        class="w-full flex justify-center items-center px-4 min-h-14 py-2 text-n-0 font-body"
      >
        <div class="w-full flex justify-center items-start gap-2">
          <template v-if="showFullQuantity">
            <quantity-selector
              v-if="showQuantitySelector && editingQuantity"
              :quantity="product.quantity"
              @click-outside="editingQuantity = false"
              @update:quantity="updateQuantity"
            />
            <div
              v-else
              class="w-5 h-full text-center font-heading text-sm flex-shrink-0"
              @click="editingQuantity = true"
            >
              {{ product.quantity }}
            </div>
          </template>

          <div
            class="flex justify-between items-start w-full"
            @click="$emit('selected')"
          >
            <div class="flex flex-col font-heading text-sm">
              <div>{{ product.name }}</div>
              <div class="text-n-200">
                {{ description }}
              </div>
              <div v-if="showComments && product.comments" class="text-n-200">
                + {{ product.comments }}
              </div>
              <div v-if="showSentToKitchen && sentTime" class="text-n-200">
                {{ $t('ordering.sent') }} {{ $filters.time(sentTime) }} ({{
                  product.course
                }})
              </div>
              <div
                v-if="product.discountType === '2x1'"
                class="text-g-500 text-sm"
              >
                2x1
                <span v-if="product.quantity > 1">
                  ({{ product.discountAmount }})
                </span>
              </div>
            </div>
            <div class="font-bold font-text text-xs flex items-center gap-3">
              <div v-if="showPrice" class="flex flex-col">
                <div
                  v-if="product.discountType"
                  class="font-body text-xs line-through"
                >
                  <span>
                    {{
                      $filters.currency(
                        product.fullPrice *
                          (showFullQuantity ? product.quantity : 1)
                      )
                    }}
                  </span>
                </div>
                <div v-if="product.discountType === '2x1'">
                  {{
                    $filters.currency(
                      product.fullPrice *
                        (product.quantity - product.discountAmount!)
                    )
                  }}
                </div>
                <div v-else>
                  {{
                    $filters.currency(
                      product.finalPrice *
                        (showFullQuantity ? product.quantity : 1)
                    )
                  }}
                </div>
              </div>
              <icon
                v-if="showBillingStatus && iconColor"
                :name="paymentIcon"
                :class="iconColor"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="allowRemove" #right>
      <div
        class="w-14 h-full flex items-center justify-center px-3 bg-r-500"
        @click="deleteProduct"
      >
        <icon name="trash" size="small" class="text-n-0" />
      </div>
    </template>
  </swipe-out>
  <product-discount v-if="showDiscount" :product="product" @close="closeSwipe">
  </product-discount>
</template>

<script setup lang="ts">
import SwipeOut from '@/components/core/SwipeOut.vue'
import { Icon } from '@last/core-ui/paprika'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import ProductDiscount from '@/components/checkout/ProductDiscount.vue'
import { useTabsStore } from '@/store/tabs'
import { computed, toRef } from 'vue'
import useProducts from '@/composables/useProducts'
import { ref, defineProps } from 'vue'
import type { Product } from '@/types'

type Props = {
  product: Product
  isSelected?: boolean
  allowRemove?: boolean
  allowDiscount?: boolean
  disabled?: boolean
  disableSwipe?: boolean
  showFullQuantity?: boolean
  showQuantitySelector?: boolean
  showSentToKitchen?: boolean
  showBillingStatus?: boolean
  showComments?: boolean
  showPrice?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  allowRemove: false,
  allowDiscount: false,
  disabled: false,
  disableSwipe: false,
  showQuantitySelector: false,
  showFullQuantity: true,
  showSentToKitchen: false,
  showBillingStatus: false,
  showComments: false,
  showPrice: true
})

const emit = defineEmits(['selected', 'delete'])

const swipeOutRef = ref<InstanceType<typeof SwipeOut> | null>(null)
const showDiscount = ref(false)

const product = toRef(props, 'product')

const {
  deleteProductWithConfirmation,
  description,
  productIsPaid,
  productIsBilled
} = useProducts(product)

const tabStore = useTabsStore()

const { updateProductQuantity, getSentToKitchenTime } = tabStore

const editingQuantity = ref(false)

const paymentIcon = computed(() => {
  if (productIsPaid.value) return 'done'
  if (productIsBilled.value) return 'pending-payment'
  return ''
})

const iconColor = computed(() => {
  if (productIsPaid.value) return 'text-g-500'
  if (someProductBilled.value) return 'text-y-500'
  return ''
})

const someProductBilled = computed(() => {
  return product.value.notBilledQuantity != product.value.quantity
})

const sentTime = computed(() => {
  return getSentToKitchenTime(product.value.id)
})

function updateQuantity(quantity: number) {
  updateProductQuantity({
    productId: product.value.id,
    quantity
  })
}

function deleteProduct() {
  const deleted = deleteProductWithConfirmation()
  if (deleted) {
    emit('delete')
  }
  closeSwipe()
}

function closeSwipe() {
  showDiscount.value = false
  swipeOutRef.value?.close()
}
</script>
