<template>
  <div class="w-screen h-screen overflow-hidden flex flex-col">
    <top-bar :show-back="true" @back="exitOrderManagement()">
      <template #center>
        <div
          v-if="tab"
          class="flex items-center justify-center gap-2 font-heading font-bold text-xl"
        >
          {{ tab.tableName }}
          <icon
            v-if="tab.open"
            name="arrow-down"
            class="text-v-300"
            @click="openTabOptions = true"
          />
          <l-dropdown
            v-model:open="openTabOptions"
            :options="tabOptions"
            :mark-selected="false"
          />
        </div>
      </template>
    </top-bar>
    <div
      class="flex w-full h-full overflow-hidden relative"
      :class="{
        'mb-[5.5rem]': !showOpenTab
      }"
    >
      <div class="flex-1 w-0 flex flex-col mt-3">
        <div class="flex justify-between px-3 mb-2 gap-6">
          <ordering-mode-selector :tab-id="tabId" />
          <seat-or-course-selector
            v-model:selected-seat="selectedSeat"
            v-model:selected-course="selectedCourse"
            class="flex-1"
            :tab-id="tabId"
          />
        </div>
        <menu-browser
          :customer-points="availablePoints"
          :customer-used-promotions="customerUsedPromotions"
          @selected-product="configureProduct"
        />
      </div>
      <open-tab
        v-if="showOpenTab"
        v-model:selected-seat="selectedSeat"
        v-model:selected-course="selectedCourse"
        class="absolute t-0 left-0 w-full z-20"
        :tab-id="tabId"
      />
    </div>

    <resend-kitchen-orders-component
      v-if="resendKitchenOrders"
      :tab-id="tabId"
      @close="resendKitchenOrders = false"
    />
    <div
      class="absolute bottom-0 left-0 w-full p-4 pb-6 bg-n-800"
      :class="{ hidden: showOpenTab, absolute: !showOpenTab }"
    >
      <l-button class="w-full" @click="showOpenTab = true"
        >{{ $t('ordering.see-order') }}
        <l-badge :value="productCount" class="ml-2" />
      </l-button>
    </div>
    <link-customer-component
      v-if="linkCustomerModal"
      :tab-id="tabId"
      @close="linkCustomerModal = false"
    ></link-customer-component>
    <div
      v-if="editingProduct || editingCombo"
      class="fixed inset-0 h-full w-full bg-n-0 z-10"
    >
      <product-editor
        v-if="editingProduct"
        show-quantity
        emit-close
        :product="editingProduct"
        :catalog-id="catalogId"
        :tab-id="tabId"
        :selected-seat="selectedSeat"
        @close="editingProduct = null"
      />
      <combo-editor
        v-if="editingCombo"
        :editing-combo="editingCombo.edit"
        :catalog-combo="editingCombo.catalog"
        :combo-product="editingCombo.comboProduct"
        :catalog-id="catalogId"
        :tab-id="tabId"
        :seat="selectedSeat"
        @close="editingCombo = null"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import MenuBrowser from '@/components/ordering/MenuBrowser.vue'
import ComboEditor from '@/mobile/views/ComboEditor.vue'
import ProductEditor from '@/mobile/views/ProductEditor.vue'
import ResendKitchenOrdersComponent from '@/components/ordering/ResendKitchenOrders.vue'
import TopBar from '@/mobile/components/TopBar.vue'
import {
  Icon,
  LButton,
  useDialog,
  LDropdown,
  LBadge
} from '@last/core-ui/paprika'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import api from '@/api'
import OrderingModeSelector from '@/components/ordering/OrderingModeSelector.vue'
import SeatOrCourseSelector from '@/components/ordering/SeatOrCourseSelector.vue'
import OpenTab from '@/components/ordering/OpenTab.vue'
import LinkCustomerComponent from '@/components/ordering/LinkCustomer.vue'
import { useTabsStore } from '@/store/tabs'
import { useCatalogStore } from '@/store/catalog'
import { usePromotionsStore } from '@/store/promotions'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { useTabs } from '@/composables/useTabs'
import { useI18n } from 'vue-i18n'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

const route = useRoute()
const router = useRouter()
const dialog = useDialog()
const { t } = useI18n()

const tabId = ref(route.params.tabId as string)

const tabsStore = useTabsStore()
const catalogStore = useCatalogStore()
const promotionsStore = usePromotionsStore()
const configStore = useConfigStore()

const { addProduct, updateProductModifiers, updateCourse } = tabsStore
const { usedPointsInTab } = storeToRefs(promotionsStore)
const { config } = storeToRefs(configStore)
const { sendKitchenOrder, pendingOrders } = useKitchenOrders(tabId)
const { tab, closeTab, catalogId, getAllProducts } = useTabs(tabId)

const selectedSeat = ref<number | null>(null)
const selectedCourse = ref<string>('')
const editingProduct = ref<any>(null)
const editingCombo = ref<any>(null)
const resendKitchenOrders = ref(false)
const customerPoints = ref(0)
const customerUsedPromotions = ref<any[]>([])
const linkCustomerModal = ref(false)
const showOpenTab = ref(false)
const openTabOptions = ref(false)

const tabOptions: Option[] = [
  {
    icon: 'user',
    label: t('ordering.link-customer'),
    value: 'link-customer',
    onClick: () => {
      linkCustomerModal.value = true
      openTabOptions.value = false
    }
  },
  {
    icon: 'calendar',
    label: t('ordering.link-reservation'),
    value: 'link-reservation',
    onClick: () => {
      router.push({ name: 'reservations', query: { tabId: tabId.value } })
      openTabOptions.value = false
    }
  },
  {
    icon: 'close',
    label: t('close-tab.close-tab'),
    value: 'close',
    onClick: () => {
      closeTab()
      openTabOptions.value = false
    }
  }
]

const productCount = computed(() => {
  return getAllProducts.value.reduce((total, product) => {
    total += product.quantity
    return total
  }, 0)
})

const tabCustomerId = computed(() => tab.value?.customerId)

const availablePoints = computed(() => {
  return customerPoints.value - usedPointsInTab.value(tabId.value)
})

watch(
  () => productCount.value,
  (oldCount, newCount) => {
    if (oldCount !== newCount) {
      editingProduct.value = null
    }
  }
)

watch(tabCustomerId, (oldId, newId) => {
  if (oldId !== newId) {
    updatePromotionsInfo()
  }
})

watch(selectedSeat, value => {
  if (value !== route.query.selectedSeat) {
    router.replace({
      query: { ...route.query, selectedSeat: value }
    })
  }
})

async function updatePromotionsInfo() {
  let points = 0
  if (tab.value?.customerId) {
    const response = await api.get(
      `/customer/${tab.value.customerId}/promotions`
    )
    points = response.data.points
    customerUsedPromotions.value = response.data.customerUsedPromotions
  }
  customerPoints.value = points
}

function exitOrderManagement() {
  if (showOpenTab.value) {
    showOpenTab.value = false
    return
  }
  if (
    pendingOrders.value &&
    tab.value.activationTime &&
    config.value.enableKitchenOrders &&
    tab.value.open
  ) {
    dialog({
      title: t('unsent-dialog.title'),
      content: t('unsent-dialog.message'),
      secondaryLabel: t('unsent-dialog.cancel'),
      onConfirm: async () => {
        await sendKitchenOrder('all')
        router.replace({ name: 'pos' })
      },
      onSecondary: () => router.replace({ name: 'pos' })
    })
  } else {
    router.replace({ name: 'pos' })
  }
}

async function saveProduct(product: any) {
  let productPricing = ProductPriceCalculator.calculateProductPricing(product)
  if (product.id) {
    updateProductModifiers({
      productId: product.id,
      modifiers: product.modifiers,
      comments: product.comments,
      productPricing
    })
    updateCourse({
      tabId: tabId.value,
      productId: product.id,
      course: product.course
    })
  } else {
    addProduct({
      tabId: tabId.value,
      seat: selectedSeat.value,
      product: {
        ...product,
        ...productPricing
      }
    })
  }
  editingProduct.value = null
}

function configureProduct(product: any, quantity = 1) {
  const parentProduct = catalogStore.getProductById(product.id)
  if (product.categories) {
    let newCombo = {
      catalog: { ...product, ...parentProduct },
      edit: null
    }
    editingCombo.value = newCombo
  } else {
    let newProduct = {
      ...product,
      ...parentProduct,
      price: product.price,
      course: selectedCourse.value || product.course,
      id: null,
      parentProduct: product.id,
      quantity
    }
    let mandatory =
      newProduct.modifierGroups &&
      newProduct.modifierGroups.some((group: any) => group.min > 0)
    if (mandatory) {
      editingProduct.value = newProduct
    } else {
      saveProduct({ ...newProduct, modifiers: [] })
    }
  }
}

onMounted(async () => {
  if (!tab.value.open) {
    router.push({ name: 'pos' })
  }
  updatePromotionsInfo()
  if (route.query.selectedSeat) {
    selectedSeat.value = parseInt(route.query.selectedSeat as string)
  }
})
</script>
