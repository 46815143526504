<template>
  <div class="flex flex-row">
    <div
      v-if="showFloorplanButton"
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-action': selectedTab === 'floorplan'
      }"
      @click="emit('update:selectedTab', 'floorplan')"
    >
      <icon name="floorplan" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-action': selectedTab === 'delivery'
      }"
      @click="emit('update:selectedTab', 'delivery')"
    >
      <icon name="delivery" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-action': selectedTab === 'takeAway'
      }"
      @click="emit('update:selectedTab', 'takeAway')"
    >
      <icon name="take-away" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-action': selectedTab === 'all'
      }"
      @click="emit('update:selectedTab', 'all')"
    >
      <icon name="tabs" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '@last/core-ui/v3/components/Icon.vue'
import { defineProps } from 'vue'

type Props = {
  selectedTab: string | null
  showFloorplanButton: boolean
}
withDefaults(defineProps<Props>(), {
  selectedTab: 'floorplan',
  showFloorplanButton: true
})

const emit = defineEmits(['update:selectedTab'])
</script>
