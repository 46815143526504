<template>
  <info-screen
    :title="$t('extra-device.title')"
    :message="$t('extra-device.message')"
    :confirm-text="$t('extra-device.add-cta')"
    :image="'device.svg'"
    @confirm="onDeviceAdded"
  />
</template>

<script setup lang="ts">
import InfoScreen from './core/InfoScreen.vue'
import app from '@/app'
import api from '@/api'
import sync from '@/sync/service.js'
import { Capacitor } from '@capacitor/core'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { watch } from 'vue'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Location } from '@/types/location'

const authStore = useAuthStore()
const { isAuthenticated } = storeToRefs(authStore)

const route = useRoute()
const router = useRouter()

const locations = ref<Location[]>([])

const locationIdToBeSelected = computed(() => {
  return route.query.locationId as string
})

watch(isAuthenticated, () => {
  if (!isAuthenticated) {
    router.push({ name: 'login' })
  }
})

onMounted(async () => {
  let response = await api.get('/locations')
  locations.value = response.data
})

async function onDeviceAdded() {
  let selectedLocation = locations.value.find(
    l => l.id === locationIdToBeSelected.value
  )
  let platform = Capacitor.getPlatform()
  if (platform !== 'web') {
    const deviceInfo = await app.getDeviceInfo()
    const device = { deleted: false, ...deviceInfo }
    try {
      await api.put(`/devices/${device.id}`, device)
    } catch (e: any) {
      const error = e.response?.data
      if (error.type === 'devices_limit') {
        router.push({
          name: 'planSelector',
          params: {
            plan: error.plan,
            locationId: locationIdToBeSelected.value
          }
        })
      } else if (error.type === 'no_permission') {
        router.push({
          name: 'overTheLimit'
        })
      }
      return
    }
  }
  authStore.selectLocation(selectedLocation)
  sync.resync()
  router.push({ name: 'employees' })
}
</script>
