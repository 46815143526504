<template>
  <info-screen
    :title="$t('free-trial-expired.title')"
    :message="$t('free-trial-expired.message')"
    :image="'dizzy-robot.svg'"
  />
</template>

<script setup lang="ts">
import InfoScreen from '@/components/core/InfoScreen.vue'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'
import { watchEffect } from 'vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const { trialExpired } = storeToRefs(authStore)
const router = useRouter()

onMounted(() => {
  authStore.refreshBillingStatus()
  if (!trialExpired.value) {
    router.push({
      name: 'employees'
    })
  }
})

watchEffect(() => {
  if (!trialExpired.value) {
    router.push({
      name: 'employees'
    })
  }
})
</script>
