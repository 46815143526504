<template>
  <full-screen :title="$t('hardware-selector.title')" @close="$router.go(-1)">
    <div class="flex flex-col w-full sm:w-1/2 px-4 sm:px-0 m-auto py-4">
      <l-field :label="$t('hardware-selector.till')">
        <l-select
          v-model="selectedTill"
          :options="tillsOptions"
          :disabled="
            (tillsOptions.length <= 1 && !!selectedTill) || !shiftsEnabled
          "
        />
      </l-field>

      <l-field :label="$t('hardware-selector.cash-machine')">
        <l-select
          v-model="selectedCashMachine"
          :options="cashMachinesOptions"
          :disabled="cashMachinesOptions.length <= 1 && !!selectedCashMachine"
        />
      </l-field>

      <l-field :label="$t('hardware-selector.dataphone')">
        <l-select
          v-model="selectedDataphone"
          :options="dataphonesOptions"
          :disabled="dataphonesOptions.length <= 1 && !!selectedDataphone"
        />
      </l-field>
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import { LSelect, LField } from '@last/core-ui/paprika'
import { useConfigStore } from '@/store/config'
import { useTillStore } from '@/store/till'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import FullScreen from './core/FullScreen.vue'

const configStore = useConfigStore()
const tillStore = useTillStore()
const router = useRouter()

const { updateHardwarePreferences } = configStore
const { tills, device, config } = storeToRefs(configStore)
const { setTill } = tillStore
const { selectedCashTillIsStarted, selectedCashTill, shiftsEnabled } =
  storeToRefs(tillStore)

const cashMachinesOptions = computed(() => {
  let cashMachines = config.value.paymentMethodsConfig.filter(paymentMethod => {
    return paymentMethod.configs
  })
  return cashMachines.reduce(
    (acc, cashMachine) => {
      for (const config of cashMachine.configs) {
        acc.push({
          value: config.id,
          label: `${cashMachine.name} - ${config.name}`
        })
      }
      return acc
    },
    [] as { value: string; label: string }[]
  )
})

const tillsOptions = computed(() => {
  return tills.value.cash.map(cashTill => {
    return {
      value: cashTill.id,
      label: cashTill.name
    }
  })
})

const dataphonesOptions = computed(() => {
  return config.value.dataphoneConfigs.map(dataphone => {
    return {
      value: dataphone.id,
      label: dataphone.name
    }
  })
})

const selectedTill = computed({
  get() {
    return selectedCashTill.value?.id
  },
  set(newValue) {
    let selectedTill = tills.value.cash.find(till => till.id === newValue)
    setTill(selectedTill!)
    if (shiftsEnabled && !selectedCashTillIsStarted) {
      router.push({ name: 'startShift' })
    }
    updateHardwarePreferences({
      preferredTill: newValue
    })
  }
})

const selectedCashMachine = computed({
  get() {
    return device.value.preferredCashMachine
  },
  set(newValue) {
    updateHardwarePreferences({
      preferredCashMachine: newValue
    })
  }
})

const selectedDataphone = computed({
  get() {
    return device.value.preferredDataphone
  },
  set(newValue) {
    updateHardwarePreferences({
      preferredDataphone: newValue
    })
  }
})
</script>
