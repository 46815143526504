import { Preferences } from '@capacitor/preferences'
import app from './app'

function getCapacitorStorage() {
  return {
    setItem: async (key: string, value: any) => {
      await Preferences.set({ key, value })
    },
    getItem: async (key: string) => {
      const data = await Preferences.get({ key })
      return data.value
    },
    removeItem: async (key: string) => {
      await Preferences.remove({ key })
    },
    keys: async () => {
      return await Preferences.keys()
    },
    clear: async () => {
      await Preferences.clear()
      await Preferences.removeOld()
    }
  }
}

function getElectronStorage() {
  const { ipcRenderer } = window.require('electron')
  return {
    setItem: (key: string, value: any) => {
      return ipcRenderer.invoke('storage:set', key, value)
    },
    getItem: (key: string) => {
      return ipcRenderer.invoke('storage:get', key)
    },
    removeItem: (key: string) => {
      return ipcRenderer.invoke('storage:remove', key)
    },
    keys: () => {
      return ipcRenderer.invoke('storage:keys')
    },
    clear: () => {
      return ipcRenderer.invoke('storage:clear')
    }
  }
}

export default app.isElectron ? getElectronStorage() : getCapacitorStorage()
