<template>
  <div class="border-b border-n-600">
    <div class="mx-4 py-3 text-n-0">
      <div class="flex justify-between items-center">
        <div>{{ $t('checkout.charge-surcharge-to-tab') }}</div>
        <l-switch
          :model-value="isChecked"
          @update:model-value="toggleSurcharge"
        />
      </div>
      <div
        v-if="isChecked"
        class="flex justify-between items-center text-n-200 text-sm pt-1"
      >
        {{ $t('checkout.terrace-surcharge') }}: +{{
          tab.terraceSurchargePercentage
        }}%
        <div>{{ $filters.currency(surchargeAmountForPendingTotal) }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { LSwitch } from '@last/core-ui/paprika'
import type { Tab } from '@/types'
import { useTabs } from '@/composables/useTabs'

type Props = {
  tab: Tab
}
const props = defineProps<Props>()

const emit = defineEmits(['toggle'])

const { getPendingBill } = useTabs(props.tab.id)

const isChecked = ref<boolean>(false)

const surchargeAmountForPendingTotal = computed(() => {
  return getPendingBill.value.terraceSurcharge || 0
})

onMounted(() => {
  isChecked.value = !!props.tab.terraceSurchargePercentage
})

function toggleSurcharge(value: boolean): void {
  isChecked.value = value
  emit('toggle', value)
}
</script>
