<template>
  <transition name="fade-slide">
    <div
      v-if="open"
      class="fixed w-screen h-screen left-0 top-0 sm:py-4 flex items-center justify-center z-40 bg-n-900/80 backdrop-blur-sm"
      @click="emit('close')"
    >
      <div
        class="flex flex-col bg-n-0 dark:bg-n-800 w-full sm:h-auto sm:max-h-full shadow-lg sm:rounded-2xl text-n-800 dark:text-n-0 relative overflow-hidden"
        :class="{
          'sm:w-[32rem] sm:max-h-[48rem]': size === 'small',
          'sm:w-[60rem] sm:max-h-[35rem]': size === 'large',
          'h-full': fullHeight
        }"
        @click.stop
      >
        <template v-if="screens.length > 0">
          <transition
            v-for="(screen, index) in screens"
            :key="screen.name"
            :name="transitionName"
          >
            <div
              v-if="activeScreen === index"
              :key="screen.name"
              class="w-full h-full absolute left-0 top-0 min-h-0"
            >
              <div
                class="absolute flex flex-col h-full w-full left-0 top-0 flex-1 overflow-y-auto"
              >
                <div class="flex flex-1 flex-col">
                  <l-modal-title
                    :title="screen.title"
                    :show-back="activeScreen > 0"
                    @back="activeScreen--"
                    @close="emit('close')"
                  />
                  <div class="px-6 overflow-y-auto">
                    <div v-if="screen.description" class="font-body">
                      {{ screen.description }}
                    </div>
                    <div class="pt-4">
                      <slot :name="screen.name" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="screen.buttonText"
                class="absolute w-full left-0 bottom-0 bg-n-0 dark:bg-n-800 py-8 rounded-b-[1rem]"
              >
                <l-button
                  :disabled="actionDisabled"
                  class="w-[17.875rem] h-12 mx-auto"
                  @click="emit('action')"
                >
                  {{ screen.buttonText }}
                </l-button>
              </div>
            </div>
          </transition>
        </template>
        <template v-else>
          <div class="flex-1 grow relative min-h-0 flex flex-col h-full">
            <l-modal-title :title="title" @close="emit('close')" />
            <div class="flex flex-col h-full px-6 overflow-y-auto">
              <div v-if="description" class="flex-none font-body">
                {{ description }}
              </div>
              <div class="pt-4 flex-1">
                <slot />
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="buttonText && button"
          class="bg-n-0 dark:bg-n-800 p-8 rounded-b-[1rem] relative"
        >
          <l-button
            class="max-w-72 w-full h-12 mx-auto"
            size="small"
            :disabled="!buttonEnabled"
            @click="emit('action')"
          >
            {{ buttonText }}
          </l-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import LButton from '../LButton.vue'
import LModalTitle from './LModalTitle.vue'
import { watch, ref, defineModel } from 'vue'

type Screen = {
  name: string
  buttonText?: string
  sectionTitle?: string
  title?: string
  description?: string
}

type Props = {
  open?: boolean
  size?: 'small' | 'large'
  button?: boolean
  buttonText?: string
  buttonEnabled?: boolean
  sectionTitle?: string
  title?: string
  description?: string
  screens?: Screen[]
  fullHeight?: boolean
}

withDefaults(defineProps<Props>(), {
  open: true,
  size: 'small',
  button: true,
  buttonText: '',
  buttonEnabled: true,
  sectionTitle: '',
  title: '',
  description: '',
  screens: () => [],
  fullHeight: true
})

const emit = defineEmits(['action', 'close', 'update:active-screen'])

const activeScreen = defineModel<number>('activeScreen')

const transitionName = ref('forward')

watch(activeScreen, (newVal = 0, oldVal = 0) => {
  transitionName.value = newVal < oldVal ? 'backward' : 'forward'
  emit('update:active-screen', newVal)
})
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.backward-enter-active,
.backward-leave-active {
  transition: transform 0.3s ease;
}
.backward-leave-to {
  transform: translateX(100%);
}

.backward-enter-from {
  transform: translateX(-100%);
}

.forward-enter-active,
.forward-leave-active {
  transition: transform 0.3s ease;
}
.forward-leave-to {
  transform: translateX(-100%);
}

.forward-enter-from {
  transform: translateX(100%);
}
</style>
