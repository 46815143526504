<template>
  <l-modal
    size="small"
    :title="title"
    :button-text="$t('ctas.confirm')"
    :button-enabled="isComplete"
    @close="emit('close')"
    @action="saveTab()"
  >
    <div class="flex flex-col">
      <l-field :label="$t('new-tab.name-label')">
        <l-input
          v-model="newTab.name"
          size="small"
          :placeholder="$t('new-tab.name-placeholder')"
        />
      </l-field>
      <l-option-selector
        v-model="newTab.lang"
        :options="languages"
        class="mb-4"
      />
      <new-tab-seats-selector v-model="newTab.seats" @save="saveTab" />
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { LInput, LField, LModal, LOptionSelector } from '@last/core-ui/paprika'
import NewTabSeatsSelector from '@/components/tabs/NewTabSeatsSelector.vue'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { defineProps, withDefaults, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Option } from '@last/core-ui/paprika/components/LOptionSelector.vue'
import { onMounted } from 'vue'

const { t } = useI18n()
const router = useRouter()

type Table = any
type Customer = any

const props = withDefaults(
  defineProps<{
    table?: Table
    customer?: Customer
  }>(),
  {
    isActive: false,
    table: undefined,
    customer: undefined
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'tabCreated', tabId: string): void
}>()

const tabsStore = useTabsStore()
const configStore = useConfigStore()

const languages: Option[] = [
  {
    label: 'Esp',
    value: 'ES',
    icon: 'es',
    iconOriginalColor: true
  },
  {
    label: 'Ita',
    value: 'IT',
    icon: 'it',
    iconOriginalColor: true
  },
  {
    label: 'Fra',
    value: 'FR',
    icon: 'fr',
    iconOriginalColor: true
  },
  {
    label: 'Eng',
    value: 'EN',
    icon: 'en',
    iconOriginalColor: true
  },
  {
    label: 'Deu',
    value: 'DE',
    icon: 'de',
    iconOriginalColor: true
  },
  {
    label: 'Cat',
    value: 'CA',
    icon: 'cat',
    iconOriginalColor: true
  }
]

const { config } = storeToRefs(configStore)
const { openTab, openTabWithCustomer } = tabsStore

const newTab = ref<{
  lang: string
  name: string | null
  seats: number | null
}>({
  lang: 'ES',
  name: null,
  seats: null
})

onMounted(() => {
  newTab.value.lang = config.value.locationCountryCode
  if (!config.value.organizationConfig.seatSelector) {
    newTab.value.seats = 1
    saveTab()
  }
})

const isComplete = computed(() => !!newTab.value.seats)

const title = computed(() => {
  if (props.table) {
    return t('new-tab.new-tab-table', { name: props.table.name })
  }
  return t('new-tab.new-tab')
})

async function saveTab() {
  if (!isComplete.value) return
  let tabId = null
  if (props.customer) {
    tabId = await openTabWithCustomer({
      tableId: props.table?.id || null,
      tab: { ...newTab.value, customerInfo: props.customer },
      customer: props.customer
    })
  } else {
    tabId = await openTab({
      tableId: props.table?.id || null,
      tab: newTab.value
    })
  }
  emit('tabCreated', tabId)
  emit('close')
  router.push({ name: 'orderManagement', params: { tabId } })
}
</script>
