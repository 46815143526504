<template>
  <div>
    <div
      class="flex items-center justify-between p-4 text-n-0 font-heading font-medium"
    >
      <div class="flex flex-1 flex-shrink-0">
        <icon v-if="bill.pending === 0" name="done" class="mr-3 text-n-0" />
        <div v-if="bill.number">
          <span v-if="bill.customerCompany">{{ $t('checkout.invoice') }}</span>
          <span v-else>{{ $t('checkout.bill') }}</span>
          {{ bill.number }}
        </div>
        <div v-else-if="bill.id === currentBillId">
          {{ $t('checkout.pending-bill') }}
        </div>
        <div v-else-if="currentBillId">{{ index }} / {{ totalBills - 1 }}</div>
        <div v-else>{{ index + 1 }} / {{ totalBills }}</div>
      </div>
      <div
        class="font-heading font-medium"
        :class="{ 'text-g-500': bill.pending === 0 }"
      >
        {{ $filters.currency(bill.total) }}
      </div>
      <icon
        v-if="bill.id !== currentBillId"
        name="dots"
        class="text-v-300 p-1 ml-2"
        @click.stop="openOptions = true"
      />
      <l-dropdown v-model:open="openOptions" :options="options" />
    </div>
    <div
      v-if="selectedBillId === bill.id || bill.pending > 0"
      class="p-4 border-t border-n-700"
    >
      <div class="text-n-0 font-bold">
        {{ $t('checkout.payments') }}
      </div>
      <payments :bill-id="bill.id" :paid="bill.pending === 0" />
    </div>
    <div
      v-if="selectedBillId === bill.id || bill.pending > 0"
      class="flex border-t border-n-700"
    >
      <div
        class="flex-1 text-center border-r border-n-700 text-g-500 font-body p-2"
      >
        <div class="text-sm">{{ $t('checkout.paid') }}</div>
        <div class="font-medium">
          {{ $filters.currency(bill.paid) }}
        </div>
      </div>
      <div v-if="tip > 0" class="flex-1 text-center border-r border-n-700 p-2">
        <div class="text-sm">{{ $t('checkout.tip') }}</div>
        <div class="font-medium">
          {{ $filters.currency(tip) }}
        </div>
      </div>
      <div
        class="flex-1 text-center text-r-300 p-2"
        :class="{ 'text-g-400': bill.pending === 0 }"
      >
        <div class="text-sm">{{ $t('checkout.pending') }}</div>
        <div class="font-medium">
          {{ $filters.currency(bill.pending) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Payments from '@/components/checkout/Payments.vue'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import { LDropdown } from '@last/core-ui/paprika'
import { Bill, Payment } from '@/types'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  bill: Bill
  selectedBillId?: string
  currentBillId?: string
  index?: number
  totalBills?: number
}

type PaymentWithTip = Payment & { tip?: number }

const props = withDefaults(defineProps<Props>(), {
  selectedBillId: '',
  currentBillId: '',
  index: 0,
  totalBills: 0
})

const emit = defineEmits([
  'billSelected',
  'invalidate',
  'generateInvoice',
  'cancelInvoice',
  'sendInvoice'
])

const { t } = useI18n()

const openOptions = ref(false)

const options = computed<Option[]>(() => {
  const options = [
    {
      label: t('checkout.delete'),
      value: 'invalidate',
      onClick: () => emit('invalidate', props.bill.id)
    }
  ]
  if (props.bill.customerCompany) {
    options.push(
      {
        label: t('checkout.cancel-invoice'),
        value: 'cancelInvoice',
        onClick: () => emit('cancelInvoice', props.bill.id)
      },
      {
        label: t('checkout.send-invoice'),
        value: 'sendInvoice',
        onClick: () => emit('sendInvoice', props.bill.id)
      }
    )
  } else {
    options.push({
      label: t('checkout.generate-invoice'),
      value: 'generateInvoice',
      onClick: () => emit('generateInvoice', props.bill.id)
    })
  }
  return options
})

const tip = computed(() => {
  let asTip = props.bill.payments.reduce(
    (total, payment: PaymentWithTip) => total + (payment.tip || 0),
    0
  )
  let asExtra =
    props.bill.payments.reduce(
      (total, payment: PaymentWithTip) =>
        total + (payment.amount - (payment.tip || 0)),
      0
    ) - props.bill.total
  return asTip + Math.max(asExtra, 0)
})
</script>

<style scoped></style>
