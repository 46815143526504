import { default as coreUiTheme } from '@last/core-ui/tailwind.config.js'

export default {
  mode: 'jit',
  darkMode: 'selector',
  theme: {
    colors: {
      ...coreUiTheme.theme.colors,
      white: '#FFFFFF',
      black: '#000000',
      'gray-100': '#FAFAFD',
      'gray-200': '#ECECF3',
      'gray-300': '#D3D3E6',
      'gray-400': '#A0A0C0',
      'gray-900': '#545383',
      blue: '#545383',
      'blue-100': '#5F5D90',
      'blue-200': '#6a69a0',
      'blue-600': '#302F66',
      'blue-700': '#1D1C48',
      'blue-800': '#2C2C45',
      'blue-900': '#222134',
      'blue-light': '#A0A0C1',
      red: '#FF7878',
      'red-2': '#FD5F6D',
      'error-b': '#B4263B',
      'red-b': '#FD5F6D',
      'light-blue': '#4299E1',
      'light-blue-b': '#3989CB',
      green: '#21BECB',
      'green-b': '#07A1AE',
      'green-light': '#22BECB',
      yellow: '#FDC000',
      'yellow-b': '#DDA801',
      violet: '#9C6DFE',
      'violet-b': '#7C48E8',
      accent: '#FF7878',
      /// Semantic colors
      action: coreUiTheme.theme.colors.v[300],
      help: coreUiTheme.theme.colors.b[500],
      error: coreUiTheme.theme.colors.r[500],
      success: coreUiTheme.theme.colors.g[500],
      warning: coreUiTheme.theme.colors.y[500],
      blur: 'rgba(24, 24, 37, 0.8)',
      list: {
        1: coreUiTheme.theme.colors.r[500],
        2: '#D11A88',
        3: '#A01AD1',
        4: coreUiTheme.theme.colors.v[400],
        5: '#1A4BD1',
        6: coreUiTheme.theme.colors.b[500],
        7: '#39C0FE',
        8: coreUiTheme.theme.colors.g[500],
        9: '#1AD194',
        10: '#1AD126',
        11: coreUiTheme.theme.colors.y[400],
        12: '#FC9105',
        13: '#FF5708'
      }
    },
    boxShadow: {
      default:
        '0 1px 3px 0 rgba(34, 33, 52, .2), 0 1px 2px 0 rgba(34, 33, 52, .06)',
      md: ' 0 4px 6px -1px rgba(34, 33, 52, .2), 0 2px 4px -1px rgba(34, 33, 52, .06)',
      lg: ' 0 10px 15px -3px rgba(34, 33, 52, .2), 0 4px 6px -2px rgba(34, 33, 52, .05)',
      xl: ' 0 20px 25px -5px rgba(34, 33, 52, .2), 0 10px 10px -5px rgba(34, 33, 52, .04)',
      '2xl': '0 25px 50px -12px rgba(34, 33, 52, .2)'
    },
    extend: {
      borderRadius: {
        small: '10px',
        big: '20px'
      },
      fontFamily: {
        heading: ['Poppins'],
        title: ['Poppins'],
        text: ['Poppins'],
        body: ['Nunito Sans']
      },
      backgroundImage: {
        'corner-gradient':
          'radial-gradient(at top right, #43396a 0%, #2a2a42 40%)',
        'dual-b-y': `linear-gradient(to bottom,${coreUiTheme.theme.colors.b['500']} 0%,${coreUiTheme.theme.colors.b['500']} 50%,${coreUiTheme.theme.colors.y['500']} 50%,${coreUiTheme.theme.colors.y['500']} 100%)`,
        'dual-r-y': `linear-gradient(to bottom,${coreUiTheme.theme.colors.r['300']} 0%,${coreUiTheme.theme.colors.r['300']} 50%,${coreUiTheme.theme.colors.y['500']} 50%,${coreUiTheme.theme.colors.y['500']} 100%)`
      },
      borderWidth: {
        6: '6px'
      }
    }
  },
  variants: {
    borderColor: ['responsive', 'hover', 'focus', 'focus-within'],
    backgroundColor: ['responsive', 'hover', 'focus', 'active'],
    margin: ['responsive', 'first']
  },
  content: [
    `src/**/*.vue`,
    `../core-ui/components/**/*.{vue,js}`,
    `../core-ui/paprika/**/*.{vue,js,ts}`
  ],
  safelist: [{ pattern: /bg-list-(1|2|3|4|5|6|7|8|9|10|11|12|13)/ }]
}
