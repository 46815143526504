const customColors = {
  n: {
    0: '#FFFFFF',
    10: '#F9F9FB',
    50: '#F3F3F7',
    100: '#E6E6EF',
    200: '#C7C7DB',
    300: '#A8A8C8',
    400: '#8988B4',
    500: '#6A69A0',
    600: '#3E3E61',
    700: '#363654',
    800: '#2A2A42',
    900: '#181825',
    DEFAULT: '#A8A8C8'
  },
  v: {
    100: '#E0D1FF',
    200: '#BE9FFE',
    300: '#9C6DFE',
    400: '#7939FE',
    500: '#5707FD',
    DEFAULT: '#9C6DFE',
    dark: '#7939FE'
  },
  r: {
    100: '#FFE0E0',
    200: '#FFADAD',
    300: '#FF7878',
    400: '#FF4747',
    500: '#FF1414',
    dark: '#FF4747'
  },
  b: {
    100: '#C6E0F6',
    200: '#9AC8EF',
    300: '#6EB1E8',
    400: '#4299E1',
    500: '#2180CF',
    DEFAULT: '#6EB1E8',
    dark: '#4299E1'
  },
  g: {
    100: '#C2F1F5',
    200: '#96E7EE',
    300: '#6ADDE7',
    400: '#3ED3E0',
    500: '#21BECB',
    DEFAULT: '#6ADDE7',
    dark: '#3ED3E0'
  },
  y: {
    100: '#FFF9E5',
    200: '#FFE699',
    300: '#FFDA66',
    400: '#FFCE33',
    500: '#E5AE00',
    DEFAULT: '#FFDA66',
    dark: '#FFCE33'
  },
  transparent: 'transparent'
}

export default {
  darkMode: 'selector',
  theme: {
    colors: {
      ...customColors,
      primary: customColors.v.DEFAULT,
      'primary-dark': customColors.v.dark,
      'transparent-gray': 'rgba(160, 160, 192, 0.2)',
      white: '#FFFFFF',
      blue: '#545383'
    },
    extend: {
      fontFamily: {
        heading: ['Poppins'],
        text: ['Poppins'],
        body: ['Nunito Sans']
      }
    }
  },
  variants: {
    borderColor: ['responsive', 'hover', 'focus', 'focus-within'],
    margin: ['responsive', 'first'],
    animation: ['hover']
  },
  content: [
    `./components/**/*.{vue,js}`,
    `./v3/components/**/*.{vue,js}`,
    `./paprika/**/*.{vue,js,ts}`,
    `./stories/**/*.ts`
  ]
}
