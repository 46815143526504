<template>
  <l-modal
    size="small"
    :auto-height="true"
    :title="
      selectedOption === 'manual'
        ? isHotel
          ? $t('link-customer.type-identifier')
          : $t('link-customer.type-phone-number')
        : $t('link-customer.scan-qr')
    "
    :button="selectedOption === 'manual' && !loading"
    :button-enabled="identifier.length > 0"
    :button-text="$t('link-customer.save')"
    @close="$emit('close')"
    @action="save(identifier)"
  >
    <div
      class="flex flex-col justify-center"
      :class="{ 'items-center h-full': loading }"
    >
      <div v-if="loading">
        <l-loading class="max-w-16" type="spinner"></l-loading>
      </div>
      <template v-if="!loading && deviceHasCamera()">
        <l-option-selector
          v-model="selectedOption"
          :options="selectOptions"
          class="w-full mb-4"
        />
      </template>
      <div
        v-if="selectedOption === 'manual' && !loading"
        class="w-full overflow-y-scroll scrolling-touch"
      >
        <l-field
          :label="
            isHotel
              ? $t('link-customer.identifier')
              : $t('link-customer.phone-number')
          "
          class="w-full"
        >
          <l-phone-input
            ref="identifierInput"
            v-model="identifier"
            :icon="isHotel ? '' : 'phone'"
            icon-class="text-blue"
            :placeholder="
              isHotel
                ? $t('link-customer.type-identifier')
                : $t('link-customer.type-phone-number')
            "
          />
        </l-field>
      </div>
      <template v-if="selectedOption === 'automatic' && !loading">
        <div class="w-full mt-4 flex flex-col items-center">
          <q-r-scanner
            @update:value="value => save(value)"
            @close="$emit('close')"
            @cancel="activateManualInput()"
            @error="activateManualInput(), (deviceCameraError = true)"
          />
        </div>
      </template>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'
import { Capacitor } from '@capacitor/core'
import { useConfigStore } from '@/store/config'
import { useNotifications } from '@/composables/useNotifications'
import { storeToRefs } from 'pinia'
import api from '@/api'
import {
  LField,
  LPhoneInput,
  LInput,
  LModal,
  LOptionSelector,
  LLoading
} from '@last/core-ui/paprika'
import QRScanner from './QRScanner.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  tabId: {
    type: String,
    required: true
  }
})

const { t } = useI18n()

const emit = defineEmits(['close'])

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)
const { notifySuccess, notifyError } = useNotifications()

const currentPlatform = ref(Capacitor.getPlatform())
const deviceCameraError = ref(false)
const identifier = ref('')
const loading = ref(false)

const selectOptions = [
  { label: t('sidemenu.automatic-shipment'), value: 'automatic' },
  { label: t('sidemenu.manual-shipment'), value: 'manual' }
]

const selectedOption = ref<'automatic' | 'manual'>('automatic')
const isHotel = computed(() => config.value.isHotel ?? false)
const identifierInput = ref<typeof LInput>()

onMounted(() => {
  if (!deviceHasCamera()) {
    activateManualInput()
  }
})

function deviceHasCamera() {
  return (
    (currentPlatform.value === 'android' || currentPlatform.value === 'ios') &&
    !deviceCameraError.value
  )
}

async function save(value: string) {
  loading.value = true
  try {
    await api.post(`/tabs/${props.tabId}/customer`, {
      identifier: value,
      isHotel: isHotel.value
    })
    notifySuccess({
      title: t('link-customer.customer-linked'),
      description: t('link-customer.customer-linked-successfully')
    })
    emit('close')
  } catch (e) {
    notifyError({
      title: t('link-customer.error'),
      description: t('link-customer.customer-linked-error')
    })
  }
  loading.value = false
}

async function activateManualInput() {
  selectedOption.value = 'manual'
  await nextTick()
  identifierInput.value?.focus()
}
</script>
