<template>
  <div class="h-screen bg-cover pt-10">
    <l-modal
      size="small"
      :title="$t('suspended-account.title')"
      :blur-background="false"
      :hidden-close="true"
    >
      <template #body>
        <div class="text-center whitespace-pre-line text-blue">
          {{ suspensionText }}
        </div>
      </template>
    </l-modal>
  </div>
</template>

<script>
import LModal from '@last/core-ui/v3/components/LModal.vue'
import lastUtils from '@last/core/src/lastUtils'
import api from '@/api'
import { useAuthStore } from '@/store/auth'
import { storeToRefs } from 'pinia'

export default {
  name: 'PaymentRequest',
  components: {
    LModal
  },
  setup() {
    const authStore = useAuthStore()
    const { posEnabled } = storeToRefs(authStore)
    return {
      posEnabled
    }
  },
  data() {
    return {
      amount: 0
    }
  },
  computed: {
    suspensionText() {
      if (this.amount) {
        return this.$t('suspended-account.payment-request', {
          amount: lastUtils.currencyFilter(this.amount * 100)
        })
      } else {
        return this.$t('suspended-account.no-payment-request')
      }
    }
  },
  async mounted() {
    let response = await api.get(`/payment-amount/${this.locationId}`)
    this.amount = response.data.amount
  },
  methods: {
    close() {
      if (!this.posEnabled) return
      this.$router.push({ name: 'employees' })
    }
  }
}
</script>
